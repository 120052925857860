import dayjs from 'dayjs';
import React from 'react';
import {
  FaBirthdayCake,
  FaHeart,
  FaMapMarkerAlt,
  FaSkull,
  FaTag,
  FaUser,
  FaVenusMars,
} from 'react-icons/fa';
import { GiGraveyard } from 'react-icons/gi';
import { MdFamilyRestroom } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styles from './AboutContainer.module.css';

const AboutItems = ({ grave }) => {
  const intl = useIntl();
  const countries = useSelector((state) => state.countries.countries);

  const icons = {
    name: <FaUser style={{ color: '#404d56', fontSize: 16 }} />,
    gender: <FaVenusMars style={{ color: '#404d56', fontSize: 16 }} />,
    birthplace: <FaMapMarkerAlt style={{ color: '#404d56', fontSize: 16 }} />,
    nickname: <FaTag style={{ color: '#404d56', fontSize: 16 }} />,
    birth_name: <FaUser style={{ color: '#404d56', fontSize: 16 }} />,
    death_location: <FaSkull style={{ color: '#404d56', fontSize: 16 }} />,
    cemetery: <GiGraveyard style={{ color: '#404d56', fontSize: 16 }} />,
    birthday: <FaBirthdayCake style={{ color: '#404d56', fontSize: 16 }} />,
    death_date: <FaSkull style={{ color: '#404d56', fontSize: 16 }} />,
    relations: <MdFamilyRestroom style={{ color: '#404d56', fontSize: 16 }} />,
    cause_of_death: <FaHeart style={{ color: '#404d56', fontSize: 16 }} />,
    country: <FaMapMarkerAlt style={{ color: '#404d56', fontSize: 16 }} />,
  };

  const formatDateTime = (day, date, time) => {
    if (!date) return null;
    let formattedDate = `${day ? intl.formatMessage({ id: day }) + ' ' : ''}${dayjs(date).format(
      'DD/MM/YYYY',
    )}`;
    return time ? `${formattedDate} at ${dayjs(time).format('HH:mm')}` : formattedDate;
  };

  const formattedData = [
    { key: 'name', value: grave.name },
    {
      key: 'birth_name',
      value: grave.grave_additional_names.find((n) => n.name_type === 'Birthname')?.name,
    },
    {
      key: 'nickname',
      value: grave.grave_additional_names.find((n) => n.name_type === 'Nickname')?.name,
    },
    { key: 'gender', value: grave.gender },
    {
      key: 'country',
      value: grave.country_id ? countries?.find((c) => c.id === grave.country_id)?.name : null,
    },
    { key: 'birthplace', value: grave.location_of_birth },
    { key: 'death_location', value: grave.location_of_death },
    { key: 'cemetery', value: grave.graveyard?.name },
    {
      key: 'birthday',
      value: formatDateTime(grave.day_of_birth, grave.date_of_birth, grave.time_of_birth),
    },
    {
      key: 'death_date',
      value: formatDateTime(grave.day_of_death, grave.date_of_death, grave.time_of_death),
    },
    {
      key: 'relations',
      value: grave.grave_users.length
        ? grave.grave_users.map((user) => user.user.full_name).join(', ')
        : null,
    },
    { key: 'cause_of_death', value: grave.reason_of_death },
  ].filter((item) => item.value); // Remove entries with null/undefined values

  return formattedData.map(({ key, value }) => (
    <div key={key} className={styles.infoItem}>
      <span className={styles.icon}>{icons[key]}</span>
      <span className={styles.label}>
        <FormattedMessage id={`about_${key}`} defaultMessage={key} />:
      </span>
      <span className={styles.value}>
        {key === 'gender' ? <FormattedMessage id={value} /> : value}
      </span>
    </div>
  ));
};

export default AboutItems;
