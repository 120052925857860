import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import styles from './MemoryDetails.module.css';

const ImageCarousel = ({ mediaContents, currentIndex, onNext, onPrev }) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => onNext(),
    onSwipedRight: () => onPrev(),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div className={styles.modalContent} {...handlers}>
      {/* Left Navigation */}
      {mediaContents.length > 1 && (
        <Button
          className={styles.navIcon}
          shape='circle'
          icon={<LeftOutlined />}
          onClick={onPrev}
        />
      )}

      {/* Display Media */}
      <div className={styles.mediaWrapper}>
        {mediaContents[currentIndex].category === 'image' ? (
          <img
            src={mediaContents[currentIndex].file_url}
            alt='Full View'
            className={styles.fullImage}
          />
        ) : (
          <video
            className={styles.fullImage}
            src={mediaContents[currentIndex].file_url}
            muted
            playsInline
            controls
          />
        )}
      </div>

      {/* Right Navigation */}
      {mediaContents.length > 1 && (
        <Button
          onClick={onNext}
          className={styles.navIcon}
          icon={<RightOutlined />}
          shape='circle'
        />
      )}
    </div>
  );
};

export default ImageCarousel;
