import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setGraveImages } from '../../../../../../redux/actions';
import { request } from '../../../../../../service/request';
import styles from './GravePhotos.module.css';

const { TextArea } = Input;

const privacyOptions = [
  { value: 'private', label: <FormattedMessage id='private' /> },
  { value: 'public', label: <FormattedMessage id='public' /> },
  { value: 'relatives', label: <FormattedMessage id='relatives' /> },
];

const EditImageModal = ({ visible, onClose, imageItem, setImages, images }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [formValues, setFormValues] = useState({
    description: imageItem.description,
    address: imageItem.address,
    privacy: imageItem.privacy,
  });

  useEffect(() => {
    const updateHeight = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImage = async () => {
    if (!imageItem.file_url || !croppedAreaPixels) return null;

    try {
      const croppedBlob = await cropImage(imageItem.file_url, croppedAreaPixels);
      return croppedBlob;
    } catch (error) {
      console.error('Cropping error:', error);
      return null;
    }
  };
  const cropImage = async (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.src = imageSrc;
      image.crossOrigin = 'anonymous';

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );

        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error('Canvas to Blob conversion failed'));
          },
          'image/jpeg',
          0.95, // High quality
        );
      };

      image.onerror = (err) => reject(err);
    });
  };

  const handleSave = async () => {
    try {
      const croppedImageBlob = await getCroppedImage();
      if (croppedImageBlob) {
        const formData = new FormData();
        const blob = new Blob([croppedImageBlob], { type: croppedImageBlob.type });
        formData.append('grave_image[file]', blob);
        formData.append('grave_image[description]', formValues.description || '');
        formData.append('grave_image[address]', formValues.address || '');
        formData.append('grave_image[category]', 'display');
        formData.append('grave_image[privacy_attributes][setting]', formValues.privacy);
        const headers = { 'Content-Type': 'multipart/form-data' };
        const response = await request(`/grave_images/${imageItem.id}`, formData, 'put', headers);
        const newImages = images.map((item) => (item.id === imageItem.id ? response.data : item));
        setImages(newImages);
        dispatch(setGraveImages([...newImages]));
      }
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<FormattedMessage id='edit_image' />}
      width={window.innerWidth <= 768 ? '100%' : 800}
      style={{
        top: window.innerWidth <= 768 ? 0 : '10%',
        margin: window.innerWidth <= 768 ? 0 : 'auto',
        maxWidth: window.innerWidth <= 768 ? '100%' : 'calc(100% - 40px)',
      }}
      styles={{
        header: {
          color: '#333',
          fontSize: '18px',
          fontWeight: '600',
          padding: '12px 16px',
          borderBottom: '1px solid #e1e1e1',
        },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'calc(100vh - 200px)',
          borderRadius: window.innerWidth <= 768 ? 0 : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: 'auto',
          flex: 1,
        },
      }}
      footer={
        <div className={styles.footer}>
          <Button key='cancel' onClick={onClose}>
            <FormattedMessage id='settings_cancel' />
          </Button>
          <Button
            key='save'
            type='primary'
            onClick={handleSave}
            style={{ backgroundColor: '#404d56' }}
          >
            <FormattedMessage id='update_memory' />
          </Button>
        </div>
      }
    >
      <div className={styles.cropperWrapper}>
        <Cropper
          image={imageItem.file_url}
          crop={crop}
          aspect={18 / 9}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          zoomWithScroll={false}
          showGrid={false}
          objectFit='cover'
          style={{
            containerStyle: { width: '100%' },
            mediaStyle: { width: '100%' },
          }}
        />
      </div>

      <Form
        layout='vertical'
        style={{ marginTop: 12, padding: '0 12px' }}
        initialValues={formValues}
        onValuesChange={(_, allValues) => setFormValues(allValues)}
      >
        <Form.Item
          name='description'
          label={<FormattedMessage id='label_description' defaultMessage='Description' />}
        >
          <TextArea rows={3} placeholder={intl.formatMessage({ id: 'label_description' })} />
        </Form.Item>

        <Form.Item
          name='address'
          label={<FormattedMessage id='label_address' defaultMessage='Address' />}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'placeholder_address',
              defaultMessage: 'Enter address',
            })}
          />
        </Form.Item>

        <Form.Item
          name='privacy'
          label={<FormattedMessage id='about_privacy' defaultMessage='Privacy Settings' />}
        >
          <Select
            showSearch
            optionFilterProp='label'
            placeholder={intl.formatMessage({
              id: 'placeholder_privacy',
              defaultMessage: 'Select privacy option',
            })}
            options={privacyOptions.map((c) => ({ value: c.value, label: c.label }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditImageModal;
