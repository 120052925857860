import PersonIcon from '@mui/icons-material/Person';
import Popover from '@mui/material/Popover';
import { Avatar } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BellIcon from '../../../img/BellIcon.svg';
import {
  setNotificationIsRead,
  setUserNotificationReadRedux,
  setUserReadNotifications,
  setUserUnReadNotifications,
} from '../../../redux/actions';
import { fetch_request } from '../../../service/request';
import IconButton from './IconButton';
import './Notifications.css';
import RequestActionButtons from './RequestActionButtons';
import RequestActionLabels from './RequestActionLabels';

const Notifications = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [activeTab, setActiveTab] = React.useState('New');
  const newNotifications = useSelector((state) => state.user?.unreadNotifications);
  const readNotifications = useSelector((state) => state.user?.readNotifications);

  const showAlertDot = newNotifications.some((item) => item.read === false);
  const alertDotCount = newNotifications.filter((item) => item.read === false).length;

  React.useEffect(() => {
    fetchReadNotifications();
    fetchUnReadNotifications();
  }, []);

  const fetchReadNotifications = () => {
    fetch_request(`/api/notifications?read=true`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((data) => dispatch(setUserReadNotifications(data.notifications)))
      .catch(console.log);
  };

  const fetchUnReadNotifications = () => {
    fetch_request(`/api/notifications?read=false`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((data) => dispatch(setUserUnReadNotifications(data.notifications)))
      .catch(console.log);
  };

  const handleClose = () => {
    newNotifications.forEach(makeNotificationRead);
    dispatch(setUserNotificationReadRedux());
    setAnchorEl(null);
  };

  const makeNotificationRead = (item) => {
    fetch_request(`/api/notifications/${item.id}`, {
      method: 'PUT',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      body: JSON.stringify({ read: true }),
    }).catch(console.log);
  };

  const handleNotificationClick = (item) => {
    if (['memory_mentioned_notification', 'new_memory_notification'].includes(item.category)) {
      setAnchorEl(null);

      if (!item.read) {
        makeNotificationRead(item);
        dispatch(setNotificationIsRead(item.id));
      }
      navigate(`/deceased/${item.extra_info.slug}?memory=${item.extra_info.memory_id}`);
    }

    if (item.category === 'grave_mentioned_notification') {
      setAnchorEl(null);

      if (!item.read) {
        makeNotificationRead(item);
        dispatch(setNotificationIsRead(item.id));
      }
      navigate(`/deceased/${item.extra_info.slug}`);
    }
  };

  const getNotificationsSource = () => (activeTab === 'New' ? newNotifications : readNotifications);

  const formattedDateTime = (string) =>
    string
      ? new Date(string).toLocaleString('uk-UK', { dateStyle: 'short', timeStyle: 'short' })
      : '';

  return (
    <div className='notifications'>
      <div className='icon-relative'>
        <IconButton icon={BellIcon} handleClick={(e) => setAnchorEl(e.currentTarget)} />
        {showAlertDot && <div className='yellow-dot'>{alertDotCount}</div>}
      </div>
      {props.open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <div className='headline tabs'>
            <span
              className={activeTab === 'New' ? 'active' : ''}
              onClick={() => setActiveTab('New')}
            >
              <FormattedMessage id='new' />
            </span>
            <span
              className={activeTab === 'Read' ? 'active' : ''}
              onClick={() => setActiveTab('Read')}
            >
              <FormattedMessage id='read' />
            </span>
          </div>
          <div className='notifications-bar'>
            {getNotificationsSource()?.length ? (
              getNotificationsSource().map((item, index) => (
                <div
                  key={index}
                  className='item'
                  style={{
                    backgroundColor: !item.read ? '#E4F3FD' : 'white',
                    padding: !item.read ? '0px 8px 8px 8px' : '0px',
                  }}
                  onClick={() => handleNotificationClick(item)}
                >
                  <div className='notification-card'>
                    {!item.read && <div className='notification-dot' />}
                    <Avatar
                      size={36}
                      src={item.extra_info?.user_avatar || null}
                      icon={!item.extra_info?.user_avatar && <PersonIcon />}
                    />
                    <div className='text'>{item.title}</div>
                  </div>
                  {item.show_button && <RequestActionButtons notification={item} />}
                  {(item.requestStatus || item.status) && (
                    <RequestActionLabels requestStatus={item.requestStatus || item.status} />
                  )}
                  <div className='notification-date-time'>{formattedDateTime(item.created_at)}</div>
                </div>
              ))
            ) : (
              <div className='noItems-notification'>
                <FormattedMessage id='empty_notification_message' />
              </div>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default Notifications;
