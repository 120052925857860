export const isVideoScrolledIntoView = (el) => {
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;

  var isVisible = elemTop <= 500 && elemTop >= 100;

  return isVisible;
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}/${formattedMonth}/${year}`;
};

export const getSubscriptionDiscount = (discounts, members) => {
  const sortedDiscounts = discounts.sort((a, b) => a.user_count - b.user_count);

  const maxDiscountObject = sortedDiscounts[sortedDiscounts.length - 1];
  const maxUserCount = maxDiscountObject.user_count;

  if (members > maxUserCount) {
    return maxDiscountObject;
  }

  const foundDiscount = sortedDiscounts.find((discount) => discount.user_count === members);

  return foundDiscount ? foundDiscount : { user_count: members, discount_percentage: 0 };
};

export const timeSinceCreated = (createdAt) => {
  if (createdAt === null || createdAt === undefined) {
    return '';
  }

  const createdDate = new Date(createdAt);
  const currentDate = new Date();

  if (isNaN(createdDate.getTime())) {
    return '';
  }

  const timeDifference = currentDate - createdDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);

  if (minutes <= 1) {
    return 'Just now';
  } else if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else {
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (weeks < 4) {
      return `About ${weeks} week${weeks !== 1 ? 's' : ''} ago`;
    } else if (createdDate.getFullYear() === currentDate.getFullYear()) {
      const options = { day: 'numeric', month: 'long' };
      return createdDate.toLocaleDateString('en-US', options);
    } else {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return createdDate.toLocaleDateString('en-US', options);
    }
  }
};

export const formatTotalCount = (totalCount) => {
  if (totalCount < 1000) {
    return totalCount.toString();
  } else if (totalCount < 1_000_000) {
    return (totalCount / 1000).toFixed(1).replace(/\.0$/, '') + 'k'; // Format to '1.4k', '1.5k', etc.
  } else if (totalCount < 1_000_000_000) {
    return (totalCount / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'; // Format to '1.4M', '1.5M', etc.
  } else {
    return (totalCount / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'; // Format to '1.4B', '1.5B', etc.
  }
};

// Function to calculate brightness & return appropriate text color
export const getTextColor = (backgroundColor) => {
  if (!backgroundColor) return '#E4F3FD'; // default fallback

  let color = backgroundColor.replace('#', '');

  // Expand shorthand hex (e.g., #fff to #ffffff)
  if (color.length === 3) {
    color = color
      .split('')
      .map((c) => c + c)
      .join('');
  }

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  // Formula to calculate brightness
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Threshold ~128 (you can tweak it)
  return brightness > 128 ? '#19262D' : '#E4F3FD';
};

// Similarly, description text color
export const getDescriptionColor = (backgroundColor) => {
  if (!backgroundColor) return '#E4F3FD';

  let color = backgroundColor.replace('#', '');

  if (color.length === 3) {
    color = color
      .split('')
      .map((c) => c + c)
      .join('');
  }

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 128 ? '#404D56' : '#E4F3FD';
};
