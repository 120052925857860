import { PlusOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SearchInput from '../../../../components/SearchInput';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { fetch_request, request } from '../../../../service/request';
import GraveUsersList from './GraveUserList';
import InvitationForm from './InvitationForm';
import InvitedUsersList from './InvitedUsersList';
import styles from './Relationships.module.css';

const Relationships = () => {
  const intl = useIntl();
  const grave = useSelector((state) => state.grave.grave);
  const loggedUser = useSelector((state) => state.user.user);
  const relations = useSelector((state) => state.grave.relations);
  const relationDirections = useSelector((state) => state.grave.relationDirections);
  const currentGraveUser = grave?.current_user_relation;
  const [openInvitationForm, setOpenInvitationForm] = useState(false);
  const [graveInvitations, setGraveInvitations] = useState([]);
  const [graveUsers, setGraveUsers] = useState([]);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setGraveUsers([...grave.grave_users]);
  }, [grave.grave_users]);

  const fetchGraveInvitations = async () => {
    try {
      if (!token) return;

      const response = await fetch_request(`/api/graves/${grave.slug}/grave_invitations`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setGraveInvitations(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Failed to fetch grave invitations:', error.message);
    }
  };

  useEffect(() => {
    fetchGraveInvitations();
  }, [grave.slug]);

  const handleOpenInvitationForm = () => {
    setOpenInvitationForm(true);
  };

  const onSubmitInvitation = async (values, id) => {
    const payload = {
      grave_invitation: {
        full_name: values.username || '',
        email: values.email || '',
        admin: values.role === 'admin' ? true : false,
        relation_id: values.relation || null,
        reciprocal_relation_id: values.relationDirection || null,
        grave_id: grave.id,
      },
    };

    const handleSuccess = (res) => {
      if (id) {
        // Update existing invitation
        setGraveInvitations(graveInvitations.map((w) => (w.user_id === id ? { ...res.data } : w)));
      } else {
        // Create new invitation
        setGraveInvitations([res.data, ...graveInvitations]);
      }
    };

    const handleError = (error) => {
      const errorMessage = error?.response?.data?.error || error.message;
      showUserNotification(errorMessage, 'warning');
    };

    // API Call
    const apiEndpoint = id ? `/grave_invitations/${id}` : `/grave_invitations`;

    const method = id ? 'put' : 'post';

    try {
      const response = await request(apiEndpoint, payload, method);
      handleSuccess(response);
    } catch (error) {
      handleError(error);
    }
  };

  const removeInvitation = async (id) => {
    if (!id) return;

    try {
      await request(`/grave_invited_users/${id}`, null, 'delete');
      setGraveInvitations(graveInvitations.filter((relation) => relation.id !== id));
    } catch (error) {
      const errorMessage = error?.response?.data?.error || error.message;
      showUserNotification(errorMessage, 'error');
    }
  };

  const filterGraveUsers = (searchItem) => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(`/api/graves/${grave.id}/grave_users?search=${searchItem}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setGraveUsers(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterInvitations = (searchItem) => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(
      `/api/graves/${grave.id}/grave_invited_users?search=${searchItem}`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((data) => {
        setGraveInvitations(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sortedUsers =
    graveUsers.length > 0 &&
    graveUsers.sort((a, b) => {
      if (a.grave_owner) return -1; // Grave owner on top
      if (b.grave_owner) return 1;
      if (a.admin && !b.admin) return -1; // Admins next
      if (!a.admin && b.admin) return 1;
      return 0; // Keep others as they are
    });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>
          <FormattedMessage id='relationships' />
        </h3>
        <SearchInput
          filterItems={filterGraveUsers}
          placeholder={intl.formatMessage({ id: 'find_grave_member' })}
        />
      </div>

      <div style={{ marginTop: 16 }}>
        {sortedUsers.length > 0
          ? sortedUsers.map((user) => (
              <GraveUsersList
                key={user.id}
                user={user}
                relations={relations}
                relationDirections={relationDirections}
                grave={grave}
                loggedUser={loggedUser}
                currentGraveUser={currentGraveUser}
              />
            ))
          : null}
      </div>

      <div className={styles.header} style={{ marginTop: 12 }}>
        <h3>
          <FormattedMessage id='pending_invitations' />
        </h3>
        <SearchInput
          filterItems={filterInvitations}
          placeholder={intl.formatMessage({ id: 'find_invited_user' })}
        />
      </div>
      {(currentGraveUser?.admin || currentGraveUser?.grave_owner) && loggedUser.is_subscribed && (
        <Button
          type='text'
          style={{
            marginTop: 12,
            display: openInvitationForm ? 'none' : 'flex',
            fontWeight: 'bold',
            background: 'rgba(0,0,0,0.04)',
            color: '#404d56',
            fontSize: 14,
          }}
          icon={<PlusOutlined />}
          onClick={handleOpenInvitationForm}
        >
          <FormattedMessage id='invite_family_member' />
        </Button>
      )}

      {openInvitationForm && (
        <div className={styles.invitationForm}>
          <InvitationForm
            relations={relations}
            relationDirections={relationDirections}
            onSubmit={onSubmitInvitation}
            onCancel={() => setOpenInvitationForm(false)}
            grave={grave}
          />
        </div>
      )}

      <div style={{ height: loading ? 400 : 'auto' }}>
        {graveInvitations.length > 0
          ? graveInvitations.map((user) => (
              <InvitedUsersList
                user={user}
                key={user.id}
                relations={relations}
                relationDirections={relationDirections}
                grave={grave}
                loggedUser={loggedUser}
                currentGraveUser={currentGraveUser}
                onSubmitInvitation={onSubmitInvitation}
                removeInvitation={removeInvitation}
              />
            ))
          : !loading && (
              <Result
                status='404'
                title={<FormattedMessage id='no_invitations_sent' />}
                subTitle={
                  <FormattedMessage
                    id='no_invitations_message'
                    values={{ graveName: grave.name }}
                  />
                }
              />
            )}
      </div>
    </div>
  );
};

export default Relationships;
