import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Result, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setLifeEvents } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import CreateEventModal from './Components/CreateEventModal';
import LifeEvents from './Components/LifeEvents/LifeEvents';
import styles from './LifeEventsPage.module.css';

const LifeEventsPage = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.grave.lifeEvents);
  const grave = useSelector((state) => state.grave.grave);
  const user = useSelector((state) => state.user.user) || {};
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const findGraveUser = () => {
    return grave.current_user_relation.user.id === user.id;
  };

  const fetchLifeEvents = async () => {
    if (!grave.id) return;
    setLoading(true);
    await request(`/life_events?grave_id=${grave.id}`)
      .then((res) => {
        dispatch(setLifeEvents(res.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLifeEvents();
  }, [dispatch, grave.id]);

  return (
    <div className={styles.container}>
      <div className={styles.lifeEventsHeader}>
        <h3>
          <FormattedMessage id='life_archive' />
        </h3>
        {findGraveUser() && (
          <Button
            type='text'
            onClick={() => setOpenModal(true)}
            style={{
              fontWeight: 'bold',
              background: '#f2f4f7',
              color: '#404d56',
              fontSize: 14,
            }}
            icon={<PlusOutlined />}
          >
            <FormattedMessage id='add_life_event' />
          </Button>
        )}
      </div>
      <div style={{ marginTop: 12 }}>
        {loading ? (
          <div className={styles.loading}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
          </div>
        ) : events && events.length > 0 ? (
          <LifeEvents events={events} />
        ) : (
          <Result
            status='404'
            title={<FormattedMessage id='no_events_added' />}
            subTitle={<FormattedMessage id='add_events_message' />}
          />
        )}
      </div>
      {openModal && (
        <CreateEventModal
          grave={grave}
          user={user}
          visible={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default LifeEventsPage;
