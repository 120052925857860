import React from 'react';
import './Questions.css';
import QuestionMark from '../../img/QuestionMark.svg';
import Question from './atoms/Question';

const Questions = (props) => {
  return (
    <div className='questions-section'>
      <div className='questions-section-title'>
        <span className='questions-section-title_button'>
          <img loading='lazy' src={QuestionMark} alt="QuestionMark"/>
        </span>
        Spørsmål og svar 
      </div>

      <div className='questions-section-questions'>
        <Question
          title='Hva gjør en Nettgrav forskjellig fra en tradisjonell grav?'
          content='En Nettgrav gir en mer personlig måte å bevare minnet om den gravlagte på. I motsetning til en tradisjonell grav gir en Nettgrav en plattform for å dele historier, bilder og annet innhold som gir et mer fullstendig bilde av den avdøde. I tillegg er en Nettgrav tilgjengelig når som helst, noe som gir familie og venner tilgang uansett hvor de er i verden. Dette skaper en mer inkluderende opplevelse for alle som ønsker å være nær sine.'
        />
        <Question
          title='Er det mulig å si opp den fysiske graven via nettgrav.no?'
          content='Foreløpig ikke. Vi jobber med å gjøre dette mulig i fremtiden, slik at det blir enklere og mer fleksibelt å være ansvarlig for graver.'
        />
        <Question
          title='Fri og Nettgrav Bevisst?'
          content='Nettgrav kommer med to abonnementer: Fri og Bevisst. Fri gir tilgang til søk. Bevisst er medlemskap med alle funksjoner.'
        />
        <Question
          title='Er det mulig å opprette flere graver for samme person på nettgrav.no?'
          content='Nei, det er kun tillatt med én grav per person, akkurat som på en tradisjonell gravlund.'
        />
        <Question
          title='Hvem har tillatelse til å laste opp ord og minner på Nettgrav Gravtavle?'
          content='Når graven administreres av gravfestere eller administratorer, godkjennes relasjoner til den gravlagte av disse. Deretter kan godkjente personer benytte seg av gravens funksjoner. Når graven ikke er gjort krav på, kan familie og venner sette relasjon til den gravlagte som venter på godkjenning. De kan legge til informasjon på graven mens de venter.'
        />
        <Question
          title='Hvorfor må dokumenter lastes opp for å vise at man er ansvarlig for graven?'
          content='For å sikre et pålitelig nettverk krever Nettgrav bevis på ansvar for graven før vi gir administrasjonsrett. Godkjente dokumenter inkluderer dødsattest, faktura for gravstell eller festeavgift, eller advokatbrev som bekrefter eierskap.'
        />
      </div>
    </div>
  )
};


export default Questions;
