import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import LinkPreview from '../LinkPreview';
import styles from './RichInput.module.css';

const ContentEditable = ({
  onInput,
  value,
  editable = true,
  placeholder = '',
  style,
  linkPreview = false,
  setPreviewLink,
  previewLink,
  autoFocus = false,
}) => {
  const intl = useIntl();
  const editableDivRef = useRef(null);

  useEffect(() => {
    if (editableDivRef.current && value) {
      editableDivRef.current.innerHTML = value;
    }
  }, []);

  useEffect(() => {
    if (autoFocus && editableDivRef.current) {
      editableDivRef.current.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(editableDivRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [autoFocus]);

  useEffect(() => {
    if (editableDivRef.current && !value) {
      editableDivRef.current.innerHTML = '';
    }
  }, [value]);

  const handleInput = () => {
    let innerHTML = editableDivRef.current.innerHTML;
    if (innerHTML === '<br>') {
      innerHTML = '';
      editableDivRef.current.innerHTML = '';
    }
    onInput(innerHTML);
  };

  const handlePaste = async (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text/plain').trim();

    const urlPattern = /^(https?:\/\/[^\s]+)/;
    const range = window.getSelection().getRangeAt(0);
    range.deleteContents(); // Remove selected text before pasting

    let newNode;
    if (urlPattern.test(pastedText)) {
      // Create a link for valid URLs
      const anchor = document.createElement('a');
      anchor.href = pastedText;
      anchor.target = '_blank';
      anchor.textContent = pastedText;
      newNode = anchor;

      if (linkPreview) {
        setPreviewLink(pastedText);
      }
    } else {
      // Paste as plain text
      newNode = document.createTextNode(pastedText);
    }

    range.insertNode(newNode);

    // Move cursor to the end of the inserted text
    range.setStartAfter(newNode);
    range.setEndAfter(newNode);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    handleInput();
  };

  return (
    <div className={styles['container']}>
      {editable && !value && <div className={styles['placeholder']}>{placeholder}</div>}
      <div
        style={style}
        ref={editableDivRef}
        className={styles['richInput']}
        contentEditable={editable}
        onInput={handleInput}
        onPaste={handlePaste}
        aria-placeholder={intl.formatMessage({ id: 'comment_placeholder' })}
        aria-label={intl.formatMessage({ id: 'comment_placeholder' })}
      />

      {previewLink && <LinkPreview previewLink={previewLink} setPreviewLink={setPreviewLink} />}
    </div>
  );
};

export default ContentEditable;
