import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import X from '../../../../../../img/X.svg';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '6px',
    outline: 'none',
    width: '700px',
    minHeight: '500px',
    maxHeight: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: '12px',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    borderRadius: '6px 6px 0px 0px',
    zIndex: 10,
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    padding: '16px',
  },
  footer: {
    padding: '12px',
    borderTop: '1px solid #ddd',
  },
  closeButton: {
    cursor: 'pointer',
  },
}));

const SparkModal = ({ isOpen, children, footer, closeModal, title, style }) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent} style={style}>
        <div className={classes.header}>
          {title}
          <img
            src={X}
            onClick={() => closeModal(false)}
            alt='close'
            className={classes.closeButton}
          />
        </div>
        <div className={classes.content}>{children}</div>
        {footer && <div className={classes.footer}>{footer}</div>}
      </div>
    </Modal>
  );
};

export default SparkModal;
