import { Avatar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CreateMemorySection.module.css';
import InputButton from './InputButton';

const CreateMemorySection = ({ user, setOpenModal, graveName }) => {
  const navigate = useNavigate();

  const goToLifePage = () => {
    navigate(`/member/${user.user.slug}`);
  };

  return (
    <div className={styles.memorySection}>
      <Avatar
        src={user.user?.avatar}
        size={55}
        onClick={goToLifePage}
        style={{ cursor: 'pointer' }}
      />
      <InputButton setOpenModal={setOpenModal} graveName={graveName} />
    </div>
  );
};

export default CreateMemorySection;
