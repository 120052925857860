import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CommentsModal.module.css';

const Menu = ({ onDelete, setOpenEditModal, openEditModal, setOpenMenu }) => {
  return (
    <div className={styles.menu}>
      <Button
        type='text'
        icon={<EditOutlined />}
        style={{ justifyContent: 'flex-start' }}
        onClick={() => {
          setOpenEditModal(!openEditModal);
          setOpenMenu(false);
        }}
      >
        <FormattedMessage id='settings_edit' />
      </Button>
      <Button
        type='text'
        icon={<DeleteOutlined style={{ color: 'red' }} />}
        style={{ justifyContent: 'flex-start' }}
        onClick={() => {
          onDelete();
          setOpenMenu(false);
        }}
      >
        <FormattedMessage id='delete' />
      </Button>
    </div>
  );
};

export default Menu;
