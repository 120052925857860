import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Result, Row } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { CONSTANTS } from '../../../../../../service/constants';
import ConnectGraveModal from '../../../ConnectGraveModal/ConnectGraveModal';
import Grave from './Grave';
import styles from './GraveLink.module.css';

const GraveLink = () => {
  const grave = useSelector((state) => state.grave.grave);
  const loggedUser = useSelector((state) => state.user.user);
  const currentGraveUser = grave?.current_user_relation;
  const isClaimed = grave.grave_status === CONSTANTS.grave.claimed;
  const [openGraveConnectionModal, setOpenGraveConnectionModal] = useState(false);

  return (
    <div>
      <div className={styles.header}>
        <h3>
          <FormattedMessage id='grave_link' />
        </h3>
        {loggedUser.is_subscribed &&
          currentGraveUser &&
          (currentGraveUser?.admin || currentGraveUser?.grave_owner) &&
          isClaimed && (
            <Button
              type='text'
              style={{
                fontWeight: 'bold',
                background: '#f2f4f7',
                color: '#404d56',
                fontSize: 14,
              }}
              icon={<PlusOutlined />}
              onClick={() => setOpenGraveConnectionModal(true)}
            >
              <FormattedMessage id='connect_grave' />
            </Button>
          )}
      </div>
      <div style={{ marginTop: 12 }}>
        {grave?.linked_graves && grave.linked_graves.length > 0 ? (
          <Row gutter={[16, 16]} justify='center'>
            {grave.linked_graves.map((linkedGrave) => (
              <Col xs={24} xl={12} key={linkedGrave.id}>
                <Grave grave={grave} linkedGrave={linkedGrave} />
              </Col>
            ))}
          </Row>
        ) : (
          <Result
            status='404'
            title={<FormattedMessage id='no_grave_linked' />}
            subTitle={<FormattedMessage id='add_grave_linked_message' />}
          />
        )}
      </div>
      {openGraveConnectionModal && (
        <ConnectGraveModal
          onOpen={openGraveConnectionModal}
          onClose={() => setOpenGraveConnectionModal(false)}
          grave={grave}
        />
      )}
    </div>
  );
};

export default GraveLink;
