import { CaretDownFilled } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import UpgradePlanModal from '../../../../components/Modals/UpgradePlanModal';
import ConnectLogo from '../../../../img/ConnectGrave.svg';
import ConnectGraveModal from '../ConnectGraveModal/ConnectGraveModal';
import styles from './DeceasedMenu.module.css';

const DeceasedMenu = ({ isAuth, grave, user, isClaimed }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('grave_wall');
  const [openGraveConnectionModal, setOpenGraveConnectionModal] = useState(false);
  const [canAccessLinkedGraves, setCanAccessLinkedGraves] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const currentTab = searchParams.get('tab');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) {
      const graveUser = grave?.grave_users?.find((u) => u.user?.id === user.id);
      setAdmin(graveUser?.admin || graveUser?.grave_owner);
    }
  }, [grave, user]);

  useEffect(() => {
    if (!currentTab) {
      setActiveTab('grave_wall');
    } else {
      setActiveTab(currentTab.startsWith('about') ? 'about' : currentTab);
    }
  }, [location.pathname, searchParams]);

  const handleTabChange = (key) => {
    if (key === 'grave_wall') {
      navigate(`/deceased/${grave?.slug}`); // Keep URL clean for default tab
    } else {
      setSearchParams({ tab: key }); // Update URL for other tabs
    }
    setActiveTab(key);
  };

  const handleLinkedGraveOnClick = () => {
    if (user.subscription?.can_access_linked_graves) {
      setOpenGraveConnectionModal(true);
    } else {
      setCanAccessLinkedGraves(false);
    }
  };

  const menuItems = [
    { key: 'grave_wall', label: <FormattedMessage id='grave_wall' /> },
    { key: 'about', label: <FormattedMessage id='about' /> },
    { key: 'relationships', label: <FormattedMessage id='relationships' /> },
    { key: 'life_events', label: <FormattedMessage id='life_archive' /> },
  ];

  return (
    <div className={styles['tabs-container']}>
      {/* Upgrade Plan Modal */}
      <UpgradePlanModal
        isOpen={!canAccessLinkedGraves}
        onClose={() => setCanAccessLinkedGraves(true)}
        body={<FormattedMessage id='link_grave_body' />}
        title={<FormattedMessage id='upgrade_plan_modal' />}
      />

      {/* Ant Design Tabs Component */}
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        tabBarStyle={{ marginBottom: 0 }}
        style={{ padding: '0 16px' }}
        tabBarExtraContent={
          isAuth &&
          admin &&
          isClaimed &&
          (!isMobile ? (
            <Button
              type='primary'
              style={{ background: '#404d56' }}
              onClick={handleLinkedGraveOnClick}
            >
              <FormattedMessage id='connect_grave' />
            </Button>
          ) : (
            <Button
              icon={<img src={ConnectLogo} style={{ height: 18 }} alt='connect' />}
              shape='circle'
              style={{
                color: '#404d56',
                justifyContent: 'center',
              }}
              onClick={handleLinkedGraveOnClick}
            />
          ))
        }
        moreIcon={<CaretDownFilled />}
        items={menuItems.map((item) => ({
          key: item.key,
          label: item.label,
        }))}
      />

      {/* Connect Grave Modal */}
      {openGraveConnectionModal && (
        <ConnectGraveModal
          onOpen={openGraveConnectionModal}
          onClose={() => setOpenGraveConnectionModal(false)}
          grave={grave}
        />
      )}
    </div>
  );
};

export default DeceasedMenu;
