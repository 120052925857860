import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification';
import { setLifeEvents } from '../../../../../../redux/actions';
import { request } from '../../../../../../service/request';
import { getDescriptionColor, getTextColor } from '../../../../../../service/Utils';
import ConformModal from '../../../../../NewSettings/atoms/ConformDeleteModal';
import CreateEventModal from '../CreateEventModal';
import styles from './LifeEventDetails.module.css';

const LifeEventDetails = ({ event, user, grave }) => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const events = useSelector((state) => state.grave.lifeEvents);
  const [openModal, setOpenModal] = useState(false);
  const countries = useSelector((state) => state.countries.countries);

  const fineCountry = async () => {
    const country = await countries.find((country) => country.id.toString() === event.location);
    if (!isEmpty(country)) setCountry(country);
  };

  useEffect(() => {
    fineCountry();
  }, [event]);

  const deleteEvent = () => {
    request(`/life_events/${event.id}`, null, 'delete')
      .then((res) => {
        const newEvents = events.filter((e) => e.id !== event.id);
        dispatch(setLifeEvents([...newEvents]));
        showUserNotification('Livshendelsen er slettet', 'success');
        setOpenDeleteModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDateString = (dateString) => {
    const options = { month: 'short', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);

    return formattedDate;
  };

  const textColor = getTextColor(event.theme_color);
  const descriptionColor = getDescriptionColor(event.theme_color);

  const maxLength = 120;
  const truncatedDescription =
    event.description.length > maxLength
      ? event.description.slice(0, maxLength) + '....'
      : event.description;

  const handelOpenEditModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpenModal(true);
  };

  const handelOpenDeleteModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpenDeleteModal(true);
  };

  return (
    <div className={styles['details-container']}>
      <div className={styles['date-container']}>
        <div className={styles['date']}>
          {event.event_date ? formatDateString(event.event_date) : ''}
        </div>
        <div className={styles['location']}>{country.name ? country.name : ''}</div>
      </div>
      <div className={styles['title']} style={{ color: textColor }}>
        {event.event_title}
      </div>
      <div className={styles['description']} style={{ color: descriptionColor }}>
        {truncatedDescription}
      </div>
      {event.creator_id === user.id && (
        <div className={styles['action-btn']}>
          <Button
            type='primary'
            shape='circle'
            size='small'
            style={{
              fontWeight: 'bold',
              background: '#f2f4f7',
              color: '#404d56',
              fontSize: 14,
            }}
            icon={<EditOutlined />}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handelOpenEditModal(event);
            }}
          />
          <Button
            type='primary'
            shape='circle'
            size='small'
            style={{
              fontWeight: 'bold',
              background: '#f2f4f7',
              color: '#404d56',
              fontSize: 14,
            }}
            icon={<DeleteOutlined />}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handelOpenDeleteModal(event);
            }}
          />
        </div>
      )}
      {openDeleteModal && (
        <ConformModal
          title='lifeEvent'
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
          deletePayment={deleteEvent}
        />
      )}
      {openModal && (
        <CreateEventModal
          visible={openModal}
          onClose={() => setOpenModal(false)}
          grave={grave}
          event={event}
          user={user}
        />
      )}
    </div>
  );
};

export default LifeEventDetails;
