import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Popover, Space } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const PopoverMenu = ({ onEdit, onDelete, isImage }) => {
  const [visible, setVisible] = React.useState(false);
  const renderMenu = () => (
    <div style={{ width: 150 }}>
      <Space direction='vertical' style={{ width: '100%' }}>
        {isImage && (
          <Button
            type='text'
            style={{ justifyContent: 'flex-start' }}
            icon={<EditOutlined />}
            block
            onClick={() => {
              onEdit();
              setVisible(false);
            }}
          >
            <FormattedMessage id='edit' />
          </Button>
        )}
        <Button
          type='text'
          style={{ justifyContent: 'flex-start' }}
          icon={<DeleteOutlined />}
          danger
          block
          onClick={() => {
            onDelete();
            setVisible(false);
          }}
        >
          <FormattedMessage id='delete' />
        </Button>
      </Space>
    </div>
  );
  return (
    <Popover
      content={renderMenu()}
      trigger='click'
      placement='bottomRight'
      open={visible}
      onOpenChange={() => setVisible(!visible)}
    >
      <Button
        type='text'
        size='small'
        shape='circle'
        onClick={(e) => {
          e.stopPropagation();
          setVisible(!visible);
        }}
        icon={<MoreOutlined />}
        style={{
          position: 'absolute',
          background: '#fff',
          top: 8,
          right: 8,
          fontSize: 16,
        }}
      />
    </Popover>
  );
};

export default PopoverMenu;
