import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import { addMemory } from '../../redux/actions';
import { request } from '../../service/request';
import CommentsModal from '../NewMemoryWall/Components/CommentsModal/CommentsModal';
import MemoryDetails from '../NewMemoryWall/Components/MemoryDetails/MemoryDetails';
import NewMemoryWall from '../NewMemoryWall/NewMemoryWall';
import styles from './DeceasedTopHeader.module.css';
import Anniversaries from './atoms/Anniversaries/Anniversaries';

const DeceasedGraveWall = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const grave = useSelector((state) => state.grave.grave);
  const user = useSelector((state) => state.user) || {};
  const [searchParams] = useSearchParams();
  const [memory, setMemory] = useState(null);
  const [comments, setComments] = useState([]);
  const memories = useSelector((state) => state.grave.memories);

  const memoryId = searchParams.get('memory');
  const selectedImageId = searchParams.get('id');

  const onClose = () => {
    navigate(`/deceased/${grave.slug}`);
    setMemory(null);
    setComments([]);
  };

  const fetchMemory = async () => {
    if (!memoryId) {
      onClose();
      return;
    }

    await request(`/memories/${memoryId}`)
      .then((res) => {
        setMemory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchComments = async () => {
    if (!memoryId) {
      onClose();
      return;
    }

    await request(`/comments?comment[commentable_id]=${memoryId}&comment[commentable_type]=Memory`)
      .then((res) => {
        setComments(res.data.comments);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchMemory();
    fetchComments();
  }, [memoryId]);

  const findGraveUser = () => {
    return grave.current_user_relation.user.id === user.user.id;
  };

  const addReplyToParent = (commentsList, parentId, reply) => {
    return commentsList.map((comment) => {
      if (comment.id === parentId) {
        return {
          ...comment,
          replies_count: (comment.replies_count || 0) + 1,
          replies: [reply, ...(comment.replies || [])],
        };
      } else if (comment.replies && comment.replies.length > 0) {
        return {
          ...comment,
          replies: addReplyToParent(comment.replies, parentId, reply),
        };
      }
      return comment;
    });
  };

  const createComment = async (comment, id, parentId = null) => {
    try {
      const payload = {
        comment: {
          description: comment,
          commentable_id: id,
          commentable_type: 'Memory',
        },
      };

      if (parentId) {
        payload.comment.parent_id = parentId;
      }

      const res = await request(`/comments`, payload, 'post');

      if (parentId) {
        const updatedComments = addReplyToParent(comments, parentId, res.data);
        setComments(updatedComments);
      } else {
        setComments([res.data, ...comments]);
        setMemory({
          ...memory,
          comments_count: memory.comments_count + 1,
        });
        const updatedMemories = memories.map((m) => {
          if (m.id === memory.id) {
            return {
              ...m,
              comments_count: m.comments_count + 1,
            };
          }
          return m;
        });
        dispatch(addMemory(updatedMemories));
      }
    } catch (err) {
      showUserNotification(err.response?.data?.message || 'Error', 'error');
      console.error('Failed to create comment:', err);
    }
  };

  const updateCommentInTree = (commentsList, id, updatedComment) => {
    return commentsList.map((comment) => {
      if (comment.id === id) {
        return {
          ...comment,
          ...updatedComment,
        };
      } else if (comment.replies && comment.replies.length > 0) {
        return {
          ...comment,
          replies: updateCommentInTree(comment.replies, id, updatedComment),
        };
      }
      return comment;
    });
  };

  const editComment = async (id, editAbleComment) => {
    if (!id) return;
    try {
      const payload = {
        comment: {
          description: editAbleComment,
        },
      };
      const res = await request(`/comments/${id}`, payload, 'put');
      const updatedComments = updateCommentInTree(comments, id, res.data);

      setComments(updatedComments);
    } catch (err) {
      showUserNotification(err.response.data.message || 'Error', 'error');
      console.error('Failed to update comment:', err);
    }
  };

  const deleteComment = async (id) => {
    if (!id) return;
    try {
      const res = await request(`/comments/${id}`, null, 'delete');
      const { parent_id } = res.data;

      if (!parent_id) {
        setMemory({
          ...memory,
          comments_count: memory.comments_count - 1,
        });
        const updatedMemories = memories.map((m) => {
          if (m.id === memory.id) {
            return {
              ...m,
              comments_count: m.comments_count - 1,
            };
          }
          return m;
        });
        dispatch(addMemory(updatedMemories));
      }

      const updateReplies = (replies) => {
        return replies
          .map((reply) => {
            if (reply.id === id) {
              return null;
            }
            if (reply.replies && reply.replies.length > 0) {
              return {
                ...reply,
                replies: updateReplies(reply.replies),
                replies_count: reply.replies.filter((r) => r.id !== id).length,
              };
            }
            return reply;
          })
          .filter(Boolean);
      };

      const updatedComments = comments
        .map((comment) => {
          if (comment.id === parent_id) {
            return {
              ...comment,
              replies: updateReplies(comment.replies),
              replies_count: comment.replies.filter((r) => r.id !== id).length,
            };
          }

          if (comment.replies && comment.replies.length > 0) {
            return {
              ...comment,
              replies: updateReplies(comment.replies),
            };
          }

          if (comment.id === id) {
            return null;
          }

          return comment;
        })
        .filter(Boolean);

      setComments(updatedComments);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles['about-container']}>
      <Anniversaries grave={grave} />
      <NewMemoryWall grave={grave} user={user} isGraveUser={findGraveUser()} />
      {memory && memoryId && (
        <CommentsModal
          user={user}
          grave={grave}
          memory={memory}
          comments={comments}
          onClose={onClose}
          setMemory={setMemory}
          editComment={editComment}
          createComment={createComment}
          deleteComment={deleteComment}
        />
      )}
      {memory && selectedImageId && memoryId && (
        <MemoryDetails
          user={user}
          grave={grave}
          memory={memory}
          comments={comments}
          onClose={onClose}
          setMemory={setMemory}
          editComment={editComment}
          createComment={createComment}
          deleteComment={deleteComment}
          selectedImageId={selectedImageId}
        />
      )}
    </div>
  );
};

export default DeceasedGraveWall;
