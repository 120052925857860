import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Comment from './Comment';
import CommentReplyFooter from './CommentReplyFooter';
import styles from './CommentsModal.module.css';

const AllComments = ({
  comments,
  grave,
  memoryId,
  createComment,
  editComment,
  deleteComment,
  style,
}) => {
  const [commentReply, setCommentReply] = useState('');
  const [showReplies, setShowReplies] = useState(null);
  const graveUsers = useSelector((state) => state.grave.grave?.current_user_relation);

  const createCommentReply = async (comment) => {
    createComment(commentReply, memoryId, comment.id);
    setCommentReply('');
  };

  return (
    <div className={styles.allComments}>
      {comments.map((comment) => (
        <div>
          <Comment
            key={comment.id}
            style={style}
            comment={comment}
            grave={grave}
            memoryId={memoryId}
            showReplies={showReplies}
            setShowReplies={setShowReplies}
            editComment={editComment}
            deleteComment={deleteComment}
            createComment={createComment}
          />
          {showReplies === comment.id && (
            <CommentReplyFooter
              user={graveUsers}
              comment={commentReply}
              setComment={setCommentReply}
              createCommentReply={() => createCommentReply(comment)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default AllComments;
