import { Col, Image, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CoverPhoto.module.css';

const SelectCoverPhotoModal = ({ isOpen, grave, handleCancel, handleSelect, selectedImage }) => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <Modal
      open={isOpen}
      title={<FormattedMessage id='choose_cover_photo' />}
      footer={null}
      onCancel={handleCancel}
      width={window.innerWidth <= 768 ? '100%' : 800}
      style={{
        top: window.innerWidth <= 768 ? 0 : '5%',
        margin: window.innerWidth <= 768 ? 0 : 'auto',
        maxWidth: window.innerWidth <= 768 ? '100%' : 'calc(100% - 40px)',
      }}
      styles={{
        header: {
          color: '#333',
          fontSize: '18px',
          fontWeight: '600',
          padding: '12px 16px',
          borderBottom: '1px solid #e1e1e1',
        },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'calc(100vh - 100px)',
          borderRadius: window.innerWidth <= 768 ? 0 : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: 'auto',
          flex: 1,
        },
      }}
    >
      <div style={{ padding: '0px 16px 16px 16px' }}>
        <Row gutter={[4, 4]} justify='start'>
          {grave?.grave_images?.map((item) => (
            <Col key={item.file_url} xs={12} sm={8} md={6}>
              <div className={styles.imageContainer} onClick={() => handleSelect(item)}>
                <Image
                  src={item.file_url}
                  width='100%'
                  height='100%'
                  preview={false}
                  style={{
                    objectFit: 'cover',
                    borderRadius: 4,
                    cursor: 'pointer',
                    border: selectedImage === item.file_url ? '3px solid #1890ff' : 'none',
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Modal>
  );
};

export default SelectCoverPhotoModal;
