import React, { useState } from 'react';
import PopoverMenu from './PopoverMenu';
import styles from './Work.module.css';
import WorkForm from './WorkForm';
import WorkplaceCard from './WorkplaceCard';

const WorkPlaceList = ({
  workplace,
  currentGraveUser,
  loggedUser,
  onRemoveWorkplace,
  onSubmit,
}) => {
  const [openEditForm, setOpenEditForm] = useState(false);
  return (
    <div className={styles.workPlaceList}>
      {openEditForm ? (
        <WorkForm
          workplace={workplace}
          onCancel={() => setOpenEditForm(false)}
          onSubmit={onSubmit}
        />
      ) : (
        <>
          <WorkplaceCard workplace={workplace} />
          {currentGraveUser.user.id === workplace.user_id && loggedUser.is_subscribed && (
            <PopoverMenu
              workplace={workplace}
              onEditWorkplace={() => setOpenEditForm(true)}
              onRemoveWorkplace={onRemoveWorkplace}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WorkPlaceList;
