import { Button } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import { request } from '../../../../../service/request';
import ProcessModal from '../../../../Payment/atoms/ProcessModal';
import AnniversaryFormModal from '../AnniversaryForm/AnniversaryFormModal';
import Anniversary from './Anniversary';
import styles from './AnniversaryCard.module.css';
import CardHeader from './CardHeader';

const AnniversaryCard = ({
  titleKey,
  anniversaries,
  isPublic,
  grave,
  setAnniversaries,
  isGraveUser,
}) => {
  const [openForm, setOpenForm] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [selectedAnniversary, setSelectedAnniversary] = useState({});
  const [openProcessModal, setOpenProcessModal] = useState(false);

  const handleCreateAnniversary = async (data) => {
    setOpenForm(false);
    setOpenProcessModal(true);
    const payload = {
      grave_anniversary: {
        name: data.name,
        datetime: data.dateTime,
        grave_id: grave.id,
      },
    };
    request('/grave_anniversaries', payload, 'post')
      .then((response) => {
        setAnniversaries([response.data, ...anniversaries]);
        setOpenProcessModal(false);
      })
      .catch((error) => {
        console.log(error);
        setOpenProcessModal(false);
      });
  };

  const handleUpdateAnniversary = async (data, id) => {
    setOpenForm(false);
    setOpenProcessModal(true);
    const payload = {
      grave_anniversary: {
        name: data.name,
        datetime: data.dateTime,
        grave_id: grave.id,
      },
    };
    request(`/grave_anniversaries/${id}`, payload, 'put')
      .then((response) => {
        const updatedAnniversaries = anniversaries.map((anniversary) =>
          anniversary.id === id ? response.data : anniversary,
        );
        setAnniversaries(updatedAnniversaries);
        setOpenProcessModal(false);
        setSelectedAnniversary({});
      })
      .catch((error) => {
        console.log(error);
        setOpenProcessModal(false);
      });
  };

  const deleteAnniversary = (id) => {
    request(`/grave_anniversaries/${id}`, null, 'delete')
      .then(() => {
        const updatedAnniversaries = anniversaries.filter((anniversary) => anniversary.id !== id);
        setAnniversaries(updatedAnniversaries);
      })
      .catch((error) => {
        console.log(error);
        showUserNotification(error.response.data.error || error.message, 'error');
      });
  };

  const visibleAnniversaries = isPublic
    ? showAll
      ? anniversaries
      : anniversaries.slice(0, 3)
    : anniversaries;

  return (
    <div className={styles['container']}>
      <CardHeader
        titleKey={titleKey}
        setOpenForm={setOpenForm}
        isPublic={isPublic}
        isGraveUser={isGraveUser}
      />
      {visibleAnniversaries && visibleAnniversaries.length > 0 ? (
        visibleAnniversaries.map((anniversary) => (
          <Anniversary
            key={anniversary.id}
            isPublic={isPublic}
            anniversary={anniversary}
            setOpenForm={setOpenForm}
            setSelectedAnniversary={setSelectedAnniversary}
            deleteAnniversary={deleteAnniversary}
          />
        ))
      ) : (
        <div className={styles['empty-message']}>
          <FormattedMessage id='empty_anniversaries' />
        </div>
      )}
      {isPublic && anniversaries.length > 3 && (
        <Button
          onClick={() => setShowAll(!showAll)}
          size='small'
          type='primary'
          style={{
            fontWeight: 'bold',
            background: 'rgb(242, 244, 247)',
            color: 'rgb(64, 77, 86)',
            fontSize: 14,
          }}
        >
          {showAll ? <FormattedMessage id='close' /> : <FormattedMessage id='show_all' />}
        </Button>
      )}
      {openForm && (
        <AnniversaryFormModal
          isOpen={openForm}
          onClose={() => {
            setOpenForm(false);
            setSelectedAnniversary({});
          }}
          onCreate={handleCreateAnniversary}
          anniversary={selectedAnniversary}
          handleUpdateAnniversary={handleUpdateAnniversary}
        />
      )}
      {openProcessModal && (
        <ProcessModal open={openProcessModal} messageKey='create_anniversary_waiting' />
      )}
    </div>
  );
};

export default AnniversaryCard;
