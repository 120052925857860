import { SendOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import isEmpty from 'lodash.isempty';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ContentEditable from '../../../../../../components/RichTextEditor/ContentEditable';
import styles from './GravePhotos.module.css';

const CommentReplyFooter = ({ user, setComment, comment, createCommentReply }) => {
  return (
    <div className={styles.footerComment} style={{ padding: '0px 12px 12px 56px' }}>
      <div>
        <Avatar src={user?.user.user_avatar} className={styles.avatar} icon={<UserOutlined />} />
      </div>
      <div className={styles.inputContainer}>
        <ContentEditable
          placeholder={<FormattedMessage id='comment_placeholder' />}
          value={comment}
          onInput={setComment}
          editable={true}
          style={{
            minHeight: 70,
          }}
        />
        <Button
          className={styles.sendIcon}
          disabled={isEmpty(comment)}
          type='primary'
          shape='circle'
          icon={<SendOutlined />}
          onClick={createCommentReply}
        />
      </div>
    </div>
  );
};

export default CommentReplyFooter;
