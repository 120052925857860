import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification.js';
import { addReactionCache } from '../../../../../../graphql/CacheUpdates/ReactionsCache/addReactionCache.js';
import { removeReactionCache } from '../../../../../../graphql/CacheUpdates/ReactionsCache/removeReactionCache.js';
import { updatedReactionCache } from '../../../../../../graphql/CacheUpdates/ReactionsCache/updatedReactionCache.js';
import {
  useCreateReactionMutation,
  useDestroyReactionMutation,
  useUpdateReactionMutation,
  useUpdateSparkMutation,
} from '../../../../../../graphql/generated/sparkHooks.ts';
import Enlightened from '../../../../../../img/Enlightened.svg';
import Inspired from '../../../../../../img/Inspired.svg';
import Reflected from '../../../../../../img/Reflected.svg';
import { privacyList } from '../CreateSparks/CreateSparks.jsx';
import EditDescription from './EditDescription';
import Header from './Header';
import SparkFooter from './SparkFooter/SparkFooter.jsx';
import styles from './SparksList.module.css';

export const reactionList = {
  inspired: {
    title: <FormattedMessage id='inspired' />,
    icon: Inspired,
    type: 'inspired',
  },
  reflected: {
    title: <FormattedMessage id='reflected' />,
    icon: Reflected,
    type: 'reflected',
  },
  enlightened: {
    title: <FormattedMessage id='enlightened' />,
    icon: Enlightened,
    type: 'enlightened',
  },
};

const SparkPost = ({ spark, sparkCategories, user, renderSparkModal = false }) => {
  const intl = useIntl();
  const [editSpark, setEditSpark] = useState(false);
  const [description, setDescription] = useState('');
  const [sparkCategory, setSparkCategory] = useState({});
  const [privacy, setPrivacy] = useState('');
  const [destroyReaction] = useDestroyReactionMutation();
  const [updateReaction] = useUpdateReactionMutation();
  const [createReaction] = useCreateReactionMutation();

  const [updateSpark] = useUpdateSparkMutation({
    onCompleted: () => {
      setEditSpark(false);
      setDescription('');
      setSparkCategory({});
      setPrivacy('');
    },
  });

  useEffect(() => {
    if (sparkCategories.length > 0) setSparkCategory(sparkCategories[0]);
  }, [sparkCategories]);

  const onSave = async () => {
    if (description.trim() === '') {
      showUserNotification(
        'The description field cannot be empty. Please provide some details.',
        'error',
      );
      return;
    }
    try {
      await updateSpark({
        variables: {
          id: spark.id,
          description: description,
          sparkCategoryId: sparkCategory.id,
          visibility: privacy.key,
        },
      });
      showUserNotification(intl.formatMessage({ id: 'spark_update' }), 'success');
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        error.graphQLErrors.forEach(({ message }) => {
          showUserNotification(message, 'error');
        });
      }
      if (error.networkError) {
        showUserNotification('Network error: ' + error.networkError.message, 'error');
      }
      if (!error.graphQLErrors && !error.networkError) {
        showUserNotification('An unexpected error occurred: ' + error.message, 'error');
      }
    }
  };

  const handelReaction = async (type) => {
    try {
      await createReaction({
        variables: {
          reactableId: spark.id,
          reactionType: type,
          reactableType: 'Spark',
        },
        update: addReactionCache,
      });
    } catch (error) {
      console.log('Error in reaction mutation:', error.message);
    }
  };

  const handelRemoveReaction = async (reactionId, sparkId) => {
    try {
      await destroyReaction({
        variables: {
          id: reactionId,
        },
        update: removeReactionCache(sparkId),
      });
    } catch (error) {
      console.log('Error in removing reaction:', error.message);
    }
  };

  const handelUpdateReaction = async (reactionId, sparkId, newReactionType) => {
    try {
      await updateReaction({
        variables: {
          id: reactionId,
          reactionType: newReactionType,
        },
        update: updatedReactionCache(sparkId),
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const onClickEdit = (spark) => {
    setEditSpark(true);
    setDescription(spark.description);
    const findCategory = sparkCategories.find((category) => category.id === spark.sparkCategoryId);
    const findPrivacy = privacyList.find((privacy) => privacy.key === spark.visibility);
    if (findCategory) {
      setSparkCategory(findCategory);
    }
    if (findPrivacy) {
      setPrivacy(findPrivacy);
    }
  };

  const onCloseEdit = () => {
    setEditSpark(false);
    setDescription('');
  };

  return (
    <div className={renderSparkModal ? styles['spark-container-modal'] : styles['spark-container']}>
      <Header
        spark={spark}
        onClickEdit={onClickEdit}
        editSpark={editSpark}
        sparkCategories={sparkCategories}
        privacy={privacy}
        sparkCategory={sparkCategory}
        setPrivacy={setPrivacy}
        setSparkCategory={setSparkCategory}
        user={user}
      />
      {editSpark ? (
        <EditDescription
          description={description}
          setDescription={setDescription}
          onCloseEdit={onCloseEdit}
          onSave={onSave}
        />
      ) : (
        spark?.description && (
          <div
            className={styles['description']}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(spark?.description),
            }}
          />
          // <div className={styles['description']}>{spark?.description}</div>
        )
      )}
      <SparkFooter
        renderSparkModal={renderSparkModal}
        spark={spark}
        reactionList={reactionList}
        handelReaction={handelReaction}
        handelRemoveReaction={handelRemoveReaction}
        handelUpdateReaction={handelUpdateReaction}
        user={user}
        sparkCategories={sparkCategories}
      />
    </div>
  );
};

export default SparkPost;
