import { useEffect } from 'react';

const useNavigationWarning = (
  isLoading,
  message = 'Your post is still uploading. Are you sure you want to leave?',
) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isLoading) {
        event.preventDefault();
        event.returnValue = message; // Standard way to show an alert before leaving
      }
    };

    const handlePopState = (event) => {
      if (isLoading) {
        const confirmLeave = window.confirm(message);
        if (!confirmLeave) {
          window.history.pushState(null, '', window.location.href); // Prevents navigation
        }
      }
    };

    if (isLoading) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isLoading, message]);
};

export default useNavigationWarning;
