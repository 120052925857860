import { Button, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { FormattedMessage } from 'react-intl';
import 'react-toastify/dist/ReactToastify.css';
import styles from './CreatePostModal.module.css';

const CropImageModal = ({ editingFile, setEditingFile, setFileList }) => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [imageSrc, setImageSrc] = useState(null);
  const cropperRef = useRef(null);

  useEffect(() => {
    if (editingFile.type.startsWith('image/') && editingFile?.originFileObj?.preview) {
      setImageSrc(editingFile.originFileObj.preview);
    } else if (editingFile.type === 'image' && editingFile?.url) {
      fetch(editingFile.url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          setImageSrc(blobUrl || editingFile.url);
        })
        .catch((error) => console.error('Error loading image:', error));
    }
  }, [editingFile]);

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const getCropImage = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
        if (!blob || !editingFile) return;

        const croppedFile = new File([blob], editingFile.name, {
          type: editingFile.type || 'image/jpeg', // Ensure type is defined
          lastModified: Date.now(),
        });

        croppedFile.uid = editingFile.uid;
        croppedFile.originFileObj = croppedFile;
        croppedFile.preview = URL.createObjectURL(croppedFile); // Set new preview URL

        setFileList((prev) =>
          prev.map((file) =>
            file.uid === editingFile.uid
              ? { ...file, originFileObj: croppedFile, url: croppedFile.preview }
              : file,
          ),
        );

        setEditingFile(null);
      }, editingFile.type || 'image/jpeg');
    }
  };

  return (
    <Modal
      title={
        editingFile.type &&
        (editingFile.type.startsWith('image/') || editingFile.type === 'image') ? (
          <FormattedMessage id='edit_image' />
        ) : (
          <FormattedMessage id='edit_video' />
        )
      }
      open={!!editingFile}
      onCancel={() => setEditingFile(null)}
      footer={
        <div className={styles.footer}>
          <Button type='primary' style={{ backgroundColor: '#404d56' }} onClick={getCropImage}>
            <FormattedMessage id='auth_save' />
          </Button>
        </div>
      }
      style={{
        top: window.innerWidth <= 768 ? 0 : '10%',
        margin: window.innerWidth <= 768 ? 0 : 'auto',
        maxWidth: window.innerWidth <= 768 ? '100%' : 1000,
      }}
      styles={{
        header: {
          color: '#333',
          fontSize: '18px',
          fontWeight: '600',
          textAlign: 'center',
          padding: '12px 16px',
          borderBottom: '1px solid #e1e1e1',
        },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'calc(100vh - 200px)',
          borderRadius: window.innerWidth <= 768 ? 0 : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: 'auto',
          flex: 1,
          padding: '0px 16px',
        },
      }}
    >
      {imageSrc ? (
        <Cropper
          ref={cropperRef}
          className={styles.cropper}
          style={{ height: '100%', width: '100%' }}
          src={imageSrc}
          viewMode={1}
          guides
          autoCropArea={1}
          zoomOnWheel={false}
          background={false}
          responsive={true}
          aspectRatio={1} // Ensure an aspect ratio is set
          cropBoxMovable={true} // Allow moving crop box
          cropBoxResizable={true}
        />
      ) : (
        editingFile.type.startsWith('video/') ||
        (editingFile.type === 'video' && (
          <video controls style={{ width: '100%' }}>
            <source
              src={editingFile?.originFileObj?.preview || editingFile?.url}
              type={editingFile.type}
            />
          </video>
        ))
      )}
    </Modal>
  );
};

export default CropImageModal;
