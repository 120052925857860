import { MoreOutlined, SendOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Popover } from 'antd';
import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentEditable from '../../../../../../components/RichTextEditor/ContentEditable';
import { request } from '../../../../../../service/request';
import styles from './GravePhotos.module.css';
import Menu from './Menu';

const CommentReply = ({ comment, grave, refreshComments }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editAbleComment, setEditAbleComment] = useState(comment.description);

  const creator = grave.grave_users.find((graveUser) => graveUser.user?.id === comment.user_id);

  const editCommentReply = async () => {
    if (!comment.id) return;
    try {
      const graveCommentReply = {
        reply: {
          description: editAbleComment,
        },
      };
      await request(`/grave_image_replies/${comment.id}`, graveCommentReply, 'put');
      openEditModal && setOpenEditModal(false);
      await refreshComments();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCommentReply = async () => {
    if (!comment.id) return;
    try {
      await request(`/grave_image_replies/${comment.id}`, null, 'delete');
      await refreshComments();
    } catch (error) {
      console.log(error);
    }
  };

  const handelOpenEditModal = (open) => {
    if (open) {
      setEditAbleComment(comment.description);
    }
    setOpenEditModal(open);
  };

  return (
    <div>
      <div className={styles.commentContainer} style={{ padding: '12px 0px' }}>
        <div>
          <Avatar
            src={creator?.user.user_avatar}
            className={styles.avatar}
            icon={<UserOutlined />}
          />
        </div>
        {!openEditModal ? (
          <div className={styles.comment}>
            <div className={styles.userName}>{creator?.user.full_name}</div>
            <div
              className={styles.commentText}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.description) }}
            />
          </div>
        ) : (
          <div>
            <div className={styles.inputContainer}>
              <ContentEditable
                placeholder={<FormattedMessage id='comment_placeholder' />}
                value={editAbleComment}
                onInput={setEditAbleComment}
                editable={true}
                autoFocus={true}
              />
              <Button
                className={styles.sendIcon}
                type='primary'
                shape='circle'
                icon={<SendOutlined />}
                onClick={() => {
                  editCommentReply();
                }}
              />
            </div>
            <Button
              type='text'
              size='small'
              onClick={() => setOpenEditModal(false)}
              style={{ color: '#36434b', marginTop: '5px' }}
            >
              <FormattedMessage id='settings_cancel' />
            </Button>
          </div>
        )}
        {comment.can_edit && comment.can_destroy && !openEditModal && (
          <Popover
            placement='bottomLeft'
            open={openMenu}
            onOpenChange={() => setOpenMenu(false)}
            trigger='click'
            content={
              <Menu
                onDelete={deleteCommentReply}
                setOpenEditModal={handelOpenEditModal}
                openEditModal={openEditModal}
                setOpenMenu={setOpenMenu}
              />
            }
          >
            <Button
              onClick={() => setOpenMenu(true)}
              icon={<MoreOutlined rotate={90} />}
              type='text'
              shape='circle'
              size='small'
              style={{ color: '#36434b' }}
            />
          </Popover>
        )}
      </div>
    </div>
  );
};

export default CommentReply;
