import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React from 'react';
import { RiShareForwardLine } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './MemoryCard.module.css';

const { confirm } = Modal;

const Menu = ({ onDelete, setOpenEditModal, openEditModal, setOpenMenu, setOpenShareModal }) => {
  const intl = useIntl();

  const showDeleteConfirm = () => {
    confirm({
      title: intl.formatMessage({ id: 'delete_memory_title' }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: 'delete_memory_content' }),
      okText: intl.formatMessage({ id: 'delete_memory_confirm' }),
      okType: 'danger',
      cancelText: intl.formatMessage({ id: 'settings_cancel' }),
      onOk: onDelete,
    });
  };

  return (
    <div className={styles.menu}>
      <Button
        type='text'
        icon={<EditOutlined />}
        style={{ justifyContent: 'flex-start' }}
        onClick={() => {
          setOpenEditModal(!openEditModal);
          setOpenMenu(false);
        }}
      >
        <FormattedMessage id='settings_edit' />
      </Button>
      <Button
        type='text'
        icon={<RiShareForwardLine />}
        style={{ justifyContent: 'flex-start' }}
        onClick={() => {
          setOpenShareModal(true);
          setOpenMenu(false);
        }}
      >
        <FormattedMessage id='share' />
      </Button>
      <Button
        type='text'
        icon={<DeleteOutlined style={{ color: 'red' }} />}
        style={{ justifyContent: 'flex-start' }}
        onClick={() => {
          showDeleteConfirm();
          setOpenMenu(false);
        }}
      >
        <FormattedMessage id='delete' />
      </Button>
    </div>
  );
};

export default Menu;
