import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CustomModal from '../CustomModal';
import styles from './GraveInviteModal.module.css';

const GraveInviteModal = ({ onOpen, onClose, handelAction, graveInvitation, currentUser }) => {
  return (
    <CustomModal
      isOpen={onOpen}
      closeModal={onClose}
      title={
        <div>
          <FormattedMessage id='auth_welcome' />, {currentUser.full_name}
        </div>
      }
      children={
        <div className={styles['message-container']}>
          <span className={styles['message']}>
            <FormattedMessage id='grave_invitation_first_text' />{' '}
            <Link className={styles['link']} to={`/deceased/${graveInvitation.grave.slug}`}>
              {graveInvitation.grave.name}
            </Link>{' '}
            <FormattedMessage id='of' /> {graveInvitation.invited_by.name}.
          </span>
          <span className={styles['message']}>
            <FormattedMessage id='grave_invitation_second_text' />
          </span>
        </div>
      }
      footer={
        <div className={styles['grave-information_content-verify']}>
          <button className={styles['rootDiscard']} onClick={() => handelAction('decline')}>
            <FormattedMessage id='decline' />
          </button>
          <button className={styles['rootVerify']} onClick={() => handelAction('accept')}>
            <FormattedMessage id='accept' />
          </button>
        </div>
      }
    />
  );
};

export default GraveInviteModal;
