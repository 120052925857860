import React from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import AboutGraveTab from '../../containers/NewDeceased/atoms/AboutGraveTab/AboutGraveTab';
import PhotoDetails from '../../containers/NewDeceased/atoms/AboutGraveTab/components/GravePhotos/PhotoDetails';
import ClaimMessage from '../../containers/NewDeceased/atoms/ClaimMessageContainer/ClaimMessage';
import ClaimQueueMessage from '../../containers/NewDeceased/atoms/ClaimMessageContainer/ClaimQueueMessage';
import CoverPhoto from '../../containers/NewDeceased/atoms/CoverPhoto/CoverPhoto';
import DeceasedMenu from '../../containers/NewDeceased/atoms/DeceasedMenu/DeceasedMenu';
import LifeEventDetails from '../../containers/NewDeceased/atoms/LifeEventDetails/LifeEventDetails';
import LifeEventsPage from '../../containers/NewDeceased/atoms/NewLifeEvents/LifeEventsPage';
import Relationships from '../../containers/NewDeceased/atoms/Relationships/Relationships';
import DeceasedTopHeader from '../../containers/NewDeceased/DeceasedTopHeader';
import styles from './NewDeceased.module.css';

const AcceptedUsers = ({ grave, user, isAuth, isClaimed, isAcceptedUser }) => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab');

  const inClaimRequestQueue = () => {
    return isAuth && !grave.can_claim && grave.current_user_in_claim_queue;
  };

  const canRequestClaim = () => {
    return isAuth && grave.can_claim && !grave.current_user_in_claim_queue;
  };

  const isAboutTab = activeTab && activeTab?.startsWith('about');

  return (
    <>
      <div className={styles.topSection}>
        <div className={styles.container}>
          <CoverPhoto grave={grave} />
          <DeceasedTopHeader
            isAuth={isAuth}
            user={user}
            grave={grave}
            isClaimed={isClaimed}
            isAcceptedUser={isAcceptedUser}
          />
          <div className={styles['menu-tabs-deceased']}>
            <DeceasedMenu isAuth={isAuth} grave={grave} user={user} isClaimed={isClaimed} />
          </div>
        </div>
      </div>
      <div className={styles['deceased-page']}>
        {canRequestClaim() && <ClaimMessage grave={grave} isAcceptedUser={isAcceptedUser} />}
        {inClaimRequestQueue() && <ClaimQueueMessage grave={grave} />}
        {isAboutTab ? (
          <AboutGraveTab />
        ) : activeTab === 'life_events' ? (
          <LifeEventsPage />
        ) : activeTab === 'relationships' ? (
          <Relationships />
        ) : activeTab === 'photos' ? (
          <PhotoDetails />
        ) : activeTab === 'events' ? (
          <LifeEventDetails />
        ) : (
          <Outlet />
        )}

        {/* {grave.can_add_virtue && (
        <div className={styles['deceased-virtue']} ref={tabRef}>
          <PersonalityWrapper grave={grave} isAuth={isAuth} user={user} />
        </div>
      )} */}
      </div>
    </>
  );
};

export default AcceptedUsers;
