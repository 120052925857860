import React, { useState } from 'react';
import ConfirmDeleteModal from '../../../../NewSettings/atoms/ConformDeleteModal';
import styles from './Anniversary.module.css';
import PopoverMenu from './PopoverMenu';

const ActionButtons = ({ anniversary, setSelectedAnniversary, setOpenForm, deleteAnniversary }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const onConfirm = () => {
    setOpenConfirmModal(false);
    deleteAnniversary(anniversary.id);
  };

  const onEdit = () => {
    setOpenForm(true);
    setSelectedAnniversary(anniversary);
  };

  const onDelete = () => {
    setOpenConfirmModal(true);
  };

  return (
    <div className={styles['edit-btn-container']}>
      <PopoverMenu onEdit={onEdit} onDelete={onDelete} />
      {openConfirmModal && (
        <ConfirmDeleteModal
          open={openConfirmModal}
          handleClose={() => setOpenConfirmModal(false)}
          deletePayment={onConfirm}
          title='anniversary'
        />
      )}
    </div>
  );
};

export default ActionButtons;
