import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React from 'react';
import styles from './Anniversary.module.css';

const CalenderIcon = () => {
  return (
    <div className={styles['calender']}>
      <CalendarMonthIcon style={{ color: '#404D56', fontSize: 18 }} />
    </div>
  );
};

export default CalenderIcon;
