import { Avatar, Select, Typography } from 'antd';
import isEmpty from 'lodash.isempty';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import BoyAvatar from '../../../../img/BoyAvatar.svg';
import GirlsAvatar from '../../../../img/GirlsAvatar.svg';
import defaultBackground from '../../../../img/defaultGraveCoverPhoto.jpg';
import styles from './ConnectGraveModal.module.css';

const { Text } = Typography;

const JoinGraves = ({
  grave,
  linkedGrave,
  relation,
  relationDirection,
  setRelation,
  setRelationDirection,
}) => {
  const intl = useIntl();
  const relationDirections = useSelector((state) => state.grave.relationDirections);
  const relations = useSelector((state) => state.grave.relations);
  const [filteredDirections, setFilteredDirections] = useState([]);

  // Handle Relation Selection
  const handleOnSelectRelation = (option) => {
    if (!option) {
      setRelationDirection(null);
      setRelation(null);
      setFilteredDirections([]);
    } else {
      setRelationDirection(null);
      setRelation(option);
      setFilteredDirections(filterDirectionList(option));
    }
  };

  // Handle Reciprocal Relation Selection
  const handleOnSelectReciprocalRelation = (option) => {
    console.log(option);

    if (!option) {
      setRelationDirection(null);
    } else {
      setRelationDirection(option);
    }
  };

  console.log(relationDirection);
  console.log(relation);

  // Filter Reciprocal Relations Based on Selected Relation
  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = relationDirections
      .filter((item) => item.from_relation.id === selectedRelation.id)
      .map((item) => item.to_relation);

    return filteredList.length > 0 ? filteredList : [selectedRelation];
  };

  return (
    <div className={styles.graveLinkContainer}>
      <div className={styles.graveContainer} style={{ maxWidth: 136 }}>
        <div className={styles.graveWrapper}>
          <div className={styles.graveAvatar}>
            <Avatar
              src={grave?.avatar}
              shape='square'
              style={{ borderRadius: 0, height: '100%', width: '100%' }}
              icon={
                <img
                  src={
                    grave?.gender === 'mann'
                      ? BoyAvatar
                      : grave.gender === 'kvinne'
                      ? GirlsAvatar
                      : defaultBackground
                  }
                />
              }
            />
          </div>
          <div className={styles.graveName}>{grave?.name}</div>
          <div className={styles.graveDates}>
            {grave?.date_of_birth && (
              <Text type='secondary'>{grave?.date_of_birth.substring(0, 4)}</Text>
            )}{' '}
            -{' '}
            {grave?.date_of_death && (
              <Text type='secondary'>{grave?.date_of_death.substring(0, 4)}</Text>
            )}
          </div>
          <div className={styles.graveRelation}>{grave.current_user_relation?.relation.name}</div>
        </div>
        <Select
          showSearch
          value={relation?.id}
          style={{ width: '100%' }}
          placeholder={intl.formatMessage({ id: 'select_relationship' })}
          onChange={(_value, option) =>
            handleOnSelectRelation(option ? option['data-object'] : null)
          }
          optionFilterProp='label'
          options={relations.map((rel) => ({
            label: rel.key ? intl.formatMessage({ id: rel.key }) : rel.name,
            value: rel.id,
            'data-object': rel,
          }))}
          allowClear
        ></Select>
      </div>
      <div className={styles.linkIconContainer}>
        {relation && (
          <>
            <div className={styles.graveRelation} style={{ marginBottom: 8 }}>
              {relation?.key ? <FormattedMessage id={relation.key} /> : relation.name}
            </div>
            <div className={styles.linkIcon}>
              <div className={styles.circleDot} />
              <div className={styles.line} />
              <div className={styles.filledDot} />
            </div>
          </>
        )}
        {relationDirection && (
          <>
            <div className={styles.linkIcon} style={{ marginTop: 8 }}>
              <div className={styles.filledDot} />
              <div className={styles.line} />
              <div className={styles.circleDot} />
            </div>
            <div className={styles.graveRelation} style={{ marginBottom: 8 }}>
              {relationDirection?.key ? (
                <FormattedMessage id={relationDirection.key} />
              ) : (
                relationDirection.name
              )}
            </div>
          </>
        )}
      </div>
      <div className={styles.graveContainer} style={{ maxWidth: 136 }}>
        <div className={styles.graveWrapper}>
          <div
            className={styles.graveAvatar}
            style={{ borderColor: linkedGrave ? '#36434B' : 'transparent' }}
          >
            <Avatar
              src={linkedGrave?.avatar}
              shape='square'
              style={{ borderRadius: 0, height: '100%', width: '100%' }}
              icon={
                <img
                  src={
                    linkedGrave?.gender === 'mann'
                      ? BoyAvatar
                      : linkedGrave.gender === 'kvinne'
                      ? GirlsAvatar
                      : defaultBackground
                  }
                />
              }
            />
          </div>
          <div className={styles.graveName}>{linkedGrave?.name}</div>
          <div className={styles.graveDates}>
            <div className={styles.graveDates}>
              {linkedGrave?.date_of_birth && (
                <Text type='secondary'>{linkedGrave?.date_of_birth.substring(0, 4)}</Text>
              )}{' '}
              -{' '}
              {linkedGrave?.date_of_death && (
                <Text type='secondary'>{linkedGrave?.date_of_death.substring(0, 4)}</Text>
              )}
            </div>
          </div>
          <div className={styles.graveRelation}>{linkedGrave?.current_user_relation.relation}</div>
        </div>
        <Select
          showSearch
          style={{ width: '100%' }}
          value={relationDirection?.id}
          placeholder={intl.formatMessage({ id: 'select_relationship' })}
          onChange={(_value, option) =>
            handleOnSelectReciprocalRelation(option ? option['data-object'] : null)
          }
          allowClear
          optionFilterProp='label'
          options={filteredDirections.map((rel) => ({
            label: rel.key ? intl.formatMessage({ id: rel.key }) : rel.name,
            value: rel.id,
            'data-object': rel,
          }))}
          disabled={relation === null}
        ></Select>
      </div>
    </div>
  );
};

export default JoinGraves;
