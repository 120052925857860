import {
  addRelationsMember,
  setActionCableConsumer,
  setActiveSubscription,
  setNotificationChannelSubscribed,
  setUserInformation,
  setUserNotificationRequestStatus,
  setUserNotifications,
  updateUser,
  updateUserNotificationButton,
  updateUserNotificationRead,
  userReadNotifications,
  userUnReadNotifications,
  updateNotificationIsRead,
} from '../actions';

const userState = {
  user: {},
  relations: [],
  notifications: [],
  readNotifications: [],
  unreadNotifications: [],
  notificationChannelSubscribed: false,
  actionCableConsumer: {},
  activeSubscription: {},
  //avatar: '',
};

export const userReducer = (state = userState, action) => {
  switch (action.type) {
    case setUserInformation:
      return {
        ...state,
        user: action.userInfo,
        //avatar: action.avatar
      };
    case addRelationsMember:
      return {
        ...state,
        relations: action.members,
      };
    case setUserNotifications:
      return {
        ...state,
        notifications: action.payload,
      };
    case updateNotificationIsRead:
      return {
        ...state,
        readNotifications: state.readNotifications.map((notification) =>
          notification.id === action.payload ? { ...notification, read: true } : notification,
        ),
        unreadNotifications: state.unreadNotifications.map((notification) =>
          notification.id === action.payload ? { ...notification, read: true } : notification,
        ),
      };
    case updateUserNotificationButton:
      return {
        ...state,
        readNotifications: state.readNotifications.map((notification) =>
          notification.id === action.payload.id ? action.payload : notification,
        ),
        unreadNotifications: state.unreadNotifications.map((notification) =>
          notification.id === action.payload.id ? action.payload : notification,
        ),
      };
    case updateUserNotificationRead:
      return {
        ...state,
        readNotifications: [...state.unreadNotifications, ...state.readNotifications],
        unreadNotifications: [],
      };
    case setUserNotificationRequestStatus:
      return {
        ...state,
        notifications: state.notifications.map((notif) =>
          notif.id === action.payload.notificationId
            ? {
                ...notif,
                show_button: false,
                requestStatus: action.payload.requestStatus,
                read: true,
              }
            : notif,
        ),
      };
    case setNotificationChannelSubscribed:
      return {
        ...state,
        notificationChannelSubscribed: action.payload,
      };
    case setActionCableConsumer:
      return {
        ...state,
        actionCableConsumer: action.payload,
      };
    case userReadNotifications:
      return {
        ...state,
        readNotifications: action.payload,
      };
    case userUnReadNotifications:
      return {
        ...state,
        unreadNotifications: action.payload,
      };
    case updateUser:
      return {
        ...state,
        user: {
          ...state.user,
          is_subscribed: action.payload,
        },
      };
    case setActiveSubscription:
      return {
        ...state,
        activeSubscription: action.payload,
      };
    default:
      return state;
  }
};
