import isEmpty from 'lodash.isempty';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './GravePhotos.module.css';
import Header from './Header';

const CommentArea = ({ image, grave, images, setImages }) => {
  const creator = grave.grave_users.find((graveUser) => graveUser.user?.id === image.user_id);

  return (
    <div>
      <Header image={image} graveUser={creator} images={images} setImages={setImages} />
      {!isEmpty(image?.description) && (
        <div className={styles.description}>{image.description}</div>
      )}
      {!isEmpty(image?.address) && (
        <div className={styles.address}>{`Address: ${image.address}`}</div>
      )}
      <div className={styles.totalCommentsContainer}>
        {image.image_comments_count > 0 && (
          <div className={styles.totalComments}>
            {image.image_comments_count} <FormattedMessage id='comments' />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentArea;
