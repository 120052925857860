import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Loader/Loader';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { request } from '../../../../service/request';
import AnniversaryCard from './AnniversaryCard/AnniversaryCard';

const Anniversaries = ({ grave }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [anniversaries, setAnniversaries] = useState([]);
  const [publicAnniversaries, setPublicAnniversaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.user);

  const handleResize = () => {
    if (window.innerWidth >= 1020) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded]);

  const fetchPublicAnniversaries = useCallback(async () => {
    if (!grave.id) return;
    setLoading(true);
    try {
      const newData = await request(`/public_anniversaries`);
      if (newData.data.public_anniversaries.length > 0) {
        setPublicAnniversaries(newData.data.public_anniversaries);
      } else {
        setPublicAnniversaries([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      showUserNotification(error.response.data.errors || error.message, 'error');
      setLoading(false);
    }
  }, [grave.id]);

  const fetchAnniversaries = useCallback(async () => {
    if (!grave.id) return;
    setLoading(true);
    try {
      const newData = await request(`/grave_anniversaries?grave_id=${grave.id}`);
      if (newData.data.grave_anniversaries.length > 0) {
        setAnniversaries(newData.data.grave_anniversaries);
      } else {
        setAnniversaries([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      showUserNotification(error.response.data.errors || error.message, 'error');
      setLoading(false);
    }
  }, [grave.id]);

  useEffect(() => {
    fetchAnniversaries();
    fetchPublicAnniversaries();
  }, [fetchAnniversaries, fetchPublicAnniversaries]);

  const getUser = () => {
    if (grave?.grave_users.length > 0) {
      const isGraveUser = grave?.grave_users.find((graveUser) => graveUser.user?.id === user.id);
      if (isGraveUser) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div style={{ flex: 0.7 }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <AnniversaryCard
            titleKey='public_anniversaries'
            isPublic={true}
            anniversaries={publicAnniversaries}
            grave={grave}
            isGraveUser={getUser()}
          />
          <AnniversaryCard
            titleKey='grave_anniversaries'
            anniversaries={anniversaries}
            setAnniversaries={setAnniversaries}
            isPublic={false}
            grave={grave}
            isGraveUser={getUser()}
          />
        </>
      )}
    </div>
  );
};

export default Anniversaries;
