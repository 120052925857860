import React from 'react';
import AboutUser from './AboutUser';
import ProfileBackground from './ProfileBackground';
import SparksSubMenuDesktop from './SparksSubMenu/SparksSubMenuDesktop';
import styles from './UserHeader.module.css';

const UserHeader = ({ user, setCurrentUser }) => {
  return (
    <div className={styles['main-container']}>
      <ProfileBackground user={user} setCurrentUser={setCurrentUser} />
      <div className={styles['width-container']}>
        <div className={styles['padding-container']}>
          <AboutUser user={user} setCurrentUser={setCurrentUser} />
          <SparksSubMenuDesktop user={user} />
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
