import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Result, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { setLinkedGravesRedux } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import styles from './ConnectGraveModal.module.css';
import Grave from './Grave';
import JoinGraves from './JoinGraves';

const ConfirmDiscardModal = ({ open, onDiscard, onCancel }) => (
  <Modal
    title={<FormattedMessage id='discard_changes' />}
    open={open}
    onCancel={onCancel}
    onOk={onDiscard}
    okType='danger'
    okText={<FormattedMessage id='discard' />}
    cancelText={<FormattedMessage id='settings_cancel' />}
  >
    <FormattedMessage id='memory_discard_message' />
  </Modal>
);

const ConnectGraveModal = ({ onClose, onOpen, grave }) => {
  const dispatch = useDispatch();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [confirmDiscardOpen, setConfirmDiscardOpen] = useState(false);
  const [chosenGrave, setChosenGrave] = useState(null);
  const [gravesList, setGravesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relationDirection, setRelationDirection] = useState(null);
  const [relation, setRelation] = useState(null);

  const fetchGraves = async () => {
    setLoading(true);
    try {
      const response = await request(
        `/available_graves_for_connections?source_grave_id=${grave.id}`,
      );
      setGravesList(response.data.graves);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGraves();
  }, [grave.id]);

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight(); // Set initial height

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const handleCancel = () => {
    if (chosenGrave) {
      setConfirmDiscardOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    const handleBackButton = (event) => {
      if (onOpen) {
        event.preventDefault();
        handleCancel(); // Close the modal instead of going back
      }
    };

    if (onOpen) {
      window.history.pushState(null, '', window.location.href); // Add a fake history entry
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [onOpen, handleCancel]);

  const handleDiscard = () => {
    setConfirmDiscardOpen(false);
    setChosenGrave(null);
    setRelationDirection(null);
    setRelation(null);
    onClose();
  };

  const onBack = () => {
    setChosenGrave(null);
    setRelationDirection(null);
    setRelation(null);
  };

  const handleSave = async () => {
    const formData = {
      source_grave_id: grave.id,
      target_grave_id: chosenGrave.id,
      relation_id: relation.id,
      reciprocal_relation_id: relationDirection.id,
    };
    await request('/link_graves', formData, 'post')
      .then((res) => {
        handleDiscard();
        dispatch(setLinkedGravesRedux([res.data, ...grave.linked_graves]));
      })
      .catch((error) => {
        showUserNotification('Could not send connection request. Please try again later', 'error');
      });
  };

  return (
    <Modal
      title={
        chosenGrave ? (
          <Button icon={<ArrowLeftOutlined />} type='text' shape='circle' onClick={onBack} />
        ) : (
          <FormattedMessage id='connect_grave_title' values={{ graveName: grave.name }} />
        )
      }
      open={onOpen}
      onCancel={handleCancel}
      centered={chosenGrave}
      width={window.innerWidth <= 768 ? '100%' : 800}
      style={{
        top: window.innerWidth <= 768 ? 0 : chosenGrave ? 'auto' : '10%',
        margin: window.innerWidth <= 768 ? 0 : 'auto',
        maxWidth: window.innerWidth <= 768 ? '100%' : 'calc(100% - 40px)',
      }}
      styles={{
        header: {
          color: '#333',
          fontSize: '18px',
          fontWeight: '600',
          padding: '12px 0px',
          borderBottom: '1px solid #e1e1e1',
        },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: '0px 12px 12px 12px',
          height:
            window.innerWidth <= 768
              ? `${viewportHeight}px`
              : chosenGrave
              ? 'auto'
              : 'calc(100vh - 200px)',
          borderRadius: window.innerWidth <= 768 ? 0 : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: 'auto',
          overflowX: 'hidden',
          flex: 1,
        },
      }}
      footer={
        chosenGrave ? (
          <div className={styles.footer}>
            <Button onClick={onBack}>
              <FormattedMessage id='auth_back' />
            </Button>
            <Button type='primary' onClick={handleSave} style={{ backgroundColor: '#404d56' }}>
              <FormattedMessage id='auth_save' />
            </Button>
          </div>
        ) : null
      }
    >
      <>
        {loading ? (
          <div className={styles.loading}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
          </div>
        ) : (
          <>
            {chosenGrave ? (
              <JoinGraves
                grave={grave}
                linkedGrave={chosenGrave}
                relation={relation}
                relationDirection={relationDirection}
                setRelationDirection={setRelationDirection}
                setRelation={setRelation}
              />
            ) : (
              <>
                {gravesList && gravesList.length > 0 ? (
                  <Row gutter={[16, 16]} justify='start' wrap>
                    {gravesList.map((linkedGrave) => (
                      <Col
                        flex='1 1 136px' // Minimum width 300px, flexible
                        style={{ maxWidth: '100%' }}
                        key={linkedGrave.id}
                      >
                        <Grave grave={linkedGrave} setChosenGrave={setChosenGrave} />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Result status='404' title={<FormattedMessage id='graves_not_found' />} />
                )}
              </>
            )}
          </>
        )}
        <ConfirmDiscardModal
          open={confirmDiscardOpen}
          onDiscard={handleDiscard}
          onCancel={() => setConfirmDiscardOpen(false)}
        />
      </>
    </Modal>
  );
};

export default ConnectGraveModal;
