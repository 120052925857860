import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { setLifeEvents } from '../../../../../../redux/actions';
import { request } from '../../../../../../service/request';
import EditImageModal from './EditImageModal';
import styles from './Event.module.css';
import PopoverMenu from './PopoverMenu';

const EventMedia = ({ media, currentIndex, onNext, onPrev, setOpenModal, isCreator, event }) => {
  const dispatch = useDispatch();
  const [_searchParams, setSearchParams] = useSearchParams();
  const [openEditModal, setOpenEditModal] = useState(false);
  const events = useSelector((state) => state.grave.lifeEvents);
  const handlers = useSwipeable({
    onSwipedLeft: () => onNext(),
    onSwipedRight: () => onPrev(),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const fileURL = media[currentIndex]?.file_url;
  const isImage = media[currentIndex]?.type === 'image';
  const isVideo = media[currentIndex]?.type === 'video';

  const handleEdit = () => {
    setOpenEditModal(true);
  };

  const onSave = async (image) => {
    try {
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      if (image) {
        const blob = new Blob([image], { type: image.type });
        const formData = new FormData();
        formData.append('life_event_image[file]', blob, 'event_image');
        const res = await request(
          `/life_event_images/${media[currentIndex].id}`,
          formData,
          'put',
          headers,
        );
        if (res.data) {
          const updatedList = events.map((e) => {
            if (e.id === res.data.life_event_id) {
              return {
                ...e,
                life_event_images: e.life_event_images.map((img) =>
                  img.id === res.data.id ? res.data : img,
                ),
              };
            } else {
              return e;
            }
          });

          dispatch(setLifeEvents(updatedList));
        }
        setOpenEditModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = async () => {
    try {
      const currentMedia = media[currentIndex];
      const isImage = currentMedia.type === 'image';
      const deleteEndpoint = isImage
        ? `/life_event_images/${currentMedia.id}`
        : `/life_event_videos/${currentMedia.id}`;
      const mediaKey = isImage ? 'life_event_images' : 'life_event_videos';

      await request(deleteEndpoint, null, 'delete');

      const updatedList = events.map((eventItem) => {
        if (eventItem.id !== event.id) return eventItem;

        const updatedMedia = (eventItem[mediaKey] || []).filter(
          (item) => item.id !== currentMedia.id,
        );

        return {
          ...eventItem,
          [mediaKey]: updatedMedia,
        };
      });

      dispatch(setLifeEvents(updatedList));
      setOpenEditModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={styles.modalContent} {...handlers}>
        {media.length > 0 ? (
          <>
            {/* Left Navigation */}
            {media.length > 1 && (
              <Button
                className={styles.navIcon}
                shape='circle'
                icon={<LeftOutlined />}
                onClick={onPrev}
                size='small'
              />
            )}

            {/* Display Media */}
            <div
              className={styles.mediaWrapper}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSearchParams({ tab: 'events', event_id: event.id });
              }}
            >
              {isImage && <img src={fileURL} alt='Full View' className={styles.fullImage} />}
              {isVideo && <video src={fileURL} controls muted className={styles.fullVideo} />}
              {!isImage && !isVideo && (
                <div className={styles.fullImage}>
                  <FormattedMessage id='media_not_supported' />
                </div>
              )}
            </div>
            {isCreator && <PopoverMenu onEdit={handleEdit} isImage={isImage} onDelete={onDelete} />}

            {/* Right Navigation */}
            {media.length > 1 && (
              <Button
                onClick={onNext}
                className={styles.navIcon}
                icon={<RightOutlined />}
                shape='circle'
                size='small'
              />
            )}
          </>
        ) : (
          isCreator && (
            <Button
              type='text'
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <FormattedMessage id='add_photos_videos' />
            </Button>
          )
        )}
      </div>
      {openEditModal && (
        <EditImageModal
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          cropperImage={media[currentIndex].file_url}
          onSave={onSave}
        />
      )}
    </>
  );
};

export default EventMedia;
