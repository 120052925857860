import { PlusOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SearchInput from '../../../../../../components/SearchInput';
import { setGraveInterestsRedux } from '../../../../../../redux/actions';
import { fetch_request, request } from '../../../../../../service/request';
import styles from './Interests.module.css';
import InterestForm from './InterestsForm';
import InterestsList from './InterestsList';

const Interests = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const grave = useSelector((state) => state.grave.grave);
  const loggedUser = useSelector((state) => state.user.user);
  const currentGraveUser = grave?.current_user_relation;
  const [interests, setInterests] = useState([]);
  const [openInterestForm, setOpenInterestForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [captivatedInterests, setCaptivatedInterests] = useState([]);

  const handleOpenInterestsForm = () => {
    setOpenInterestForm(true);
  };

  useEffect(() => {
    setInterests(grave.grave_interests);
  }, [grave.grave_interests]);

  useEffect(() => {
    if (interests) {
      setLoading(false);
    }
  }, [interests]);

  useEffect(() => {
    request('/captivated_interests', null, 'get')
      .then((res) => {
        if (res.data?.captivated_interests) {
          setCaptivatedInterests(res.data.captivated_interests);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const filterInterests = (searchItem) => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(`/api/grave_interests?grave_id=${grave.id}&search=${searchItem}`, requestOptions)
      .then((response) => response.json())
      .then((data) => setInterests(data.grave_interests))
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitInterests = async (values, id) => {
    try {
      const interestData = {
        grave_interest: {
          name: values.name,
          captivated_interest_id: values.captivated_interest_id,
          description: values.description,
          ...(id ? {} : { grave_id: grave.id }), // Only add grave_id for new interest
        },
      };

      const apiEndpoint = id ? `/grave_interests/${id}` : '/grave_interests';

      const method = id ? 'put' : 'post';

      const response = await request(apiEndpoint, interestData, method);

      if (response.data) {
        // Transform received data
        const transformedInterest = {
          id: response.data.interest.id,
          name: response.data.interest.name,
          description: response.data.interest.description,
          created_at: response.data.interest.created_at,
          updated_at: response.data.interest.updated_at,
          captivated_interest: captivatedInterests.find(
            (item) => item.id === response.data.interest.captivated_interest_id,
          ), // Map captivated_interest_id to full object
        };

        const updatedInterests = id
          ? interests.map((w) => (w.id === id ? transformedInterest : w))
          : [transformedInterest, ...interests];

        dispatch(setGraveInterestsRedux(updatedInterests));
        setInterests(updatedInterests);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteInterests = (id) => {
    if (id) {
      request(`/grave_interests/${id}`, null, 'delete')
        .then((res) => {
          const newArray = interests.filter((work) => work.id !== id);
          dispatch(setGraveInterestsRedux(newArray));
          setInterests(newArray);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h3>
          <FormattedMessage id='interests' />
        </h3>
        <SearchInput
          filterItems={filterInterests}
          placeholder={intl.formatMessage({ id: 'find_interests' })}
        />
      </div>
      {loggedUser.is_subscribed && currentGraveUser && (
        <Button
          type='text'
          style={{
            marginTop: 12,
            display: openInterestForm ? 'none' : 'flex',
            fontWeight: 'bold',
            background: '#f2f4f7',
            color: '#404d56',
            fontSize: 14,
          }}
          icon={<PlusOutlined />}
          onClick={handleOpenInterestsForm}
        >
          <FormattedMessage id='add_interest' />
        </Button>
      )}
      {openInterestForm && (
        <div className={styles.interestsForm}>
          <InterestForm
            onSubmit={onSubmitInterests}
            onCancel={() => setOpenInterestForm(false)}
            captivatedInterests={captivatedInterests}
          />
        </div>
      )}

      <div style={{ marginTop: 12, height: loading ? 400 : 'auto' }}>
        {interests && interests.length > 0
          ? interests.map((interest) => (
              <InterestsList
                key={interest.id}
                interest={interest}
                onSubmit={onSubmitInterests}
                loggedUser={loggedUser}
                currentGraveUser={currentGraveUser}
                onRemoveInterest={deleteInterests}
                captivatedInterests={captivatedInterests}
              />
            ))
          : !loading && (
              <Result
                status='404'
                title={<FormattedMessage id='no_interests_added' />}
                subTitle={<FormattedMessage id='add_interests_message' />}
              />
            )}
      </div>
    </div>
  );
};

export default Interests;
