import {
  addCommentToGrave,
  addCommentToMemoryOnGrave,
  addGraveAdminType,
  addMemoryToGrave,
  addReplyToCommentOnGrave,
  addVirtueToGrave,
  availableSearchCount,
  chooseGraveFromSearch,
  disablePublishBtn,
  editCommentToMemoryOnGrave,
  editMemoryOnGrave,
  editReplyToCommentOnGrave,
  editVirtueOnGrave,
  eventMonthIndex,
  eventYearIndex,
  graveImages,
  graveInvitationToken,
  lifeEvents,
  openSelectedEvent,
  pendingRelation,
  removeCommentFromMemoryOnGrave,
  removeMemoryFromGrave,
  removeReplyFromCommentOnGrave,
  removeVirtueFromGrave,
  selectedEvent,
  selectedEventYear,
  SET_GRAVE_CURRENT_USER_RELATION,
  setCommentOnToggle,
  setCurrentUserInClaimQueue,
  setCurrentUserInRelationQueue,
  setDirectionsList,
  setGraveInterests,
  setGraveInvitations,
  setGravePassion,
  setGraveUsers,
  setGraveWorkplaces,
  setLifeEventsPhoto,
  setLifeEventsVideo,
  setLinkedGraves,
  setRelationList,
  setRelationType,
  setSectionEdit,
  setUpdateGrave,
  uploadingMemory,
} from '../actions';

const graveState = {
  isClaimed: false,
  grave: {},
  graveInvitations: [],
  comments: [],
  commentOnToggle: { type: 'text' },
  uploading: false,
  disable: true,
  availableSearchCount: null,
  lifeEventPhotos: [],
  lifeEventVideos: [],
  lifeEvents: [],
  eventYearIndex: 0,
  eventMonthIndex: 0,
  selectedEvent: {},
  openSelectedEvent: false,
  selectedEventYear: '',
  memories: [],
  pendingRelation: {},
  graveInvitationToken: {},
  relations: [],
  relationDirections: [],
};

export const deceasedGraveReducer = (state = graveState, action) => {
  switch (action.type) {
    case setRelationType:
      return {
        ...state,
        grave: {
          ...state.grave,
          isSetRelation: true,
        },
      };
    case setRelationList:
      return {
        ...state,
        relations: action.payload,
      };
    case setDirectionsList:
      return {
        ...state,
        relationDirections: action.payload,
      };
    case graveInvitationToken:
      return {
        ...state,
        graveInvitationToken: action.payload,
      };
    case pendingRelation:
      return {
        ...state,
        pendingRelation: action.payload,
      };
    case lifeEvents:
      return {
        ...state,
        lifeEvents: action.payload,
      };
    case eventYearIndex:
      return {
        ...state,
        eventYearIndex: action.payload,
      };
    case eventMonthIndex:
      return {
        ...state,
        eventMonthIndex: action.payload,
      };
    case openSelectedEvent:
      return {
        ...state,
        openSelectedEvent: action.payload,
      };
    case selectedEvent:
      return {
        ...state,
        selectedEvent: action.payload,
      };
    case selectedEventYear:
      return {
        ...state,
        selectedEventYear: action.payload,
      };
    case uploadingMemory:
      return {
        ...state,
        uploading: action.payload,
      };
    case disablePublishBtn:
      return {
        ...state,
        disable: action.payload,
      };
    case chooseGraveFromSearch:
      return {
        ...state,
        grave: action.grave,
      };
    case addCommentToGrave:
      return {
        ...state,
        comments: action.comment,
      };
    case setLifeEventsPhoto:
      return {
        ...state,
        lifeEventPhotos: action.payload,
      };
    case setLifeEventsVideo:
      return {
        ...state,
        lifeEventVideos: action.payload,
      };
    case setCommentOnToggle:
      return {
        ...state,
        commentOnToggle: action.comment,
      };
    case addMemoryToGrave:
      return {
        ...state,
        memories: action.payload,
      };
    case editMemoryOnGrave:
      return {
        ...state,
        memories: state.memories.map((mem) =>
          mem.id === action.payload.id ? action.payload : mem,
        ),
      };
    case removeMemoryFromGrave:
      return {
        ...state,
        memories: state.memories.filter((mem) => mem.id !== action.payload),
      };
    case addCommentToMemoryOnGrave:
      return {
        ...state,
        memories: state.memories.map((m) =>
          m.id === action.payload.memoryId ? { ...m, comments: action.payload.data } : m,
        ),
      };
    case editCommentToMemoryOnGrave:
      return {
        ...state,
        memories: state.memories.map((m) =>
          m.id === action.payload.memoryId
            ? {
                ...m,
                comments: m.comments.map((com) =>
                  com.id === action.payload.data.id ? { ...action.payload.data } : com,
                ),
              }
            : m,
        ),
      };
    case removeCommentFromMemoryOnGrave:
      return {
        ...state,
        memories: state.memories.map((m) =>
          m.id === action.payload.memoryId
            ? {
                ...m,
                comments: m.comments.filter((com) => com.id !== action.payload.commentId),
              }
            : m,
        ),
      };
    case addReplyToCommentOnGrave:
      return {
        ...state,
        memories: state.memories.map((mem) =>
          mem.id === action.payload.memoryId
            ? {
                ...mem,
                comments: mem.comments.map((com) =>
                  com.id === action.payload.commentId
                    ? {
                        ...com,
                        replies: com.replies?.length
                          ? [...com.replies, action.payload.data]
                          : [action.payload.data],
                      }
                    : com,
                ),
              }
            : mem,
        ),
      };
    case editReplyToCommentOnGrave:
      return {
        ...state,
        memories: state.memories.map((mem) =>
          mem.id === action.payload.memoryId
            ? {
                ...mem,
                comments: mem.comments.map((com) =>
                  com.id === action.payload.commentId
                    ? {
                        ...com,
                        replies: com.replies.map((rep) =>
                          rep.id === action.payload.data.id ? action.payload.data : rep,
                        ),
                      }
                    : com,
                ),
              }
            : mem,
        ),
      };
    case removeReplyFromCommentOnGrave:
      return {
        ...state,
        memories: state.grave.memories.map((mem) =>
          mem.id === action.payload.memoryId
            ? {
                ...mem,
                comments: mem.comments.map((com) =>
                  com.id === action.payload.commentId
                    ? {
                        ...com,
                        replies: com.replies.filter((rep) => rep.id !== action.payload.replyId),
                      }
                    : com,
                ),
              }
            : mem,
        ),
      };
    case setUpdateGrave:
      return {
        ...state,
        grave: { ...state.grave, ...action.grave },
      };
    case setLinkedGraves:
      return {
        ...state,
        grave: { ...state.grave, linked_graves: action.payload },
      };
    case setGraveWorkplaces:
      return {
        ...state,
        grave: { ...state.grave, grave_workplaces: action.payload },
      };
    case setGraveInterests:
      return {
        ...state,
        grave: { ...state.grave, grave_interests: action.payload },
      };
    case setGravePassion:
      return {
        ...state,
        grave: {
          ...state.grave,
          passion: action.payload,
        },
      };
    case graveImages:
      return {
        ...state,
        grave: {
          ...state.grave,
          grave_images: action.payload,
        },
      };
    case addGraveAdminType:
      return {
        ...state,
        grave: {
          ...state.grave,
          grave_users: state.grave.grave_users.map((user) =>
            user.id === action.payload.id ? { ...user, admin: action.payload.admin } : user,
          ),
        },
      };
    case addVirtueToGrave:
      return {
        ...state,
        grave: { ...state.grave, virtues: action.payload },
      };
    case editVirtueOnGrave:
      return {
        ...state,
        grave: {
          ...state.grave,
          virtues: state.grave.virtues?.map((v) =>
            v.id === action.payload.id ? action.payload : v,
          ),
        },
      };
    case removeVirtueFromGrave:
      return {
        ...state,
        grave: {
          ...state.grave,
          virtues: state.grave.virtues.filter((v) => v.id !== action.payload),
        },
      };
    case setSectionEdit:
      return {
        ...state,
        grave: { ...state.grave, sectionEdit: action.payload },
      };
    case setGraveUsers:
      return {
        ...state,
        grave: { ...state.grave, grave_users: action.payload },
      };
    case setGraveInvitations:
      return {
        ...state,
        graveInvitations: action.payload,
      };
    case setCurrentUserInRelationQueue:
      return {
        ...state,
        grave: action.payload,
      };
    case setCurrentUserInClaimQueue:
      return {
        ...state,
        grave: {
          ...state.grave,
          current_user_in_claim_queue: true,
          can_claim: false,
          can_set_relations: false,
        },
      };
    case SET_GRAVE_CURRENT_USER_RELATION:
      return {
        ...state,
        grave: {
          ...state.grave,
          current_user_relation: action.payload,
          grave_users: [action.payload, ...state.grave.grave_users],
        },
      };
    case availableSearchCount:
      return {
        ...state,
        availableSearchCount: action.count,
      };
    default:
      return state;
  }
};
