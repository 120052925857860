import { CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './LinkPreview.module.css';

const fetchLinkPreview = async (url) => {
  try {
    const apiKey = 'pk_0cc79d88caa97e4418002f4f7a97a628f689349f';
    const response = await fetch(`https://jsonlink.io/api/extract?url=${url}&api_key=${apiKey}`);
    const data = await response.json();

    return {
      title: data.title,
      description: data.description,
      image: data.images?.[0] || null,
      url: data.url,
      siteName: data.sitename,
    };
  } catch (error) {
    console.error('Error fetching link preview:', error);
    return null;
  }
};

const LinkPreview = ({ previewLink, setPreviewLink = null }) => {
  const [linkData, setLinkData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (previewLink) {
      fetchLinkPreview(previewLink).then((data) => {
        setLinkData(data);
        setLoading(false);
      });
    }
  }, [previewLink]);

  if (loading) {
    return (
      <div className={styles.preview} style={{ padding: 12 }}>
        <Flex gap='middle' vertical>
          <Skeleton.Node active={true} style={{ width: '100%', height: 200 }} block={true} />
          <Skeleton.Input active={true} size='small' block={true} />
          <Skeleton.Input active={true} size='large' block={true} />
        </Flex>
      </div>
    );
  }

  return (
    linkData && (
      <>
        <div className={styles.preview} onClick={() => window.open(linkData.url, '_blank')}>
          {linkData.image && (
            <img src={linkData.image} alt='Preview' className={styles.previewImage} />
          )}
          <div className={styles.previewText}>
            <div className={styles.siteName}>{linkData.siteName}</div>
            <div className={styles.title}>{linkData.title}</div>
          </div>
          {setPreviewLink && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setPreviewLink(null);
                setLinkData(null);
              }}
              shape='circle'
              size='small'
              icon={<CloseOutlined />}
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
              }}
            />
          )}
        </div>
      </>
    )
  );
};

export default LinkPreview;
