import React, { useState } from 'react';
import InterestCard from './InterestCard';
import styles from './Interests.module.css';
import InterestForm from './InterestsForm';
import PopoverMenu from './PopoverMenu';

const InterestsList = ({
  interest,
  currentGraveUser,
  loggedUser,
  onRemoveInterest,
  onSubmit,
  captivatedInterests,
}) => {
  const [openEditForm, setOpenEditForm] = useState(false);
  return openEditForm ? (
    <InterestForm
      interest={interest}
      onCancel={() => setOpenEditForm(false)}
      onSubmit={onSubmit}
      captivatedInterests={captivatedInterests}
    />
  ) : (
    <div className={styles.interestList}>
      <InterestCard interest={interest} />
      {currentGraveUser.user.id === interest.user_id && loggedUser.is_subscribed && (
        <PopoverMenu
          interest={interest}
          onEditInterest={() => setOpenEditForm(true)}
          onRemoveInterest={onRemoveInterest}
        />
      )}
    </div>
  );
};

export default InterestsList;
