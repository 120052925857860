import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MemoryCard from '../MemoryCard/MemoryCard';
import CommentFooter from './CommentFooter';

const CommentsModal = ({
  memory,
  grave,
  user,
  comments,
  createComment,
  editComment,
  deleteComment,
  setMemory,
}) => {
  const navigate = useNavigate();
  const [viewportHeight, setViewportHeight] = React.useState(window.innerHeight);

  const onClose = () => {
    navigate(`/deceased/${grave.slug}`);
  };

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <Modal
      title={user.user.full_name}
      open={true}
      onCancel={onClose}
      width={750}
      maskClosable={false}
      style={{
        top: window.innerWidth <= 768 ? 0 : '10%',
        margin: window.innerWidth <= 768 ? 0 : 'auto',
        maxWidth: window.innerWidth <= 768 ? '100%' : 750,
      }}
      styles={{
        header: {
          color: '#333',
          fontSize: '18px',
          fontWeight: '600',
          textAlign: 'center',
          padding: '12px 16px',
          borderBottom: '1px solid #e1e1e1',
        },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          ...(window.innerWidth <= 768
            ? { height: `${viewportHeight}px` }
            : { maxHeight: 'calc(100vh - 200px)' }),
          borderRadius: window.innerWidth <= 768 ? 0 : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: 'auto',
          flex: 1,
        },
      }}
      footer={<CommentFooter user={user} memory={memory} createComment={createComment} />}
    >
      <MemoryCard
        memory={memory}
        user={user}
        grave={grave}
        isCommentModal={true}
        comments={comments}
        createComment={createComment}
        editComment={editComment}
        deleteComment={deleteComment}
        setMemory={setMemory}
      />
    </Modal>
  );
};

export default CommentsModal;
