import { SendOutlined } from '@ant-design/icons';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Button } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentEditable from '../../../../components/RichTextEditor/ContentEditable';
import styles from './CommentsModal.module.css';

const CommentFooter = ({ user, createComment, memory }) => {
  const [comment, setComment] = useState('');

  return (
    <div className={styles.footerComment}>
      <div>
        <Avatar src={user?.user.avatar} className={styles.avatar} icon={<PersonIcon />} />
      </div>
      <div className={styles.inputContainer}>
        <ContentEditable
          placeholder={<FormattedMessage id='comment_placeholder' />}
          value={comment}
          onInput={setComment}
          editable={true}
          style={{
            minHeight: 70,
            width: 'calc(100% - 16px)',
          }}
        />
        <Button
          className={styles.sendIcon}
          disabled={isEmpty(comment)}
          type='primary'
          shape='circle'
          icon={<SendOutlined />}
          onClick={() => {
            createComment(comment, memory.id);
            setComment('');
          }}
        />
      </div>
    </div>
  );
};

export default CommentFooter;
