import { UserOutlined } from '@ant-design/icons';
import { Avatar, Tag } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styles from './Relationships.module.css';

const GraveUser = ({ user }) => {
  const navigate = useNavigate();
  const goto = () => {
    navigate(`/member/${user.user?.slug}`);
  };
  return (
    <div className={styles.userCard}>
      {/* Avatar */}
      <Avatar
        src={user?.user?.user_avatar}
        size={50}
        style={{ border: '1px solid  #d8d8d8', cursor: 'pointer' }}
        icon={<UserOutlined />}
        onClick={goto}
      />

      {/* User Info */}
      <div className={styles.userInfo}>
        <div className={styles.userHeader}>
          <div className={styles.userName} onClick={goto}>
            {user?.user?.full_name}
          </div>
          {user?.grave_owner && (
            <Tag color='#108ee9' className={styles.adminTag}>
              <FormattedMessage id='grave_owner' />
            </Tag>
          )}
        </div>
        <div className={styles.userRelation}>
          {user?.relation && (
            <span className={styles.relation}>
              {user.relation?.key ? (
                <FormattedMessage id={user.relation?.key} />
              ) : (
                user.relation?.name
              )}
            </span>
          )}
          {user.admin && (
            <span className={styles.relation}>
              (<FormattedMessage id='admin' />)
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default GraveUser;
