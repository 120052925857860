import { Avatar, Button, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import BoyAvatar from '../../../../img/BoyAvatar.svg';
import GirlsAvatar from '../../../../img/GirlsAvatar.svg';
import defaultBackground from '../../../../img/defaultGraveCoverPhoto.jpg';
import styles from './ConnectGraveModal.module.css';
const { Text } = Typography;

const Grave = ({ grave, setChosenGrave }) => {
  return (
    <div className={styles.graveContainer}>
      <div className={styles.graveWrapper}>
        <div className={styles.graveAvatar}>
          <Avatar
            src={grave?.avatar}
            shape='square'
            style={{ borderRadius: 0, height: '100%', width: '100%' }}
            icon={
              <img
                src={
                  grave?.gender === 'mann'
                    ? BoyAvatar
                    : grave.gender === 'kvinne'
                    ? GirlsAvatar
                    : defaultBackground
                }
              />
            }
          />
        </div>
        <div className={styles.graveName}>{grave?.name}</div>
        <div className={styles.graveDates}>
          {grave?.date_of_birth && (
            <Text type='secondary'>{grave?.date_of_birth.substring(0, 4)}</Text>
          )}{' '}
          -{' '}
          {grave?.date_of_death && (
            <Text type='secondary'>{grave?.date_of_death.substring(0, 4)}</Text>
          )}
        </div>
        <div className={styles.graveRelation}>{grave.current_user_relation?.relation}</div>
      </div>
      <Button
        type='text'
        style={{
          fontWeight: 'bold',
          background: '#f2f4f7',
          color: '#404d56',
          fontSize: 14,
        }}
        onClick={() => setChosenGrave(grave)}
      >
        <FormattedMessage id='link_to_grave' />
      </Button>
    </div>
  );
};

export default Grave;
