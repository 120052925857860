import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styles from './AboutGraveTab.module.css';
import Buried from './components/Buried/Buried';
import GraveLink from './components/GraveLink/GraveLink';
import GravePhotos from './components/GravePhotos/GravePhotos';
import Interests from './components/Interests/Interests';
import Passion from './components/Passion/Passion';
import Work from './components/Work/Work';

const menuItems = [
  { key: 'about', label: <FormattedMessage id='buried' />, path: 'about' },
  { key: 'about_work', label: <FormattedMessage id='work' />, path: 'about_work' },
  { key: 'about_interests', label: <FormattedMessage id='interests' />, path: 'about_interests' },
  { key: 'about_passion', label: <FormattedMessage id='passion' />, path: 'about_passion' },
  { key: 'about_photos', label: <FormattedMessage id='photos' />, path: 'about_photos' },
  {
    key: 'about_grave-link',
    label: <FormattedMessage id='grave_link' />,
    path: 'about_grave-link',
  },
];

const AboutGraveTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('tab') || 'about');

  useEffect(() => {
    const currentTab = searchParams.get('tab') || 'about';
    setTab(currentTab);
  }, [searchParams]);

  const handleTabChange = (tabName) => {
    setSearchParams({ tab: tabName });
  };

  return (
    <div className={styles.container}>
      {/* Left Menu */}
      <div className={styles.sidebar}>
        {menuItems.map((item) => (
          <Button
            key={item.key}
            type='text'
            className={`${styles.menuItem} ${tab === item.path ? styles.active : ''}`}
            onClick={() => handleTabChange(item.path)}
            size='large'
          >
            {item.label}
          </Button>
        ))}
      </div>

      <div className={styles.border} />

      {/* Right Content */}
      <div className={styles.content}>
        {tab === 'about' && <Buried />}
        {tab === 'about_work' && <Work />}
        {tab === 'about_interests' && <Interests />}
        {tab === 'about_passion' && <Passion />}
        {tab === 'about_photos' && <GravePhotos />}
        {tab === 'about_grave-link' && <GraveLink />}
      </div>
    </div>
  );
};

export default AboutGraveTab;
