import axios from 'axios';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/Loader/Loader';
import { setProductInfo } from '../../../../redux/actions';
import { CONSTANTS, ProductKeys } from '../../../../service/constants';
import Plan from './Plan';
import styles from './Plans.module.css';

const Plans = ({
  currentSubscription,
  openAddPaymentModal,
  paymentMethod,
  setOpenOptionsModal,
  fetchSubscriptionData,
}) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [discountsList, setDiscountsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState({});
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const [subscriptionPeriods, setSubscriptionPeriods] = useState([]);

  const fetchCurrentSubscription = async (id) => {
    try {
      const response = await fetch(`${CONSTANTS.baseUrl}/api/subscriptions/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        setLoading(false);
      }
      const data = await response.json();
      setSubscription(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentSubscription.id) {
      fetchCurrentSubscription(currentSubscription.id);
    }
  }, [currentSubscription.id]);

  const fetchPlans = () => {
    setLoading(true);
    axios
      .get(`${CONSTANTS.baseUrl}/api/products`)
      .then((res) => {
        dispatch(setProductInfo(res.data));
        setProducts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchSubscriptionDiscount = () => {
    axios
      .get(`${CONSTANTS.baseUrl}/api/subscription_discounts`)
      .then((res) => {
        setDiscountsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchSubscriptionPeriods = () => {
    setLoading(true);
    axios
      .get(`${CONSTANTS.baseUrl}/api/subscription_periods`)
      .then((res) => {
        setSubscriptionPeriods(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPlans();
    fetchSubscriptionPeriods();
    fetchSubscriptionDiscount();
  }, []);

  const updateSubscriptionMember = (field, value) => {
    if (field === 'invitation') {
      setSubscription({
        ...subscription,
        subscription_invitations: value,
      });
    } else if (field === 'members') {
      setSubscription({
        ...subscription,
        subscription_members: value,
      });
    }
  };

  if (
    loading ||
    products.length === 0 ||
    subscriptionPeriods.length === 0 ||
    (isAuth && isEmpty(subscription))
  ) {
    return (
      <div className={styles['loading-container']}>
        <Loader />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={styles['card']}>
        {products.length > 0 &&
          products.map((plan) => (
            <Plan
              key={plan.id}
              plan={plan}
              currentSubscription={subscription}
              updateSubscriptionMember={updateSubscriptionMember}
              subscriptionPeriods={subscriptionPeriods}
              paymentMethod={paymentMethod}
              setOpenOptionsModal={setOpenOptionsModal}
              fetchSubscriptionData={fetchSubscriptionData}
              discountsList={discountsList}
            />
          ))}
      </div>
      {!isEmpty(subscription) && subscription.plan_key !== ProductKeys.free && (
        <div className={styles['payment-method-change']}>
          <FormattedMessage id='plans_card_payment_method' />{' '}
          <span className={styles['payment-change']} onClick={openAddPaymentModal}>
            <FormattedMessage id='change' />
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export default Plans;
