import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const InterestForm = ({ interest, captivatedInterests, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    if (interest) {
      form.setFieldsValue({
        name: interest.name,
        captivated_interest_id: interest.captivated_interest?.id,
        description: interest.description,
      });
    }
  }, [interest, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (interest) {
        onSubmit(values, interest.id);
        onCancel();
      } else {
        onSubmit(values);
        onCancel();
      }
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  return (
    <Form form={form} layout='vertical' onFinish={handleSubmit}>
      <Row gutter={[16, 0]}>
        {/* Interest Name */}
        <Col xs={24} md={12}>
          <Form.Item
            name='name'
            label={<FormattedMessage id='interest_name' defaultMessage='Interest Name' />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'interest_name_required',
                  defaultMessage: 'Interest name is required',
                }),
              },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'interest_name_placeholder',
                defaultMessage: 'Enter interest name',
              })}
            />
          </Form.Item>
        </Col>

        {/* Category (Select with Search) */}
        <Col xs={24} md={12}>
          <Form.Item
            name='captivated_interest_id'
            label={<FormattedMessage id='interest_category' defaultMessage='Category' />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'interest_category_required',
                  defaultMessage: 'Category is required',
                }),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={intl.formatMessage({
                id: 'interest_category_placeholder',
                defaultMessage: 'Select a category',
              })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {captivatedInterests.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.interest_type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* Description */}
      <Form.Item
        name='description'
        label={<FormattedMessage id='interest_description' defaultMessage='Description' />}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'interest_description_required',
              defaultMessage: 'Description is required',
            }),
          },
        ]}
      >
        <Input.TextArea
          rows={3}
          placeholder={intl.formatMessage({
            id: 'interest_description_placeholder',
            defaultMessage: 'Enter description',
          })}
        />
      </Form.Item>

      {/* Buttons */}
      <Col xs={24}>
        <Form.Item>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            <FormattedMessage id='settings_cancel' />
          </Button>
          <Button type='primary' onClick={handleSubmit} style={{ backgroundColor: '#404d56' }}>
            <FormattedMessage id='auth_save' />
          </Button>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default InterestForm;
