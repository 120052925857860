import React from 'react';
import styles from './Anniversary.module.css';
import AnniversaryTitle from './AnniversaryTitle';
import CalenderIcon from './CalenderIcon';
import Timer from './Timer';

const Anniversary = ({
  anniversary,
  setSelectedAnniversary,
  setOpenForm,
  deleteAnniversary,
  isPublic,
}) => {
  return (
    <div className={styles['container']}>
      <CalenderIcon />
      <div className={styles['sub-container']}>
        <AnniversaryTitle
          anniversary={anniversary}
          isPublic={isPublic}
          setOpenForm={setOpenForm}
          setSelectedAnniversary={setSelectedAnniversary}
          deleteAnniversary={deleteAnniversary}
        />
        {!isPublic && (
          <div className={styles['timer-btn-container']}>
            <Timer anniversary={anniversary} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Anniversary;
