import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CreateMemorySection.module.css';

const InputButton = ({ setOpenModal, graveName }) => {
  return (
    <button className={styles.inputButton} onClick={() => setOpenModal(true)}>
      <FormattedMessage id='input_memory_placeholder' values={{ graveName }} />
    </button>
  );
};

export default InputButton;
