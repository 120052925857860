import { CameraFilled } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import React, { useRef, useState } from 'react';
import { FaUserLarge } from 'react-icons/fa6';
import { GrUserFemale } from 'react-icons/gr';
import { TfiUser } from 'react-icons/tfi';
import EditAvatarModal from './EditAvatarModal';
import styles from './GraveAvatar.module.css';

const GraveAvatar = ({ avatar, user, grave, isAcceptedUser }) => {
  const [image, setImage] = useState();
  const [openCropper, setOpenCropper] = useState();
  const inputFile = useRef();

  const handleProfilePhotoSelection = () => {
    if (user.is_subscribed) {
      inputFile.current.click();
    }
  };

  const selectTheImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      e.target.value = '';

      reader.readAsDataURL(file);
      reader.addEventListener('load', async () => {
        if (file.size / (1024 * 1024) <= 20) {
          setImage({ file, url: reader.result });
          setOpenCropper(true);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className={styles['grave-avatar-container']}>
        <Avatar
          loading='lazy'
          src={avatar}
          size={180}
          onClick={() => {
            if (isAcceptedUser()) {
              handleProfilePhotoSelection();
            }
          }}
          icon={
            grave?.gender === 'mann' ? (
              <TfiUser style={{ color: '#404d56' }} />
            ) : grave?.gender === 'kvinne' ? (
              <GrUserFemale style={{ color: '#404d56' }} />
            ) : (
              <FaUserLarge style={{ color: '#404d56' }} />
            )
          }
          style={{ border: '2px solid #404d56', background: 'rgb(242, 244, 247)' }}
        />
        {isAcceptedUser() && (
          <Button
            icon={<CameraFilled style={{ fontSize: 22, color: '#fff' }} />}
            onClick={() => handleProfilePhotoSelection()}
            type='text'
            shape='circle'
            size='large'
            style={{
              background: '#404d56',
              position: 'absolute',
              right: 5,
              bottom: 10,
            }}
          >
            <input
              style={{ display: 'none' }}
              ref={inputFile}
              type='file'
              accept='image/jpeg, image/jpg, image/png'
              className='test'
              onChange={selectTheImage}
            />
          </Button>
        )}
      </div>
      {openCropper && (
        <EditAvatarModal
          imageItem={image}
          onClose={() => setOpenCropper(false)}
          visible={openCropper}
          grave={grave}
        />
      )}
    </React.Fragment>
  );
};

export default GraveAvatar;
