import {
  CaretDownOutlined,
  GlobalOutlined,
  LoadingOutlined,
  LockOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Modal, Select, Spin, Upload } from 'antd';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import useNavigationWarning from '../../../../hooks/NavigationAlert';
import { addMemory } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import styles from './CreatePostModal.module.css';
import MediaUploader from './MediaUploader';
import MyEditor from './MyEditor';

const ConfirmDiscardModal = ({ visible, onDiscard, onCancel }) => (
  <Modal
    title={<FormattedMessage id='discard_changes' />}
    open={visible}
    onCancel={onCancel}
    onOk={onDiscard}
    okType='danger'
    okText={<FormattedMessage id='discard' />}
    cancelText={<FormattedMessage id='settings_cancel' />}
  >
    <FormattedMessage id='memory_discard_message' />
  </Modal>
);

const processMemory = async (memory) => {
  if (!memory) return null;

  return {
    text: memory.contents?.find((c) => c.category === null)?.description || '',
    files:
      memory.contents
        ?.filter((c) => c.category !== null)
        .map((c) => ({
          id: c.id,
          uid: c.id,
          url: c.file_url,
          type: c.category,
          caption: c.title,
          name: `Memory name ${c.id}`,
        })) || [],
    privacy: memory.privacy || 'relatives',
  };
};

const PostModal = ({ visible, onClose, user, grave, memory = null, setMemory }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const graveUsers = grave.grave_users || [];
  const linkedGraves = grave.linked_graves || [];

  const [privacy, setPrivacy] = useState('relatives');
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [previewLink, setPreviewLink] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [confirmDiscardOpen, setConfirmDiscardOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [removedFiles, setRemovedFiles] = useState(new Set());
  const [mentionList, setMentionList] = useState([]);

  const graveUsersList = graveUsers.map(({ user, relation }) => ({
    id: user?.id,
    name: user?.full_name,
    link: `/member/${user?.slug}`,
    relation: relation?.name || '',
    avatar: user?.user_avatar || '',
  }));

  const linkedGravesList = linkedGraves.map((grave) => ({
    id: grave?.id,
    name: grave?.name,
    link: `/deceased/${grave?.slug}`,
    relation: grave?.relation || '',
    avatar: grave?.avatar || '',
  }));

  const mentions = [...graveUsersList, ...linkedGravesList];

  useNavigationWarning(isLoading);

  const onChange = ({ fileList: newFileList }) => {
    const updatedList = [...fileList, ...newFileList]
      .filter((file) => !removedFiles.has(file.uid))
      .filter((file, index, self) => self.findIndex((f) => f.uid === file.uid) === index)
      .map((file) => ({
        ...file,
        caption: file?.caption || '',
      }));

    updatedList.forEach((file) => {
      if (!file.url && file.originFileObj && !file.originFileObj.preview) {
        file.originFileObj.preview = URL.createObjectURL(file.originFileObj);
      }
    });

    setFileList(updatedList);
  };

  const setMemoryData = async (memory) => {
    const data = await processMemory(memory);

    if (data) {
      setIsEditing(true);
      setText(data.text);
      setFileList(data.files);
      setPrivacy(data.privacy);
    }
  };

  const handleCancel = () => {
    if (text.trim() || fileList.length > 0) {
      setConfirmDiscardOpen(true);
    } else {
      onClose();
      setConfirmDiscardOpen(false);
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      if (visible) {
        event.preventDefault();
        handleCancel();
      }
    };

    if (visible) {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [visible, handleCancel]);

  useEffect(() => {
    setMemoryData(memory);
  }, [memory]);

  const goToLifePage = () => navigate(`/member/${user.user.slug}`);

  const handleDiscard = () => {
    setText('');
    setFileList([]);
    setConfirmDiscardOpen(false);
    onClose();
  };

  const handlePost = async () => {
    if (isEmpty(text) && fileList.length === 0) {
      showUserNotification(intl.formatMessage({ id: 'write_something_or_add_media' }), 'warning');
      return;
    }

    setIsLoading(true);

    try {
      let memoryId = memory?.id;
      let response;
      const mentioned_ids = [...new Set(mentionList.map((mention) => mention.id))];

      if (isEditing) {
        // **Updating an existing post**
        const memoryData = {
          memory: {
            memory_contents_attributes: [
              {
                description: text,
                id: Number(memory.contents[0].id),
              },
            ],
            privacy_attributes: {
              setting: privacy,
            },
            mentioned_ids: mentioned_ids || [],
          },
        };

        response = await request(`/memories/${memoryId}`, memoryData, 'put');
      } else {
        // **Creating a new post**
        const payload = {
          memory: {
            grave_id: grave.id,
            memory_contents_attributes: [
              { description: text, title: previewLink ? previewLink : '' },
            ],
            privacy_attributes: { setting: privacy },
            mentioned_ids: mentioned_ids || [],
          },
        };
        response = await request('/memories', payload, 'post');
        memoryId = response.data.memory_id;
      }

      // **Upload media if any**
      if (fileList.length > 0) {
        await uploadFiles(memoryId);
      } else {
        await fetchLatestMemories();
      }

      handleDiscard();
    } catch (error) {
      showUserNotification(intl.formatMessage({ id: 'post_share_success' }), 'error');
      console.error(error);
      setIsLoading(false);
    }
  };

  const uploadFiles = async (memoryId) => {
    const headers = { 'Content-Type': 'multipart/form-data' };

    try {
      const uploadPromises = fileList.map(async (file) => {
        try {
          const formData = new FormData();

          if (file.id) {
            formData.append('memory[memory_contents_attributes][][id]', file.id);
          }

          const category =
            file.type === 'image' || file.type?.startsWith('image/')
              ? 'image'
              : file.type === 'video' || file.type.startsWith('video/')
              ? 'video'
              : '';

          formData.append('memory[memory_contents_attributes][][category]', category);

          if (file.originFileObj) {
            formData.append('memory[memory_contents_attributes][][file]', file.originFileObj);
          }

          if (file.caption && !isEmpty(file.caption)) {
            formData.append('memory[memory_contents_attributes][][title]', file.caption);
          }

          await request(`/memories/${memoryId}`, formData, 'put', headers);
        } catch (error) {
          console.error('Error uploading file:', error);
          showUserNotification(intl.formatMessage({ id: 'file_upload_failed' }), 'error');
          throw error;
        }
      });

      await Promise.all(uploadPromises);
      await fetchLatestMemories();
    } catch (error) {
      console.error('Error uploading media:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLatestMemories = async () => {
    const newData = await request(`/memories?memory[grave_id]=${grave.id}`, null, 'get');
    if (newData.data.memories.length > 0) {
      if (memory && setMemory) setMemory(newData.data.memories.find((m) => m.id === memory.id));
      dispatch(addMemory(newData.data.memories));
    }
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loading}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color: '#fff' }} spin />} />
            <div className={styles.title}>
              {isEditing ? <FormattedMessage id='updating' /> : <FormattedMessage id='posting' />}
            </div>
          </div>
        </div>
      )}
      <Modal
        title={
          memory ? (
            <FormattedMessage id='edit_post' />
          ) : (
            <FormattedMessage id='create_post' values={{ graveName: grave.name }} />
          )
        }
        open={visible}
        onCancel={handleCancel}
        width={window.innerWidth <= 768 ? '100%' : 800}
        style={{
          top: window.innerWidth <= 768 ? 0 : '10%',
          margin: window.innerWidth <= 768 ? 0 : 'auto',
          maxWidth: window.innerWidth <= 768 ? '100%' : 'calc(100% - 40px)',
        }}
        styles={{
          header: {
            color: '#333',
            fontSize: '18px',
            fontWeight: '600',
            padding: '12px 16px',
            borderBottom: '1px solid #e1e1e1',
          },
          wrapper: {
            overflow: 'hidden',
          },
          content: {
            padding: 0,
            height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'calc(100vh - 200px)',
            borderRadius: window.innerWidth <= 768 ? 0 : '10px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          },
          body: {
            overflowY: 'auto',
            flex: 1,
          },
        }}
        footer={
          <div className={styles.footer}>
            <Button onClick={handleCancel}>
              <FormattedMessage id='settings_cancel' />
            </Button>
            <Button type='primary' onClick={handlePost} style={{ backgroundColor: '#404d56' }}>
              {memory ? <FormattedMessage id='update_memory' /> : <FormattedMessage id='post' />}
            </Button>
          </div>
        }
      >
        <div className={styles.createPostHeader}>
          <div className={styles.headerWrapper}>
            <Avatar
              src={user.user.avatar}
              size={55}
              onClick={goToLifePage}
              icon={<UserOutlined />}
            />
            <div className={styles.privacySection}>
              <div className={styles.name} onClick={goToLifePage}>
                {user.user.full_name}
              </div>
              <div>
                <Select
                  value={privacy}
                  onChange={setPrivacy}
                  size='small'
                  variant='filled'
                  style={{ width: 120 }}
                  open={open}
                  onDropdownVisibleChange={setOpen}
                  suffixIcon={
                    <CaretDownOutlined
                      rotate={open ? 180 : 0}
                      style={{ color: '#000', cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen((prev) => !prev);
                      }}
                    />
                  }
                >
                  <Select.Option value='public'>
                    <GlobalOutlined style={{ marginRight: 4 }} /> <FormattedMessage id='public' />
                  </Select.Option>
                  <Select.Option value='private'>
                    <LockOutlined style={{ marginRight: 4 }} /> <FormattedMessage id='private' />
                  </Select.Option>
                  <Select.Option value='relatives'>
                    <UsergroupAddOutlined style={{ marginRight: 4 }} />{' '}
                    <FormattedMessage id='relatives' />
                  </Select.Option>
                </Select>
              </div>
            </div>
          </div>
          <Upload
            accept='image/*, video/*'
            multiple
            beforeUpload={() => false}
            onChange={onChange}
            showUploadList={false}
          >
            <Button
              type='primary'
              icon={<PlusOutlined />}
              style={{ background: 'rgb(64, 77, 86)' }}
            >
              <FormattedMessage id='add_to_your_post' />
            </Button>
          </Upload>
        </div>

        <div className={styles.content} style={{ position: 'relative' }}>
          <MyEditor
            setText={setText}
            user={user}
            setMentionList={setMentionList}
            mentionList={mentionList}
            mentions={mentions}
            value={
              text || (memory && memory.contents?.find((c) => c.category === null)?.description)
            }
          />
        </div>

        <MediaUploader
          fileList={fileList}
          setFileList={setFileList}
          setRemovedFiles={setRemovedFiles}
          memoryId={memory?.id}
        />

        <ConfirmDiscardModal
          visible={confirmDiscardOpen}
          onDiscard={handleDiscard}
          onCancel={() => setConfirmDiscardOpen(false)}
        />
      </Modal>
    </>
  );
};

export default PostModal;
