import React, { useState } from 'react';
import InvitationForm from './InvitationForm';
import InvitedUsers from './InvitedUsers';
import PopoverMenu from './PopoverMenu';
import styles from './Relationships.module.css';

const InvitedUsersList = ({
  user,
  relations,
  relationDirections,
  loggedUser,
  currentGraveUser,
  onSubmitInvitation,
  removeInvitation,
  grave,
}) => {
  const [openEditForm, setOpenEditForm] = useState(false);

  return (
    <div className={styles.card}>
      {openEditForm ? (
        <InvitationForm
          userData={user}
          relations={relations}
          relationDirections={relationDirections}
          onSubmit={onSubmitInvitation}
          onCancel={() => setOpenEditForm(false)}
          grave={grave}
        />
      ) : (
        <>
          <InvitedUsers user={user} />
          {(currentGraveUser?.admin || currentGraveUser?.grave_owner) &&
            loggedUser.is_subscribed && (
              <PopoverMenu
                user={user}
                onEditRelation={() => setOpenEditForm(true)}
                onRemoveUser={removeInvitation}
              />
            )}
        </>
      )}
    </div>
  );
};

export default InvitedUsersList;
