import { Avatar, Typography } from 'antd';
import React from 'react';
import { CgWorkAlt } from 'react-icons/cg';
import styles from './Work.module.css';

const { Title, Text } = Typography;

const WorkplaceCard = ({ workplace }) => {
  if (!workplace) return null;

  return (
    <div className={styles.workplaceHeader}>
      <Avatar
        size={50}
        icon={<CgWorkAlt style={{ fontSize: 30, color: '#404d56' }} />}
        style={{ backgroundColor: '#f2f4f7' }}
      />
      <div className={styles.workplaceInfo}>
        <Title level={5} style={{ margin: 0 }}>
          {workplace?.position} at {workplace?.company_name}
        </Title>
        <Text style={{ display: 'block' }} type='secondary'>
          {workplace.city}
        </Text>
        <Text type='secondary'>
          {workplace.start_year} - {workplace.end_year}
        </Text>
        <Text style={{ display: 'block' }} type='secondary'>
          {workplace.description}
        </Text>
      </div>
    </div>
  );
};

export default WorkplaceCard;
