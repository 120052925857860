import { DeleteOutlined, EditOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Radio, Space, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSwipeable } from 'react-swipeable';
import styles from './CreateEventModal.module.css';

const ImageCarousel = ({
  fileList,
  currentIndex,
  onNext,
  onPrev,
  setFileList,
  setRemovedFiles,
  onEdit,
  setImageCategory,
  imageCategory,
}) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => onNext(),
    onSwipedRight: () => onPrev(),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const handleRemove = (file) => {
    if (file.originFileObj?.preview) {
      URL.revokeObjectURL(file.originFileObj.preview);
    }

    if (file?.url) {
      URL.revokeObjectURL(file.url);
    }

    setRemovedFiles((prev) => new Set([...prev, file.uid]));
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
  };

  const fileURL = fileList[currentIndex].url || fileList[currentIndex].originFileObj?.preview;
  const isImage = fileList[currentIndex].type && fileList[currentIndex].type.startsWith('image/');
  const isVideo = fileList[currentIndex].type && fileList[currentIndex].type.startsWith('video/');

  const handleEdit = (file) => {
    onEdit(file);
  };

  return (
    <div className={styles.modalContent} {...handlers}>
      {/* Left Navigation */}
      {fileList.length > 1 && (
        <Button
          className={styles.navIcon}
          shape='circle'
          icon={<LeftOutlined />}
          onClick={onPrev}
        />
      )}

      {/* Display Media */}
      <div className={styles.mediaWrapper}>
        {isImage && <img src={fileURL} alt='Full View' className={styles.fullImage} />}
        {isVideo && <video src={fileURL} controls muted className={styles.fullVideo} />}
        {!isImage && !isVideo && (
          <div className={styles.fullImage}>
            <FormattedMessage id='media_not_supported' />
          </div>
        )}
        <Space>
          {isImage && (
            <Tooltip title={<FormattedMessage id='settings_edit' />}>
              <Button
                shape='circle'
                size='small'
                icon={<EditOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(fileList[currentIndex]);
                }}
                style={{ position: 'absolute', top: '5px', right: '32px' }}
              />
            </Tooltip>
          )}

          <Tooltip title={<FormattedMessage id='settings_remove' />}>
            <Button
              shape='circle'
              size='small'
              icon={<DeleteOutlined style={{ color: 'red' }} />}
              onClick={(e) => {
                e.stopPropagation();
                handleRemove(fileList[currentIndex]);
              }}
              style={{ position: 'absolute', top: '5px', right: '5px' }}
            />
          </Tooltip>
        </Space>
      </div>

      {isImage && (
        <Button
          variant='filled'
          shape='round'
          className={styles.setCoverImage}
          onClick={() =>
            setImageCategory((prevCategory) =>
              prevCategory === fileList[currentIndex].uid ? null : fileList[currentIndex].uid,
            )
          }
        >
          <Radio checked={imageCategory && imageCategory === fileList[currentIndex].uid}>
            <FormattedMessage id='set_as_cover' />
          </Radio>
        </Button>
      )}

      {/* Right Navigation */}
      {fileList.length > 1 && (
        <Button
          onClick={onNext}
          className={styles.navIcon}
          icon={<RightOutlined />}
          shape='circle'
        />
      )}
    </div>
  );
};

export default ImageCarousel;
