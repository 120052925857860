import { ArrowLeftOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
} from 'antd';
import 'cropperjs/dist/cropper.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import useNavigationWarning from '../../../../../hooks/NavigationAlert';
import { setLifeEvents } from '../../../../../redux/actions';
import { request } from '../../../../../service/request';
import styles from './CreateEventModal.module.css';
import ImageCarousel from './ImageCarousel';

dayjs.extend(utc);

const { TextArea } = Input;

const ConfirmDiscardModal = ({ visible, onDiscard, onCancel }) => (
  <Modal
    title={<FormattedMessage id='discard_changes' />}
    open={visible}
    onCancel={onCancel}
    onOk={onDiscard}
    okType='danger'
    okText={<FormattedMessage id='discard' />}
    cancelText={<FormattedMessage id='settings_cancel' />}
  >
    <FormattedMessage id='memory_discard_message' />
  </Modal>
);

const CreateEventModal = ({ visible, onClose, user, grave, event = null }) => {
  const genPresets = () => {
    return [
      {
        label: 'Theme Colors',
        key: 'theme_color',
        colors: [
          '#D3E4CD', // Soft Green
          '#FADCD9', // Soft Pink
          '#F5F0BB', // Light Yellow
          '#C9E4DE', // Mint
          '#FBE4CF', // Light Orange
          '#E4C1F9', // Lavender
          '#A0E7E5', // Light Cyan
          '#FFDAC1', // Peach
          '#E2F0CB', // Light Lime
          '#B5EAD7', // Aqua Green
          '#C7CEEA', // Light Purple
          '#FFF1C1', // Light Cream
          '#FFD6E0', // Blush Pink
          '#D1CFE2', // Cool Lilac
          '#F9E2D2', // Light Salmon
          '#E2E2E2', // Light Grey
          '#F5F5F5', // White Smoke
        ],
      },
    ];
  };

  const presets = genPresets();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();

  const countries = useSelector((state) => state.countries.countries);
  const [fileList, setFileList] = useState([]);
  const [confirmDiscardOpen, setConfirmDiscardOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [removedFiles, setRemovedFiles] = useState(new Set());
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageCategory, setImageCategory] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [cropperImage, setCropperImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useNavigationWarning(isLoading);

  useEffect(() => {
    if (event) {
      setIsEditing(true);
      form.setFieldsValue({
        event_title: event.event_title,
        event_date: event.event_date ? dayjs.utc(event.event_date).local() : null,
        location: Number(event.location),
        theme_color: event.theme_color,
        description: event.description,
      });
    }
  }, [event]);

  const handleCancel = () => {
    if (fileList.length > 0) {
      setConfirmDiscardOpen(true);
    } else {
      onClose();
      setConfirmDiscardOpen(false);
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    const updatedList = [...fileList, ...newFileList]
      .filter((file) => !removedFiles.has(file.uid))
      .filter((file, index, self) => self.findIndex((f) => f.uid === file.uid) === index)
      .map((file) => ({
        ...file,
      }));

    updatedList.forEach((file) => {
      if (!file.url && file.originFileObj && !file.originFileObj.preview) {
        file.originFileObj.preview = URL.createObjectURL(file.originFileObj);
      }
    });

    setFileList(updatedList);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;

      const isTyping =
        activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA' ||
        activeElement?.isContentEditable;

      if (!isTyping) {
        if (event.key === 'ArrowRight') nextMedia();
        if (event.key === 'ArrowLeft') prevMedia();
        if (event.key === 'Escape') {
          event.stopPropagation();
          event.preventDefault();
          handleCancel();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, fileList]);

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight(); // Set initial height

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      if (visible) {
        event.preventDefault();
        handleCancel(); // Close the modal instead of going back
      }
    };

    if (visible) {
      window.history.pushState(null, '', window.location.href); // Add a fake history entry
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [visible, handleCancel]);

  const goToLifePage = () => navigate(`/member/${user.slug}`);

  const handleDiscard = () => {
    setFileList([]);
    setConfirmDiscardOpen(false);
    onClose();
  };

  const prevMedia = () => {
    if (!fileList?.length) return;
    setCurrentIndex((prevIndex) => (prevIndex - 1 + fileList.length) % fileList.length);
  };

  const nextMedia = () => {
    if (!fileList?.length) return;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % fileList.length);
  };

  const onFInish = async () => {
    try {
      const values = await form.validateFields();
      handlePost(values);
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  const handlePost = async (values) => {
    setIsLoading(true);

    try {
      let eventId = event?.id;
      let response;

      if (isEditing) {
        const eventData = {
          life_event: {
            ...values,
            event_year: values.event_date?.year(),
          },
        };

        response = await request(`/life_events/${eventId}`, eventData, 'put');
      } else {
        // **Creating a new event**
        const payload = {
          life_event: {
            event_title: values.event_title,
            grave_id: grave.id,
            creator_id: user.id,
            location: values.location,
            event_year: values.event_date?.year(),
            event_date: values.event_date,
            description: values.description,
            theme_color: values.theme_color,
          },
        };
        response = await request('/life_events', payload, 'post');
        eventId = response.data.id;
      }

      // **Upload media if any**
      if (fileList.length > 0) {
        await uploadFiles(eventId);
      } else {
        await fetchLatestEvents();
      }

      handleDiscard();
    } catch (error) {
      showUserNotification(intl.formatMessage({ id: 'post_share_success' }), 'error');
      console.error(error);
      setIsLoading(false);
    }
  };

  const uploadFiles = async (eventId) => {
    const headers = { 'Content-Type': 'multipart/form-data' };

    try {
      // Filter only new files that have `originFileObj`
      const newFiles = fileList.filter((file) => file.originFileObj);

      if (newFiles.length === 0) {
        await fetchLatestEvents();
        return;
      }

      const uploadPromises = newFiles.map(async (file) => {
        try {
          const formData = new FormData();
          const category = file.type?.startsWith('image/')
            ? 'image'
            : file.type.startsWith('video/')
            ? 'video'
            : 'other';

          if (category === 'video') {
            formData.append('life_event_video[life_event_id]', eventId);
            formData.append('life_event_video[file]', file.originFileObj, `${file.uid}.mp4`);
            await request(`/life_event_videos`, formData, 'post', headers);
          } else if (category === 'image') {
            const isDisplayCategory = imageCategory
              ? file.uid === imageCategory
                ? 'display'
                : 'timeline'
              : 'timeline';
            formData.append('life_event_image[category]', isDisplayCategory);
            formData.append('life_event_image[life_event_id]', eventId);
            formData.append('life_event_image[file]', file.originFileObj, `${file.uid}.jpg`);
            await request(`/life_event_images`, formData, 'post', headers);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          showUserNotification(intl.formatMessage({ id: 'file_upload_failed' }), 'error');
          throw error; // Ensure rejection propagates properly
        }
      });

      await Promise.all(uploadPromises);
      await fetchLatestEvents();
    } catch (error) {
      console.error('Error uploading media:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLatestEvents = async () => {
    const newData = await request(`/life_events?grave_id=${grave.id}`, null, 'get');
    if (newData.data.length > 0) {
      dispatch(setLifeEvents(newData.data));
    }
  };

  const onEdit = (image) => {
    setCropperImage(image.originFileObj?.preview);
    setIsCropping(true);
  };

  const onCrop = async () => {
    if (!cropperImage || !croppedAreaPixels) {
      console.error('No image or crop area selected');
      return;
    }

    try {
      const croppedBlob = await getCroppedImage();
      if (!croppedBlob) {
        console.error('Cropping error: Blob is null');
        return;
      }

      // Convert Blob to File
      const croppedFile = new File([croppedBlob], `cropped-image-${Date.now()}.jpg`, {
        type: 'image/jpeg',
      });

      croppedFile.uid = fileList[currentIndex].uid;
      croppedFile.originFileObj = croppedFile;
      croppedFile.preview = URL.createObjectURL(croppedFile);

      setFileList((prev) =>
        prev.map((file, i) =>
          i === currentIndex
            ? { ...file, originFileObj: croppedFile, url: croppedFile.preview }
            : file,
        ),
      );

      setIsCropping(false);
      setCropperImage(null);
    } catch (error) {
      console.error('Error during cropping:', error);
    }
  };

  const getCroppedImage = async () => {
    if (!cropperImage || !croppedAreaPixels) return null;

    try {
      const images = await cropImage(cropperImage, croppedAreaPixels);

      return images;
    } catch (error) {
      console.error('Cropping error:', error);
      return null;
    }
  };

  // 🔧 Fixes for Cropping Function
  const cropImage = async (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.src = imageSrc;
      image.crossOrigin = 'anonymous';

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );

        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error('Canvas to Blob conversion failed'));
          },
          'image/jpeg',
          0.95, // High quality
        );
      };

      image.onerror = (err) => reject(err);
    });
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loading}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color: '#fff' }} spin />} />
            <div className={styles.title}>
              {isEditing ? <FormattedMessage id='updating' /> : <FormattedMessage id='posting' />}
            </div>
          </div>
        </div>
      )}
      <Modal
        title={
          isCropping ? (
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                setIsCropping(false);
              }}
              type='text'
              shape='circle'
              style={{
                fontWeight: 'bold',
                background: '#f2f4f7',
                color: '#404d56',
              }}
            />
          ) : event ? (
            <FormattedMessage id='edit_event' />
          ) : (
            <FormattedMessage id='create_event' />
          )
        }
        open={visible}
        onCancel={handleCancel}
        maskClosable={false}
        keyboard={false}
        width={window.innerWidth <= 768 ? '100%' : 800}
        style={{
          top: window.innerWidth <= 768 ? 0 : '10%',
          margin: window.innerWidth <= 768 ? 0 : 'auto',
          maxWidth: window.innerWidth <= 768 ? '100%' : 'calc(100% - 40px)',
        }}
        styles={{
          header: {
            color: '#333',
            fontSize: '18px',
            fontWeight: '600',
            padding: '12px 16px',
            borderBottom: '1px solid #e1e1e1',
          },
          wrapper: {
            overflow: 'hidden',
          },
          content: {
            padding: 0,
            height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'calc(100vh - 200px)',
            borderRadius: window.innerWidth <= 768 ? 0 : '10px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          },
          body: {
            overflowY: 'auto',
            flex: 1,
          },
        }}
        footer={
          isCropping ? (
            <div className={styles.footer}>
              <Button onClick={() => setIsCropping(false)}>
                <FormattedMessage id='settings_cancel' />
              </Button>
              <Button type='primary' onClick={onCrop} style={{ backgroundColor: '#404d56' }}>
                <FormattedMessage id='auth_save' />
              </Button>
            </div>
          ) : (
            <div className={styles.footer}>
              <Button onClick={handleCancel}>
                <FormattedMessage id='settings_cancel' />
              </Button>
              <Button type='primary' onClick={onFInish} style={{ backgroundColor: '#404d56' }}>
                {event ? <FormattedMessage id='update_memory' /> : <FormattedMessage id='create' />}
              </Button>
            </div>
          )
        }
      >
        {!isCropping ? (
          <>
            <div className={styles.createPostHeader}>
              <div className={styles.headerWrapper}>
                <Avatar src={user.avatar} size={55} onClick={goToLifePage} />
                <div className={styles.name} onClick={goToLifePage}>
                  {user.full_name}
                </div>
              </div>
              <Upload
                accept='image/*, video/*'
                multiple
                beforeUpload={() => false}
                onChange={onChange}
                showUploadList={false}
              >
                <Button
                  type='primary'
                  icon={<PlusOutlined />}
                  style={{ background: 'rgb(64, 77, 86)' }}
                >
                  <FormattedMessage id='add_to_your_post' />
                </Button>
              </Upload>
            </div>
            {fileList && fileList.length > 0 && (
              <ImageCarousel
                fileList={fileList}
                currentIndex={currentIndex}
                onPrev={prevMedia}
                onNext={nextMedia}
                onEdit={onEdit}
                setImageCategory={setImageCategory}
                imageCategory={imageCategory}
                setFileList={setFileList}
                setRemovedFiles={setRemovedFiles}
              />
            )}

            <Form
              style={{ padding: '16px 16px 0px 16px' }}
              form={form}
              layout='vertical'
              size='large'
            >
              <Row gutter={[16, 0]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name='event_title'
                    label={<FormattedMessage id='event_label_name' />}
                    required
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'event_required_name' }),
                      },
                    ]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'event_placeholder_name' })} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    name='event_date'
                    label={<FormattedMessage id='event_label_date' />}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'event_required_date' }),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      format='YYYY-MM-DD'
                      placeholder={intl.formatMessage({ id: 'event_placeholder_date' })}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    name='location'
                    label={<FormattedMessage id='event_label_country' />}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'event_required_country' }),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp='label'
                      placeholder={intl.formatMessage({ id: 'event_placeholder_country' })}
                      options={countries.map((c) => ({
                        value: c.id,
                        label: c.key ? intl.formatMessage({ id: c.key }) : c.name,
                      }))}
                      onChange={(value) => {
                        form.setFieldsValue({
                          country_id: value,
                          municipality_id: null,
                          cemetery: null,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    name='theme_color'
                    label={<FormattedMessage id='event_label_theme' />}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'event_required_theme' }),
                      },
                    ]}
                  >
                    <ColorPicker
                      presets={presets}
                      defaultValue={form.getFieldValue('theme_color') || '#D3E4CD'}
                      value={form.getFieldValue('theme_color') || '#D3E4CD'}
                      onChange={(color) => {
                        form.setFieldValue('theme_color', color.toHexString());
                      }}
                      disabledAlpha
                      panelRender={() => (
                        <div className='ant-color-picker-presets'>
                          {presets.map((preset) => (
                            <div key={preset.key} style={{ maxWidth: 200 }}>
                              <div style={{ marginBottom: 8 }}>{preset.label}</div>
                              <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
                                {preset.colors.map((color) => (
                                  <div
                                    key={color}
                                    style={{
                                      width: 24,
                                      height: 24,
                                      borderRadius: 50,
                                      background: color,
                                      cursor: 'pointer',
                                      border: '1px solid #ddd',
                                    }}
                                    onClick={() => {
                                      form.setFieldValue('theme_color', color);
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name='description'
                    label={intl.formatMessage({ id: 'event_label_description' })}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'event_required_description' }),
                      },
                    ]}
                  >
                    <TextArea
                      rows={3}
                      placeholder={intl.formatMessage({ id: 'event_placeholder_description' })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <div className={styles.cropperWrapper}>
            <Cropper
              image={cropperImage}
              crop={crop}
              aspect={12 / 9}
              onCropChange={setCrop}
              zoomWithScroll={false}
              objectFit='contain'
              showGrid={false}
              onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
              style={{
                containerStyle: { width: '100%' },
                mediaStyle: { width: '100%', objectFit: 'contain' },
              }}
            />
          </div>
        )}

        <ConfirmDiscardModal
          visible={confirmDiscardOpen}
          onDiscard={handleDiscard}
          onCancel={() => setConfirmDiscardOpen(false)}
        />
      </Modal>
    </>
  );
};

export default CreateEventModal;
