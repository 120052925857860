import { Button, DatePicker, Form, Input, Modal, Select, TimePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification';
import { goToChosenGrave } from '../../../../../../redux/actions';
import { fetch_request, request } from '../../../../../../service/request';
import styles from './Buried.module.css';

const privacyOptions = [
  { value: 'private', label: <FormattedMessage id='private' /> },
  { value: 'public', label: <FormattedMessage id='public' /> },
  { value: 'relatives', label: <FormattedMessage id='relatives' /> },
];

const gendersList = [
  { value: 'mann', label: <FormattedMessage id='mann' /> },
  { value: 'kvinne', label: <FormattedMessage id='kvinne' /> },
  { value: 'annen', label: <FormattedMessage id='annen' /> },
];

const EditGraveModal = ({ visible, onClose, grave }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countries.countries);
  const [municipalities, setMunicipalities] = useState([]);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [cemeteries, setCemeteries] = useState([]);
  const [reasonDeaths, setReasonDeaths] = useState([]);

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight(); // Set initial height

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  useEffect(() => {
    fetch_request('/api/reason_of_deaths', {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setReasonDeaths(data.reasons);
        form.setFieldsValue({
          cause_of_death: grave.reason_of_death
            ? data.reasons.find(
                (reason) => reason.reason?.toString() === grave.reason_of_death?.toString(),
              )?.id || null
            : null,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (form.getFieldValue('country_id')) {
      fetch_request(
        `/api/municipalities?country_id=${form.getFieldValue('country_id')}`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          setMunicipalities(data.municipalities);
          setCemeteries([]); // Clear cemeteries when country changes
        })
        .catch((err) => console.log(err));
    }
  }, [form.getFieldValue('country_id')]);

  useEffect(() => {
    if (form.getFieldValue('municipality_id')) {
      fetch_request(
        `/api/graveyards?graveyard[municipality_id][]=${form.getFieldValue('municipality_id')}`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          setCemeteries(data.graveyards);
        })
        .catch((err) => console.log(err));
    }
  }, [form.getFieldValue('municipality_id')]);

  const submitData = (values) => {
    const additionalNames = [
      { name_type: 'Birthname', name: values.birth_name },
      { name_type: 'Nickname', name: values.nickname },
    ];

    const payload = {
      grave: {
        name: values.name,
        gender: values.gender,
        time_of_birth: values.birth_time ? values.birth_time.format() : null,
        time_of_death: values.death_time ? values.death_time.format() : null,
        date_of_birth: values.birthday ? values.birthday.format('YYYY-MM-DD') : null,
        location_of_birth: values.birthplace,
        date_of_death: values.death_date ? values.death_date.format('YYYY-MM-DD') : null,
        location_of_death: values.death_location,
        reason_of_death_id: values.cause_of_death,
        grave_additional_names_attributes: additionalNames.map(({ name_type, name }) => ({
          name_type,
          name,
        })),
        country_id: values.country_id,
        municipality_id: values.municipality_id,
        graveyard_id: values.cemetery,
      },
    };

    if (values.privacy !== null) {
      payload.grave.privacy_attributes = { setting: values.privacy };
    }

    request(`/graves/${grave.slug}`, payload, 'put')
      .then((res) => {
        dispatch(goToChosenGrave(res.data));
      })
      .catch((err) => {
        showUserNotification(err.response?.data?.error || err.message, 'warning');
      });
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        submitData(values);
        onClose();
      })
      .catch((info) => console.log('Validation Failed:', info));
  };

  return (
    <Modal
      title={<FormattedMessage id='edit_grave_details' />}
      open={visible}
      onCancel={onClose}
      width={window.innerWidth <= 768 ? '100%' : 800}
      style={{
        top: window.innerWidth <= 768 ? 0 : '10%',
        margin: window.innerWidth <= 768 ? 0 : 'auto',
        maxWidth: window.innerWidth <= 768 ? '100%' : 'calc(100% - 40px)',
      }}
      styles={{
        header: {
          color: '#333',
          fontSize: '18px',
          fontWeight: '600',
          padding: '12px 16px',
          borderBottom: '1px solid #e1e1e1',
        },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'calc(100vh - 200px)',
          borderRadius: window.innerWidth <= 768 ? 0 : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: 'auto',
          flex: 1,
        },
      }}
      footer={
        <div className={styles.footer}>
          <Button key='cancel' onClick={onClose}>
            <FormattedMessage id='settings_cancel' />
          </Button>
          <Button
            key='save'
            type='primary'
            onClick={handleSave}
            style={{ backgroundColor: '#404d56' }}
          >
            <FormattedMessage id='auth_save' />
          </Button>
        </div>
      }
    >
      <Form
        style={{ padding: '0px 16px' }}
        form={form}
        layout='vertical'
        size='large'
        initialValues={{
          gender: grave.gender,
          name: grave.name,
          birthplace: grave.location_of_birth,
          nickname: grave.grave_additional_names.find((n) => n.name_type === 'Nickname')?.name,
          birth_name: grave.grave_additional_names.find((n) => n.name_type === 'Birthname')?.name,
          death_location: grave.location_of_death,
          cemetery: grave.graveyard?.id,
          country_id: grave.municipality?.country_id,
          municipality_id: grave.municipality?.id,
          privacy: grave.privacy,
          birthday: grave.date_of_birth ? dayjs(grave.date_of_birth) : null,
          birth_time: grave.time_of_birth ? dayjs(grave.time_of_birth) : null,
          death_date: grave.date_of_death ? dayjs(grave.date_of_death) : null,
          death_time: grave.time_of_death ? dayjs(grave.time_of_death) : null,
        }}
      >
        <div className={styles.inputItems}>
          <Form.Item
            name='name'
            label={<FormattedMessage id='settings_name' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Input variant='filled' placeholder={intl.formatMessage({ id: 'placeholder_name' })} />
          </Form.Item>

          <Form.Item
            name='nickname'
            label={<FormattedMessage id='about_nickname' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Input
              variant='filled'
              placeholder={intl.formatMessage({ id: 'placeholder_nickname' })}
            />
          </Form.Item>

          <Form.Item
            name='birth_name'
            label={<FormattedMessage id='about_birth_name' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Input
              variant='filled'
              placeholder={intl.formatMessage({ id: 'placeholder_birth_name' })}
            />
          </Form.Item>
        </div>

        <div className={styles.inputItems}>
          <Form.Item
            name='birthplace'
            label={<FormattedMessage id='about_birthplace' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Input
              variant='filled'
              placeholder={intl.formatMessage({ id: 'placeholder_birthplace' })}
            />
          </Form.Item>

          <Form.Item
            name='death_location'
            label={<FormattedMessage id='about_death_location' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Input
              variant='filled'
              placeholder={intl.formatMessage({ id: 'placeholder_death_location' })}
            />
          </Form.Item>

          <Form.Item
            name='gender'
            label={<FormattedMessage id='about_gender' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Select
              variant='filled'
              showSearch
              optionFilterProp='label'
              placeholder={intl.formatMessage({ id: 'placeholder_gender' })}
              options={gendersList.map((c) => ({ value: c.value, label: c.label }))}
            />
          </Form.Item>
        </div>

        <div className={styles.inputItems}>
          <Form.Item
            name='country_id'
            label={<FormattedMessage id='about_country' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Select
              variant='filled'
              showSearch
              optionFilterProp='label'
              placeholder={intl.formatMessage({ id: 'placeholder_country' })}
              options={countries.map((c) => ({
                value: c.id,
                label: c.key ? intl.formatMessage({ id: c.key }) : c.name,
              }))}
              onChange={(value) => {
                form.setFieldsValue({ country_id: value, municipality_id: null, cemetery: null });
                setMunicipalities([]);
                setCemeteries([]);
              }}
            />
          </Form.Item>

          <Form.Item
            name='municipality_id'
            label={<FormattedMessage id='municipality' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Select
              variant='filled'
              showSearch
              optionFilterProp='label'
              placeholder={intl.formatMessage({ id: 'placeholder_municipality' })}
              options={municipalities.map((m) => ({ value: m.id, label: m.name }))}
              onChange={(value) => {
                form.setFieldsValue({ municipality_id: value, cemetery: null });
                setCemeteries([]);
              }}
              disabled={!form.getFieldValue('country_id')}
            />
          </Form.Item>

          <Form.Item
            name='cemetery'
            label={<FormattedMessage id='cemetery' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Select
              variant='filled'
              showSearch
              optionFilterProp='label'
              placeholder={intl.formatMessage({ id: 'placeholder_cemetery' })}
              options={cemeteries.map((c) => ({ value: c.id, label: c.name }))}
              disabled={!form.getFieldValue('municipality_id')}
            />
          </Form.Item>
        </div>

        <div className={styles.inputItems}>
          <Form.Item
            name='cause_of_death'
            label={<FormattedMessage id='cause_of_death' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Select
              variant='filled'
              showSearch
              optionFilterProp='label'
              placeholder={intl.formatMessage({ id: 'placeholder_cause_of_death' })}
              options={reasonDeaths.map((r) => ({ value: r.id, label: r.reason }))}
              onChange={(value) => form.setFieldValue('cause_of_death', value)}
            />
          </Form.Item>

          <Form.Item
            name='privacy'
            label={<FormattedMessage id='about_privacy' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <Select
              variant='filled'
              showSearch
              optionFilterProp='label'
              placeholder={intl.formatMessage({ id: 'placeholder_privacy' })}
              options={privacyOptions.map((c) => ({ value: c.value, label: c.label }))}
            />
          </Form.Item>
        </div>

        <div className={styles.inputItems}>
          {/* Date of Birth */}
          <Form.Item
            name='birthday'
            label={<FormattedMessage id='date_of_birth' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <DatePicker
              format='YYYY-MM-DD'
              variant='filled'
              placeholder={intl.formatMessage({ id: 'placeholder_date_of_birth' })}
            />
          </Form.Item>

          {/* Time of Birth */}
          <Form.Item
            name='birth_time'
            label={<FormattedMessage id='time_of_birth' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <TimePicker
              format='HH:mm'
              variant='filled'
              placeholder={intl.formatMessage({ id: 'placeholder_time_of_birth' })}
            />
          </Form.Item>
        </div>

        <div className={styles.inputItems}>
          {/* Date of Death */}
          <Form.Item
            name='death_date'
            label={<FormattedMessage id='date_of_death' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <DatePicker
              format='YYYY-MM-DD'
              variant='filled'
              placeholder={intl.formatMessage({ id: 'placeholder_date_of_death' })}
            />
          </Form.Item>

          {/* Time of Death */}
          <Form.Item
            name='death_time'
            label={<FormattedMessage id='time_of_death' />}
            style={{ flex: 1, fontWeight: 'bold' }}
          >
            <TimePicker
              format='HH:mm'
              variant='filled'
              placeholder={intl.formatMessage({ id: 'placeholder_time_of_death' })}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default EditGraveModal;
