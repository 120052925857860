import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Image, Result, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setGraveImages } from '../../../../../../redux/actions';
import { request } from '../../../../../../service/request';
import EditImageModal from './EditImageModal';
import styles from './GravePhotos.module.css';
import PopoverMenu from './PopoverMenu';
import UploadPhotosModal from './UploadPhotoModal';

const GravePhotos = () => {
  const dispatch = useDispatch();
  const [_searchParams, setSearchParams] = useSearchParams();
  const grave = useSelector((state) => state.grave.grave);
  const [openModal, setOpenModal] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [imageItem, setImageItem] = useState({});
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (grave?.grave_images) {
      setImages(grave?.grave_images);
    }
  }, [grave]);

  const handleUpload = ({ fileList }) => {
    const uniqueFiles = fileList.map((file) => ({
      file,
      preview: URL.createObjectURL(file.originFileObj),
      description: '',
      privacy: 'relatives',
      address: '',
    }));

    setFilesList(uniqueFiles);
    setOpenModal(true);
  };

  const handleDelete = async (image) => {
    await request(`/grave_images/${image.id}`, null, 'delete');
    const newImages = images.filter((item) => item.id !== image.id);
    setImages(newImages);
    dispatch(setGraveImages([...newImages]));
  };
  const handleEdit = (image) => {
    setImageItem(image);
    setOpenEditModal(true);
  };

  return (
    <div>
      <div className={styles.gravePhotosHeader}>
        <h3>
          <FormattedMessage id='photos' />
        </h3>
        <Upload
          accept='image/*'
          beforeUpload={() => false}
          multiple
          fileList={[]}
          onChange={handleUpload}
          showUploadList={false}
          style={{ width: '100%' }}
        >
          <Button
            type='text'
            style={{
              fontWeight: 'bold',
              background: '#f2f4f7',
              color: '#404d56',
              fontSize: 14,
            }}
            icon={<UploadOutlined />}
          >
            <FormattedMessage id='upload_photo' />
          </Button>
        </Upload>
      </div>
      <div style={{ marginTop: 12 }}>
        {images?.length > 0 ? (
          <Row gutter={[4, 4]} justify='start'>
            {images.map((item, index) => (
              <Col key={index} xs={12} sm={8}>
                <div className={styles.imageContainer}>
                  <Image
                    src={item.file_url}
                    width='100%'
                    height='100%'
                    style={{
                      objectFit: 'cover',
                      borderRadius: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => setSearchParams({ tab: 'photos', id: item.id })}
                    preview={false}
                  />
                  {(item.can_edit || item.can_destroy) && (
                    <PopoverMenu
                      image={item}
                      onDelete={() => handleDelete(item)}
                      onEdit={() => handleEdit(item)}
                    />
                  )}
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <Result
            status='404'
            title={<FormattedMessage id='no_photos_added' />}
            subTitle={<FormattedMessage id='add_photos_message' />}
          />
        )}
      </div>
      {openModal && (
        <UploadPhotosModal
          openModal={openModal}
          filesList={filesList}
          setOpenModal={setOpenModal}
          setFilesList={setFilesList}
          grave={grave}
        />
      )}
      {openEditModal && (
        <EditImageModal
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          images={images}
          setImages={setImages}
          imageItem={imageItem}
        />
      )}
    </div>
  );
};

export default GravePhotos;
