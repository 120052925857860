import { PlusOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SearchInput from '../../../../../../components/SearchInput';
import { setGraveWorkplacesRedux } from '../../../../../../redux/actions';
import { fetch_request, request } from '../../../../../../service/request';
import styles from './Work.module.css';
import WorkForm from './WorkForm';
import WorkPlaceList from './WorkPlaceList';

const Work = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const grave = useSelector((state) => state.grave.grave);
  const loggedUser = useSelector((state) => state.user.user);
  const currentGraveUser = grave?.current_user_relation;
  const [workplaces, setWorkplaces] = useState([]);
  const [openWorkplaceForm, setOpenWorkplaceForm] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpenWorkplaceForm = () => {
    setOpenWorkplaceForm(true);
  };

  useEffect(() => {
    setWorkplaces(grave.grave_workplaces);
  }, [grave.grave_workplaces]);

  useEffect(() => {
    if (workplaces) {
      setLoading(false);
    }
  }, [workplaces]);

  const filterWorkplace = (searchItem) => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request(`/api/grave_workplaces?grave_id=${grave.id}&search=${searchItem}`, requestOptions)
      .then((response) => response.json())
      .then((data) => setWorkplaces(data.grave_workplaces))
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitWorkplace = async (values, id) => {
    try {
      const workPlaceData = {
        company_name: values.company_name,
        city: values.city,
        position: values.position,
        start_year: values.start_year ? dayjs(values.start_year).format('YYYY') : null,
        end_year: values.end_year ? dayjs(values.end_year).format('YYYY') : null,
        description: values.description,
      };

      if (!id) {
        workPlaceData.grave_id = grave.id; // Only for new workplaces
      }

      const endpoint = id ? `/grave_workplaces/${id}` : `/grave_workplaces`;
      const method = id ? 'put' : 'post';

      const response = await request(endpoint, workPlaceData, method);

      const updatedWorkplaces = id
        ? workplaces.map((w) => (w.id === id ? { ...response.data } : w))
        : [{ ...response.data }, ...workplaces];
      dispatch(setGraveWorkplacesRedux(updatedWorkplaces));
      setWorkplaces(updatedWorkplaces);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteWorkplace = (id) => {
    if (id) {
      request(`/grave_workplaces/${id}`, null, 'delete')
        .then((res) => {
          const newArray = workplaces.filter((work) => work.id !== id);
          dispatch(setGraveWorkplacesRedux(newArray));
          setWorkplaces(newArray);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h3>
          <FormattedMessage id='work' />
        </h3>
        <SearchInput
          filterItems={filterWorkplace}
          placeholder={intl.formatMessage({ id: 'find_workplace' })}
        />
      </div>
      {loggedUser.is_subscribed && currentGraveUser && (
        <Button
          type='text'
          style={{
            marginTop: 12,
            display: openWorkplaceForm ? 'none' : 'flex',
            fontWeight: 'bold',
            background: '#f2f4f7',
            color: '#404d56',
            fontSize: 14,
          }}
          icon={<PlusOutlined />}
          onClick={handleOpenWorkplaceForm}
        >
          <FormattedMessage id='add_workplace' />
        </Button>
      )}
      {openWorkplaceForm && (
        <div className={styles.workForm}>
          <WorkForm onSubmit={onSubmitWorkplace} onCancel={() => setOpenWorkplaceForm(false)} />
        </div>
      )}

      <div style={{ marginTop: 12, height: loading ? 400 : 'auto' }}>
        {workplaces && workplaces.length > 0
          ? workplaces.map((workplace) => (
              <WorkPlaceList
                key={workplace.id}
                workplace={workplace}
                onSubmit={onSubmitWorkplace}
                loggedUser={loggedUser}
                currentGraveUser={currentGraveUser}
                onRemoveWorkplace={deleteWorkplace}
              />
            ))
          : !loading && (
              <Result
                status='404'
                title={<FormattedMessage id='no_workplaces_added' />}
                subTitle={<FormattedMessage id='add_workplaces_message' />}
              />
            )}
      </div>
    </div>
  );
};

export default Work;
