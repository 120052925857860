import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { timeSinceCreated } from '../../../../service/Utils';
import AllComments from '../CommentsModal/AllComments';
import CommentFooter from '../CommentsModal/CommentFooter';
import PostModal from '../CreatePostModal/PostModal';
import Header from '../MemoryCard/Header';
import ImageCarousel from './ImageCarousel';
import styles from './MemoryDetails.module.css';

const MemoryDetails = ({
  memory,
  grave,
  user,
  comments,
  createComment,
  editComment,
  deleteComment,
  selectedImageId,
  onClose,
  setMemory,
}) => {
  const graveUsers = grave.grave_users || [];
  const [, setSearchParams] = useSearchParams();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [postCreated, setPostCreated] = useState('');

  useEffect(() => {
    setPostCreated(timeSinceCreated(memory?.created_at));
  }, [memory]);

  const memoryContents = Array.isArray(memory?.contents) ? memory.contents : [];

  const memoryCreator = graveUsers.find((graveUser) => graveUser.user?.id === memory?.user_id);

  const descriptionContents = memoryContents.filter((content) => content.category === null);
  const descriptionContent =
    descriptionContents.length > 0 ? descriptionContents[0]?.description : '';

  const mediaContents = memoryContents.filter((content) => content.category !== null);

  useEffect(() => {
    if (selectedImageId && mediaContents.length > 0) {
      const selectedIndex = mediaContents.findIndex((c) => c.id === Number(selectedImageId));
      setCurrentIndex(selectedIndex >= 0 ? selectedIndex : 0);
    } else {
      onClose();
    }
  }, [selectedImageId, mediaContents, setSearchParams]);

  useEffect(() => {
    const updateHeight = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;

      const isTyping =
        activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA' ||
        activeElement?.isContentEditable;

      if (!isTyping) {
        if (event.key === 'ArrowRight') nextMedia();
        if (event.key === 'ArrowLeft') prevMedia();
        if (event.key === 'Escape') {
          event.stopPropagation();
          event.preventDefault();
          onClose();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, mediaContents, setSearchParams]);

  const updateSearchParams = (newIndex) => {
    if (mediaContents.length > 0 && mediaContents[newIndex]) {
      setSearchParams({ memory: memory.id, id: mediaContents[newIndex].id });
    }
  };

  const prevMedia = () => {
    if (mediaContents.length === 0) return;
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? mediaContents.length - 1 : prevIndex - 1;
      updateSearchParams(newIndex);
      return newIndex;
    });
  };

  const nextMedia = () => {
    if (mediaContents.length === 0) return;
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === mediaContents.length - 1 ? 0 : prevIndex + 1;
      updateSearchParams(newIndex);
      return newIndex;
    });
  };

  return (
    <Modal
      open={true}
      onCancel={onClose}
      width={'100%'}
      closeIcon={null}
      maskClosable={false}
      keyboard={false}
      style={{
        top: 0,
        margin: 0,
        maxWidth: '100%',
      }}
      styles={{
        header: { display: 'none' },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          height: `${viewportHeight}px`,
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: window.innerWidth > 768 ? 'hidden' : 'auto',
          flex: 1,
        },
      }}
      footer={null}
    >
      <>
        <Button
          type='primary'
          shape='circle'
          icon={<CloseOutlined style={{ fontSize: '18px' }} />}
          className={styles.closeIcon}
          onClick={onClose}
        />

        <div className={styles.modalContainer}>
          {mediaContents.length > 0 && (
            <ImageCarousel
              mediaContents={mediaContents}
              currentIndex={currentIndex}
              onPrev={prevMedia}
              onNext={nextMedia}
            />
          )}
          <div className={styles.border} />
          {mediaContents[currentIndex] && (
            <div className={styles.commentArea}>
              <div>
                <Header
                  user={memoryCreator}
                  postCreated={postCreated}
                  memory={memory}
                  setOpenEditModal={setOpenEditModal}
                  openEditModal={openEditModal}
                  grave={grave}
                />
                {/* Post Description */}
                {descriptionContent && (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(descriptionContent) }}
                  />
                )}
              </div>
              {memory.comments_count > 0 && (
                <div className={styles.totalCommentsContainer}>
                  <div className={styles.totalComments}>
                    {memory.comments_count} <FormattedMessage id='comments' />
                  </div>
                </div>
              )}
              {comments && comments.length > 0 && (
                <AllComments
                  comments={comments}
                  grave={grave}
                  memoryId={memory.id}
                  createComment={createComment}
                  editComment={editComment}
                  deleteComment={deleteComment}
                />
              )}
              <CommentFooter user={user} memory={memory} createComment={createComment} />
            </div>
          )}
        </div>
        {openEditModal && (
          <PostModal
            visible={openEditModal}
            onClose={() => setOpenEditModal(false)}
            user={user}
            grave={grave}
            memory={memory}
            setMemory={setMemory}
          />
        )}
      </>
    </Modal>
  );
};

export default MemoryDetails;
