const NOR = {
  auth_mobileNumber: 'Mobilnummer',
  auth_password: 'Passord',
  auth_passwordPlaceholder: 'Skriv inn passordet ditt',
  auth_rememberMe: 'Husk meg på denne enheten',
  auth_login: 'Gå inn',
  auth_login_here: 'Logg inn her',
  auth_get_newsletr: `Motta nyheter og oppdateringer fra Nettgrav`,
  auth_notRegistered: 'Har du ikke konto? ',
  auth_alreadyRegistered: 'Har du allerede en konto? ',
  auth_register: 'Opprett konto',
  auth_forgotPassword: 'Glemt passord?',
  auth_welcome: 'Logg inn eller opprett konto',
  auth_toNettgrav: 'til nettgrav.no',
  auth_enterPhoneNumber: 'Skriv inn mobilnummeret ditt',
  auth_man: 'Mann',
  auth_woman: 'Kvinne',
  auth_other: 'Annen',
  auth_acceptConditions: 'Jeg aksepterer å motta SMS fra Nettgrav, og jeg godtar',
  auth_termsAndCondition: 'vilkår og betingelser',
  auth_next: 'Neste',
  auth_receivedSMS: 'Du vil snart motta en melding',
  auth_otpCode: 'Skriv inn engangskoden',
  auth_received: 'Har du ikke mottatt engangskoden?',
  auth_newOtp: 'Du kan sende ny engangskode om 00:',
  auth_sendNew: 'Send ny',
  auth_confirm: 'Bekreft',
  auth_yourName: 'Ditt navn',
  auth_enterFullName: 'Skriv inn navnet ditt',
  auth_enterPasswordAgain: 'Skriv inn passordet på nytt',
  auth_passwordMessage: 'Passordet må bestå av minst 7 tegn og inneholde minst 3 av følgende:',
  auth_capitalLetter: '⚙︎ Store bokstaver',
  auth_smallLetters: '⚙︎ Små bokstaver',
  auth_digits: '⚙︎ Sifre',
  auth_characters: '⚙︎ Spesialtegn',
  auth_signupInvitation: 'Du er invitert til familieabonnementet fra',
  auth_resetPassword: 'Tilbakestill passord',
  auth_resetPasswordSMS: 'Engangskode sendes til mobilnummeret ditt',
  auth_back: 'Tilbake',
  auth_send: 'Send',
  auth_restOtp: 'Skriv inn engangskoden sendt til {code}{phoneNumber}',
  auth_code: 'Skriv inn koden',
  auth_confirmPassword: 'Bekreft nytt passord',
  auth_newPassword: 'Nytt passord',
  auth_notSame: 'Passordene er ikke like',
  auth_save: 'Lagre',
  settings_profile: 'Profil',
  settings_personalInformation: 'Navn og info',
  settings_payments: 'Betalinger',
  settings_emptyPayments: 'Ingen tilgjengelige betalinger',
  settings_paymentMethod: 'Betalingsmetode',
  settings_subscription: 'Medlemskap',
  settings_members: 'Familie og venner',
  settings_giftInvitations: 'Inviter med gave',
  settings_notifications: 'Meldinger',
  settings_account_information: 'Kontoinnstillinger',
  settings_contact: 'Kontakt Nettgrav',
  settings_password: 'Passord',
  settings_edit: 'Rediger',
  settings_fullName: 'Navn',
  setting_gender: 'Kjønn',
  settings_occupation: 'Yrke',
  settings_birthday: 'Fødselsdato',
  settings_email: 'E-post',
  settings_telephoneNumber: 'Telefonnummer',
  settings_address: 'Adresse',
  settings_language: 'Språk',
  settings_currency: 'Valuta',
  settings_timeZone: 'Tidssone',
  settings_invalidEmail: 'Ugyldig e-postadresse',
  settings_cancel: 'Avbryt',
  settings_currentPassword: 'Nåværende passord',
  confirm_password: 'Bekreft passord',
  settings_newPassword: 'Nytt passord',
  settings_retype: 'Skriv inn passordet på nytt',
  settings_billingMethod: 'Betalingsmetode',
  settings_nextDue: 'Neste betaling forfaller',
  settings_paymentMethods: 'Betalingsmetoder',
  settings_nextBilling: 'Vipps eller kort',
  settings_mangeMethods: 'Hvordan betaler jeg',
  settings_emptyMethods: 'Ingen betalingsmetoder lagt til',
  settings_vippsHeading: `Vipps`,
  settings_manageVipps: 'Se mine',
  settings_goToAgreements: 'Vipps-avtaler',
  settings_cancel_mySubscription: 'Ja, avslutt abonnementet mitt',
  settings_cardEnd: 'slutter på',
  settings_remove: 'Fjern',
  settings_stop: 'Stopp',
  settings_vippsAgreement: 'Vipps-avtaler',
  view_manage_agreements: 'Se og administrer Vipps-avtalene her',
  empty_agreements: 'Ingen Vipps-avtaler ble funnet',
  settings_amount: '{amount}',
  settings_subscriptionHeading: 'Oversikt over abonnementet',
  settings_subscriptionStatus: 'Under behandling',
  settings_freeSubscriptionHeading: 'Kom i gang med Fri',
  settings_month: 'måned',
  months: 'måneder',
  year: 'år',
  month: 'måned',
  years: 'år',
  saved: 'Lagret',
  saved_message: '{name} er lagret',
  settings_upgrade: 'Oppgrader plan',
  settings_subDescription: 'For barn, foreldre, barnebarn og andre nære',
  settings_for: 'for',
  settings_paymentInfo: 'Betalingsinformasjon',
  settings_cardNumber: 'Kortnummer',
  settings_billingInfo: 'Faktureringsinformasjon',
  settings_name: 'Navn',
  settings_billingEmail: 'E-post',
  settings_billingCountry: 'Land',
  settings_postCode: 'Postnummer',
  settings_changePlan: 'Endre plan',
  change_plan: 'Endre plan',
  change: 'Endre',
  change_members: 'Administrer medlemmer',
  settings_noSubscription:
    'Du har ikke et aktivt abonnement. Gå til plansiden for å velge en plan.',
  settings_clickHere: 'Klikk her',
  settings_memberHeading: 'Invitasjoner',
  settings_receivedMembership: '(Invitasjoner du har fått)',
  settings_emptyMembership: 'Du har ingen invitasjoner akkurat nå',
  settings_memberPage: 'Administrer medlemskapet ditt på medlemssiden',
  settings_subscribedPlan: 'Abonnementet ditt er aktivert.',
  settings_updatePlan: 'Abonnementet ditt er oppdatert.',
  settings_downgradePlan: 'Abonnementet ditt er nedgradert.',
  settings_addedMember: 'Nye medlemmer er lagt til i planen din.',
  payment_progress: 'Betaling pågår. Vennligst vent',
  something_wrong: 'Noe gikk galt. Prøv igjen senere.',
  add_validNumber: 'Oppgi et gyldig telefonnummer',
  select_planMessage: 'Velg en plan for å fortsette.',
  settings_notificationsSubheading: 'Bestem hva slags meldinger du vil få',
  settings_preferenceText: 'Motta varsler fra Nettgrav og partnere via:',
  settings_genealText: 'Motta nyheter og oppdateringer fra Nettgrav.no via:',
  settings_textMessage: 'Tekstmeldinger',
  settings_general: 'Generelle oppdateringer',
  settings_your: 'din.',
  settings_enable: 'Plan aktivert',
  free: 'Fri',
  conscious: 'Bevisst',
  consciousPlus: 'Bevisst +',
  settings_membershipOn: '-medlemskap på',
  settings_cancelSubscription: 'Avslutt abonnement',
  cancelSubscription_message: `Du er i ferd med å avslutte abonnementet ditt på Nettgrav - {name}-planen. Da mister du tilgang til alle funksjonene. Du kan fortsatt bruke Nettgrav, men bare med de gratis funksjonene.`,
  reactivateSubscription_message: `Start medlemskapet ditt på nytt med {name}. Da får du tilbake tilgang til alle funksjonene.`,
  not_now: 'Nei, ikke nå',
  reactivate: 'Ja, start abonnementet mitt igjen',
  settings_modalSubheading: 'Her er kjærligheten størst.',
  settings_modalLink: 'Takk for at du er her.',
  settings_memberSubheading: 'Din familieplan.',
  settings_emptyMembers: 'Ingen medlemmer i abonnementet ditt',
  settings_memberName: `Familien til {name}`,
  settings_memberLength: '{length} medlemmer',
  settings_administrator: 'Administrator',
  settings_showMembers: 'Vis medlemmer',
  settings_familyMembers: 'Familiemedlemmer',
  settings_you: 'Du',
  settings_connect: 'koble til',
  settings_setRelation: 'Angi relasjon',
  settings_selectedRelation: 'Velg relasjon',
  settings_giftHeading: `Gi medlemskap til noen du bryr deg om.`,
  settings_giftFreeSubscription: `Du har en gratis konto. Oppgrader for å få tilgang til alt og invitere flere.`,
  settings_seeSubscriptions: 'Oppgrader nå',
  settings_youHave: 'Du har {count} ',
  settings_addMore: 'for å legge til flere medlemskap i planen din.',
  settings_addMember: 'Legg til medlem',
  settings_invitedMember: 'Inviterte medlemmer',
  settings_emptyGiftMembers: 'Ingen ubesvarte invitasjoner',
  settings_inviteTo: 'Klikk her for å invitere til ditt familieabonnement',
  settings_pendingSubscription: 'Abonnementet ditt bekreftes snart.',
  delete: 'Slett',
  settings_shareLink: 'Kopier og del din Nettgrav.no-invitasjonslenke',
  grave_invitation_shareLink: 'Kopier og del din gravinvitasjonslink',
  settings_copyToClipboard: 'Lenke kopiert til utklippstavlen',
  discard_subheading: `Er du sikker på at du vil forkaste endringene? Denne handlingen kan ikke angres.`,
  no: 'Nei',
  yes: 'Ja',
  invitation: 'invitasjon',
  information: 'Informasjon',
  interests: 'Interesser',
  note: 'Notat',
  relative: 'Familie',
  uploadingImages: 'Laster opp bilder',
  lifeEvent: 'Livshendelse',
  workplace: 'Arbeidsplass',
  delete_modalHeading: 'Bekreft at du ønsker å fjerne',
  picture: 'Bilde',
  paymentMethod: 'Betalingsmetode',
  vippsAgreement: 'Vipps-avtale',
  settings_complete_subscription: 'Fullfør abonnement',
  pending_message: 'Få tilgang til alt vi har å tilby, og start med graven på nett.',
  pending_title: 'Fullfør betalingen for å begynne med Bevisst.',
  stopped_title: 'Betalingsavtalen din er stoppet',
  stopped_message:
    'Abonnementet ditt er ufullstendig fordi betalingsavtalen ble kansellert. Fullfør abonnementet ditt for å fortsette.',
  expired_title: 'Betalingsavtalen din har utløpt',
  expired_message: 'Abonnementet ditt er ufullstendig fordi betalingsavtalen er utløpt.',
  complete_setup: 'Fullfør oppsett',
  cancelled_title: 'Abonnementet ditt er kansellert',
  cancelled_message:
    'Abonnementet ditt er kansellert. Du kan fortsette å bruke Nettgrav.no som vanlig frem til medlemsdatoen utløper. Medlemskapet utløper {period_end_date}. Etter denne datoen vil abonnementet ditt automatisk endres til Fri.',
  activeStopped_title: 'Betalingsavtalen din er avsluttet',
  activeStopped_message: `Betalingsmåten knyttet til abonnementet ditt er stoppet. Vennligst forny betalingsmåten for å fortsette å nyte premiumtjenester. Hvis problemet vedvarer, vil abonnementet bli nedgradert til gratisplanen etter gjeldende faktureringssyklus.`,
  renew_Agreement: 'Forny betalingsavtale',
  add_card: 'Legg til betalingsmåte',
  Reactivate: 'Aktiver abonnementet på nytt',
  active_title: 'Abonnementet ditt vil snart bli aktivert',
  active_message: `Abonnementet ditt er under behandling og vil bli aktivert om noen minutter. Takk for tålmodigheten.`,
  activeExpired_title: 'Betalingsavtalen din har utløpt',
  activeExpired_message: `Betalingsmåten knyttet til abonnementet ditt har utløpt. Vennligst forny eller opprett en ny betalingsavtale for å fortsette.`,
  consciousness: 'Om oss',
  about_us: 'Om nettgrav',
  team: 'Vår historie',
  careers: 'Jobb hos oss',
  features: 'Om graven',
  available_sustainable: 'Alltid tilgjengelig',
  maintenance_free_grave: 'Lett å bruke',
  memory_album: 'Bilder og minner',
  value_archive: 'Verdier og historier',
  memorial_wall: 'Vis dine gravbesøk',
  life_archive: 'Fortellinger om livet',
  anniversary_calendar: 'Kalender for viktige dager',
  Family_values: 'Familietre (kommer)',
  resources: 'Hjelp og ressurser',
  find_grave: 'Finn en grav',
  blog: 'Blogg',
  help_center: 'Spørsmål og svar',
  contact: 'Kontakt',
  contact_us: 'Kontakt oss',
  business_inquiries: 'Samarbeid med oss',
  feedback: 'Si din mening',
  settings_inviteAgain: 'invitasjoner igjen.',
  notValid_title: 'Oppdater betalingsinformasjonen din',
  notValid_message: `Betalingsmåten din trenger oppdatering. Vennligst oppdater informasjonen for å fortsette å bruke tjenesten uten avbrudd.`,
  basic_information: 'Grunnleggende informasjon',
  interest: 'Interesser',
  notes: 'Notater',
  passion: 'Lidenskap',
  relation: 'Relasjon',
  life_event: 'Livshendelse',
  subscription_invitation: 'Abonnementsinvitasjon',
  find_grave_norway: 'Finn en grav i Norge',
  search_grave: 'Søk på navn',
  or: 'eller',
  create_netgrave: 'Opprett Nettgrav',
  visit_grave: 'Ta med de døde videre i livet',
  wish: 'ditt.',
  second_section_description: 'Finn gravlagte, stå sammen og skap evig trygghet for fremtiden.',
  memorial_plaque: 'Gravstøtten',
  memorial_plaque_description: 'Ta med gravlagte videre med Nettgrav gravstøtten.',
  archive: 'Verdiarkivet',
  archive_description: `Bevar verdier og livsvisdom for nåtiden og fremtidige generasjoner.`,
  memory_album_description:
    'Last opp bilder av graven og avdøde. Gjør Nettgraven personlig og privat.',
  find_municipality: 'Tilgjengelige kommuner',
  search_municipality: 'Søk etter kommune',
  it_is: 'Finn en grav i over',
  find_grave_count: '{count}',
  municipality_description: 'tilgjengelige kommuner.',
  no_results: 'Fant ingen resultater for',
  find_cemetery: 'Finn en grav på disse kirkegårdene.',
  search_cemetery: 'Søk etter kirkegård.',
  cemetery_description:
    'tilgjengelige kirkegårder. Søk etter en kirkegård for lettere å finne graven.',
  grave_description:
    'tilgjengelige graver på denne kirkegården. Søk etter navn for å finne og besøke en grav. Ved å legge til din relasjon kan du lagre graven i din profil og motta oppdateringer på merkedager',
  goto_grave: 'Vis grav',
  payment_due_message: 'Medlemskapet ditt er aktivt. Vipps trekker pengene nå.',
  payment_due_title: 'Abonnementet ditt er aktivt',
  find_grave_image_title: 'Nettgrav – ta vare på det mest betydningsfulle når du trenger det',
  find_grave_image_text:
    'Med Nettgrav kan du holde kontakten med graven, når som helst. På din Livsside samles alle gravene du har en tilknytning til. Enkelt, trygt og tilgjengelig – uten behov for fysisk vedlikehold.',
  find_grave_text: 'lagrer gravene du har relasjon til.',
  life_page: 'Livssiden',
  the_life_page: 'Oppdag Livssiden',
  first_description:
    'Din personlige side hvor alle gravene du har knyttet deg til vises på ett sted.',
  second_description: 'Alle graver og relasjoner vises på livssiden din.',
  update: 'Samle minner og relasjoner',
  third_description:
    'På Livssiden ser du alle dine relasjoner og gravene du besøker. Det mest betydningsfulle – samlet på ett sted',
  netgrave: 'Nettgrav',
  find_grave_last_text:
    'En vedlikeholdsfri grav som alltid er tilgjengelig. Samle familie og pårørende for å dele minner, historier og omsorg – uten å måtte tenke på fysisk vedlikehold. Med Nettgrav kan du ta bevisste valg som får varig virkning.',
  plans_page_heading: 'Bevisst',
  users: 'Medlemmer',
  for_members: 'For {user} medlemmer',
  for_member: 'For {user} medlem',
  for_6_members: 'For {user} eller flere',
  plans_page_subheading:
    'Bevisst kommer med ubegrenset antall Nettgraver og koster 27,- per måned.',
  per_user: 'per medlem',
  current_members: 'Nåværende medlemmer',
  total_members: 'Oppdaterte medlemmer',
  subscription_length: 'Abonnementets varighet',
  plan_price: 'Planpris',
  increment_text: 'Velg antall medlemskap du ønsker for deg og din familie',
  free_plan_description:
    'Vi knytter sammen alle gravene dine på ett sted og tilbyr kontinuerlig oppfølging av familien din.',
  conscious_plan_description:
    'Vi knytter sammen alle gravene dine på ett sted og tilbyr kontinuerlig oppfølging av familien din.',
  conscious_plus_plan_description:
    'Vi knytter sammen alle gravene dine på ett sted og tilbyr kontinuerlig oppfølging av familien din.',
  total_user_count: ' For {subscriptionTotalCount} medlemmer er den årlige betalingen:',
  per_month: 'per måned',
  current_plan: 'Gjeldende plan',
  got_started: 'Kom i gang',
  free_feature_heading: 'Alle funksjoner inkludert i gratisplanen',
  conscious_feature_heading: 'Alle funksjoner inkludert i gratisplanen, pluss ekstra funksjoner',
  conscious_plus_feature_heading:
    'Alle funksjoner inkludert i bevissthetsplanen, pluss ekstra funksjoner',
  // Adjust the feature texts according to the requirements.//
  feature_memory_album: 'Nettgrav album',
  feature_purposes: 'Formål',
  feature_end_of_time: 'End of Time Coin',
  feature_family_tree: 'Slektstre',
  feature_testament: 'Testament',
  feature_groups: 'Grupper (Samfunn)',
  feature_Shop: 'Butikk',
  feature_Sparks: 'Gnister',
  feature_life_page: 'Livsside',
  feature_search_grave: 'Søk etter graver',
  feature_create_grave: 'Tenne gnist',
  feature_anniversary_calendar: 'Merkedagskalender',
  feature_value_archive: 'Verdiarkiv',
  question_answer: 'Spørsmål og svar',
  benefits_1_title: 'Nærhet',
  benefits_1_description: 'Vær nær de du bryr deg om når du vil, uansett hvor du er.',
  benefits_2_title: 'Et gravsted som varer',
  benefits_2_description: 'Nettgrav.no er et sted som alltid vil være der.',
  benefits_3_title: 'Se livet på nye måter',
  benefits_3_description: 'Nettgrav.no hjelper deg å tenke nytt og gjøre gode ting.',
  benefits_4_title: 'Bestem over graven',
  benefits_4_description:
    'Du kan lage, endre og passe på graven på nett. Den som eier graven, har alltid kontroll.',
  benefits_title: 'Gode grunner til å bruke Nettgrav',
  faqs_1_question: 'Hvilke betalingsmetoder aksepterer dere?',
  faqs_1_answer: 'Vi aksepterer kreditt-/debetkort (Visa, Mastercard) og Vipps',
  faqs_2_question: 'Er det ekstra gebyrer som jeg må være klar over?',
  faqs_2_answer:
    'Det er ingen skjulte gebyrer. Her kan du samle alle gravene og gravlagte uten ekstra gebyrer.',
  faqs_3_question: 'Hvordan oppdaterer jeg betalingsdetaljene mine?',
  faqs_3_answer:
    'For å oppdatere betalingsdetaljene dine, vennligst gå til kontoinnstillingene og velg abonnementsfanen.',
  faqs_4_question: 'Hva skjer etter betalingen er gjennomført?',
  faqs_4_answer:
    'Når betalingen er gjennomført vil du motta et varsel fra Nettgrav som bekrefter at ditt medlemskap er aktivert.',
  faqs_5_question: 'Hva er forskjellen på Fri og Bevisst?',
  faqs_5_answer:
    'Fri-abonnementet gir deg mulighet til å søke etter graver. Bevisst-abonnementet gir deg full tilgang til alle funksjoner uten begrensninger.',
  faqs_6_question: 'Hvem er Bevisst for?',
  faqs_6_answer:
    'Bevisst er for dem som vil få mest mulig ut av livet. Det gir deg styrke til å gi slipp på gamle forutsetninger og ritualer. Med Bevisst får du alltid tilgang til funksjoner som støtter reisen din videre i livet – med utgangspunkt i det mest meningsfulle gravstedet for dine kjære og vårt løfte om alltid å føre deg dit kjærligheten er.',
  update_modal_change_plan: 'Endring av {name}-plan',
  waite_for_validation: 'Vennligst vent mens vi validerer abonnementet',
  add_members: 'Legg til medlemmer',
  remove_members: 'Fjern medlemmer',
  how_many_add: 'Hvor mange medlemmer ønsker du å legge til?',
  how_many_remove: 'Hvor mange medlemmer vil du fjerne?',
  not_remove_all: 'Du kan ikke fjerne alle medlemmene.',
  updated_price: 'Oppdatert pris etter endringene',
  remaining_members: 'Totalt gjenværende medlemmer',
  total_price: 'Totalpris',
  extra_user: 'Det påløper en ekstra kostnad for {subscriptionTotalCount} ekstra medlem(mer)',
  process_payment: 'Fortsett til betaling',
  continue: 'Fortsett',
  subscription_members: 'Medlemmer av abonnementet',
  remove_subscription_members: 'Fjern abonnementsmedlemmer ved å klikke fjern',
  subscription_invitation_members: 'Invitasjoner til abonnement',
  remove_invitation_members: 'Fjern abonnementsinvitasjonen ved å klikke på fjern',
  downgrade_plan: 'Nedgrader plan',
  upgrade_plan: 'Oppgrader plan',
  downgrade_message: 'Deaktiver medlemmer og invitasjoner for å kunne justere abonnementet ditt.',
  upgrade_message: 'Deaktiver medlemmer og invitasjoner for å oppgradere abonnementet ditt.',
  close_message: 'Du kan lukke og fortsette reisen.',
  close: 'Lukk',
  active_members: 'Aktive medlemmer av abonnementet ditt',
  active_members_message: 'Deaktiver medlemmer for å fortsette.',
  active_members_empty_message: 'Det er ingen medlemmer tilknyttet abonnementet ditt.',
  active_invitations: 'Utestående invitasjoner til abonnementet ditt',
  active_invitations_message: 'Deaktiver invitasjoner for å fortsette.',
  active_invitations_empty_message:
    'Det er ingen utestående invitasjoner knyttet til abonnementet ditt.',
  check_out: 'Fullfør prosessen for å få tilgang til graven.',
  select_payment_method: 'Velg hvordan du ønsker å betale',
  order_details: 'Detaljer om abonnementet',
  subscription_number: 'Antall personer i abonnementet ditt',
  taxes: 'Skatt på totalbeløp',
  total: 'Totalt beløp å betale',
  sub_total: 'Ord. pris for medlemskap',
  discount_title: 'Betalingsfrekvens (månedlig eller årlig)',
  discount: 'Medlemsrabatt ved flere',
  renewal: 'Abonnementet fornyes automatisk.',
  renewal_details: `Fornyelsen skjer basert på valgt betalingsfrekvens. Du kan avslutte når som helst.`,
  payment_page_terms: 'Ved å velge "neste", godtar du våre',
  terms_conditions: ' vilkår for bruk.',
  change_free_plan: 'Fri plan - helt uten kostnad',
  free_plan_message: 'Med Fri-planen kan du utforske, finne og opprette Nettgrav.',
  ending_with: 'slutter på',
  add_payment_method: 'Legg til betalingsmåte',
  name_on_card: 'Kortholders navn',
  expiry_date: 'Utløpsdato',
  secure_payment_message: 'Trygg betaling med SSL-kryptering',
  billing_address: 'Din fakturaadresse',
  enter_address: 'Skriv inn din adresse',
  billing_info_message:
    'Denne informasjonen lagres som fakturaadresse for ditt abonnement. Du kan når som helst oppdatere den i kontoprofilen din for fremtidige betalinger.',
  subscribe: 'Abonnér',
  select_language: 'Velg språk',
  payment_error: 'Det oppsto en feil med betalingen',
  subscription_waiting_message: 'Betalingen behandles, vennligst vent...',
  success_message: 'Abonnementet ditt er nå kansellert',
  reactivate_success_message: 'Abonnementet ditt er nå reaktivert',
  choose_plan: 'Velg en plan for å fortsette',
  canceling_waite_message: 'Kansellerer abonnementet, vennligst vent...',
  subscription_overview: 'Abonnementsoversikt',
  members: 'Medlemmer',
  billing_cycle: 'Betalingsfrekvens',
  amount: 'Beløp',
  cancelSubscriptionInfo:
    'Ved å avslutte ditt {planName}-abonnement, vil du miste tilgang til tilknyttede funksjoner. Din nåværende betalingsperiode avsluttes på sluttdatoen, og du vil ikke bli belastet videre. Vær oppmerksom på at data knyttet til kontoen din kan bli utilgjengelige. Du er alltid velkommen tilbake og kan starte abonnementet på nytt når du ønsker.',
  confirm_billing_cycle: 'Bekreft valg',
  different_cycle: 'Velg en annen betalingsfrekvens',
  update_billing: 'Betalingsfrekvensen er oppdatert',
  waiting_update_billing_cycle: 'Bare et øyeblikk, vi oppdaterer betalingsfrekvensen din...',
  add_members_message: 'Medlemmer er lagt til',
  remove_members_message: 'Medlemmer er fjernet',
  your_current_plan: 'Din nåværende plan',
  upgrade_to: 'Oppgrader til {planName}',
  downgrade_to: 'Nedgrader til {planName}',
  members_removed: 'Abonnementsmedlemmer er fjernet',
  invitation_removed: 'Abonnementsinvitasjon er fjernet',
  settings_gender: 'Kjønn',
  mann: 'mann',
  kvinne: 'kvinne',
  annen: 'annet',
  life_page_description:
    'Her kan du se alle gravene du har knyttet deg til, samt din egen livsreise.',
  settings: 'Min profil',
  settings_description:
    'Administrer kontoen din, inkludert passord, medlemskap og personlige detaljer.',
  logout: 'Logg ut og ta en pause',
  logout_description:
    'Takk for at du er med oss i dag. Vi ser frem til å møte deg igjen på reisen.',
  create_new_grave: 'Opprett ny grav',
  create_grave_description: 'Opprett en ny Nettgrav',
  new: 'Ny',
  read: 'Lest',
  empty_notification_message: 'Ingen nye varsler',
  public_anniversaries: 'Offentlige merkedager',
  grave_anniversaries: 'Personlige merkedager',
  public_anniversary: 'Offentlig merkedag',
  grave_anniversary: 'Personlig merkedag',
  anniversary: 'Merkedag',
  anniversary_delete: 'Merkedagen er slettet.',
  anniversary_created: 'Merkedagen er opprettet.',
  anniversary_updated: 'Merkedagen er oppdatert.',
  anniversary_time_end: 'Merkedagen er møtt.',
  empty_anniversaries: 'Opprett merkedag.',
  create: 'Opprett',
  add_dates: 'merkedag',
  date: 'Dato',
  time: 'Tid',
  title: 'Tittel',
  reset_time: 'Nullstill tid',
  home: 'Hjem',
  about: 'Om',
  howTo: 'Hvordan',
  plans: 'Medlemskap',
  find_a_grave: 'Finn en grav',
  create_grave: 'Opprett ny grav',
  take_control: 'Ta eierskap over graven',
  pricing: 'Priser',
  get_started: 'Kom i gang',
  create_new_account: 'Registrer ny konto',
  did_you_know: 'Visste du?',
  registration_description_one:
    'Alle skal ha muligheten til nærhet til gravlagte, når man ønsker det.',
  registration_description_two:
    'Med Nettgrav trenger ingen å uroe seg for gravstell og fysisk vedlikehold.',
  registration_description_three:
    'Sett relasjonen din til gravlagte for å ta gravlagte med videre i livet.',
  enter_your_password: 'Skriv inn passordet ditt',
  confirm_your_password: 'Bekreft passordet ditt',
  type_your_email: 'Skriv inn e-postadressen',
  type_email: 'Skriv inn e-post',
  hi: 'Hei {name}',
  decline: 'Avslå',
  accept: 'Godta',
  read_only: '(Skrivebeskyttet)',
  accepted_message: 'Invitasjon akseptert',
  invited_title: 'Du er invitert til et abonnement',
  open: 'Åpne',
  subscription_invitation_message:
    'Du er invitert til å bli med i {subscription}-abonnementet fra {name}',
  email_error: 'Vennligst skriv inn en gyldig e-postadresse for å fortsette.',
  password_length:
    'Passordet må være minst 8 tegn langt og inneholde både store og små bokstaver, samt tall.',
  filled_out: 'Vennligst fyll ut alle nødvendige feltene for å fortsette.',
  must_agree: 'Husk å godta våre vilkår for å fortsette.',
  inactive: 'Ikke aktiv',
  delete_payment_method: 'Fjerner betalingsmåten, vennligst vent...',
  payment_method_deleted: 'Betalingsmåten er fjernet',
  payment_method_inactive: 'Din nåværende betalingsmåte er enten fjernet eller ikke lenger aktiv.',
  subscription_changes_title: 'Du har bedt om følgende endringer i abonnementet',
  period: 'Du ønsket å endre hvordan betalingen skjer fra {previous} til {new}',
  user_count: 'Du ønsket å redusere antallet personer fra {previous} til {new}',
  product_id: 'Du ønsket å bytte til en annen plan fra {previous} til {new}',
  remove_subscription_change: 'Abonnementsendringen tilbakestilles',
  remove_subscription_loading: 'Vennligst vent, forespørselen om å slette endringer pågår...',
  revert: 'Gå tilbake',
  subscription_changes_subTitle:
    'Endringene trer i kraft når din nåværende betalingsfrekvens avsluttes den {date}',
  add_new_paymentMethod: 'Legg til en ny betalingsmetode',
  filled_out_info: 'Sørg for at alle feltene er fylt ut før du fortsetter.',
  continue_new_card: 'Velg betalingsmetode eller legg til et nytt kort for å fortsette.',
  create_payment_method_waiting: 'Oppretter betalingsmetode. Vennligst vent...',
  login_description: 'Logg inn',
  login_with_email: 'Logg inn med e-post',
  login_with_phone: 'Logg inn med telefonnummer',
  account_created: `Kontoen din er opprettet.`,
  verify_account: 'Bekreft e-post. Gå til innboksen din for å bekrefte e-posten',
  verification_message: `Sjekk innboksen (inkludert søppelpost) og bekreft e-posten for å fullføre prosessen.`,
  resend_email: 'Send bekreftelses-e-post på nytt',
  sending_verification_email: 'Vennligst sjekk e-posten din for en bekreftelsesmelding.',
  sending_verification_code: 'En kode er sendt til telefonnummeret ditt.',
  email_verification: `Sjekk innboksen og bekreft e-posten din.`,
  signup_message_heading: 'Bekreft e-posten din for å komme i gang.',
  signup_message_subheading: 'Takk for at du registrerte deg, {name}!',
  signup_first_message: 'Vi har sendt en bekreftelseslenke til {email}.',
  signup_second_message: 'Hvis du ikke finner e-posten, sjekk søppelpost/spam-mappen.',
  signup_third_message: 'Bekreftelsen tar bare et øyeblikk.',
  signup_forth_message: 'Når du har bekreftet, kan du logge inn.',
  signup_fifth_message: 'Velkommen til Nettgrav – et sted hvor livet vokser videre.',
  cheers: 'Takk for at du er her, og du er ikke alene.',
  the_team: 'Vi er alltid med og for deg.',
  waiting_for_account: `Vi oppretter kontoen din. Vennligst vent et øyeblikk...`,
  account_verification_waiting: 'Vennligst vent, kontobekreftelsen din er i gang.',
  account_verification_heading: 'Kontoen din er bekreftet',
  account_verification_heading_error: 'Kontobekreftelse mislyktes',
  account_verification_description: 'Kontoen din har blitt bekreftet.',
  account_verification_description_error:
    'Det oppsto et problem med å bekrefte kontoen din. Vennligst prøv igjen, eller kontakt oss for hjelp.',
  update_payment_method_waiting: 'Oppdaterer betalingsmåte. Vennligst vent...',
  update_payment_method_success: 'Betalingsmåten er oppdatert',
  warning_payment_method: 'Velg en annen betalingsmåte eller legg til en ny',
  plans_card_payment_method: 'Hvis du ønsker å endre betalingsmåte, trykk:',
  forgot_password_heading: 'Glemt passord?',
  forgot_password_description: `Skriv inn e-postadressen din, så sender vi deg en tilbakestillingslenke.`,
  forgot_password_button: 'Send lenke',
  forgot_password_message: `Vi har sendt en lenke til e-postadressen din. Sjekk innboksen (og søppelpost-mappen) for å tilbakestille passordet.`,
  forgot_password_title: 'Lenke sendt',
  forgot_password_waiting: 'Vennligst vent mens vi sender tilbakestillingslenken.',
  set_new_password: 'Opprett et nytt passord',
  set_new_password_description: 'Skriv inn et sterkt passord og bekreft det for å logge inn igjen.',
  set_new_password_error: 'Vennligst skriv inn passordet ditt',
  set_new_password_success: 'Passordet ditt har blitt oppdatert',
  set_new_password_waiting: 'Vennligst vent mens vi oppdaterer passordet ditt.',
  action_required: 'Handling kreves: ',
  email_verification_alert: `Legg til og verifiser e-posten for å fortsette å logge inn med e-post eller telefon. Kontoer uten en verifisert e-postadresse vil ikke kunne logge inn.`,
  goTo_settings_page: 'Gå til innstillingssiden',
  go_to_home_page: 'Gå til hjemmesiden',
  verify_now: 'Bekreft nå',
  authCode_verified: 'Telefonnummeret er bekreftet',
  personal_information_updated: 'Personlig informasjon oppdatert',
  reset_date: 'Tilbakestill dato',
  sending_verification_waiting:
    'En bekreftelseskode har blitt sendt til telefonnummeret ditt. Vennligst vent...',
  sending_verification_email_waiting:
    'En bekreftelseslenke er sendt til e-posten din. Vennligst vent...',
  local_name: 'Lokalt navn',
  create_anniversary_waiting: 'opprett jubileum pågår vennligst vent...',
  hello: 'Hei',
  coins: 'Mynter',
  set_color: 'Hva er din farge i dag?',
  set_color_button: 'Still inn fargen din',
  sparks: 'Gnister',
  community: 'Fellesskap',
  purpose: 'Hensikt',
  talents: 'Talenter',
  profile_image_changed: 'Bildet ble oppdatert',
  waiting_profile_upload: 'profilbilde oppdateres vennligst vent...',
  graves: 'Graver',
  wisdom: 'Visdom',
  sparks_description: 'Del en gnist som tar fyr og sprer lys.',
  text_area_placeholder: 'Hva kan du dele for å lyse opp andres vei?',
  crop_image: 'Beskjær bildet',
  create_a_grave: 'Lag en grav',
  recurring_anniversary: 'Gjenta hvert år',
  nick_name: 'Kallenavn',
  birth_name: 'Fødselsnavn',
  date_of_birth: 'Fødselsdato',
  date_of_death: 'Dødsdato',
  time_of_birth: 'Fødselstidspunkt',
  time_of_death: 'Dødstidspunkt',
  place_of_birth: 'Fødested',
  place_of_death: 'Dødssted',
  cause_of_death: 'Dødsårsak',
  municipality: 'Kommune',
  country: 'Land',
  cemetery: 'Gravlund',
  grave_number: 'Gravnummer',
  grave_info_updating: 'Oppdaterer gravinformasjon. Vennligst vent...',
  deceased_name: 'Navn',
  deceased_placeholder: 'Skriv inn navnet på gravlagte',
  deceased_gender: 'Kjønn',
  i_am: 'Jeg er',
  buried_is_mine: 'Gravlagte er min',
  select_relationship: 'Velg eller skriv',
  upload_document: 'Last opp dokument for graven',
  upload_document_description: 'Velg hvilket dokument du vil laste opp.',
  death_certificate: 'Bekreftelse på dødsfall',
  birth_certificate: 'Fødselsbevis',
  invoice: 'Kvittering for gravstell eller leie',
  power_of_attorney: 'Bekreftelse fra advokat',
  driving_license: 'ID-kort for den døde',
  proof_of_person: 'Last opp dokument om den gravlagte',
  proof_of_person_description:
    'Dokumentet kan være i PDF-, PNG- eller JPG-format og være mindre enn 5 MB.',
  replace_files: 'Erstatt filer',
  browse_files: 'Finn filen din',
  drag_image: 'eller dra filen hit',
  dear: 'Kjære',
  new_grave_first_description: 'Velkommen til Nettgrav.',
  new_grave_second_description: 'Her kan du lage et trygt og fint gravsted.',
  new_grave_third_description:
    'Når du lager en Nettgrav, sier du ja til at du har lov til å gjøre dette for den som er gravlagt.',
  new_grave_fourth_description: 'Klikk "Godta" for å fortsette.',
  new_grave_agree_terms: 'Jeg sier ja til reglene over',
  enter_name_error: 'Vennligst skriv inn navnet på personen.',
  select_gender_error: 'Vennligst velg kjønn for den avdøde.',
  select_relation_error: 'Vennligst velg din relasjon til den avdøde.',
  select_date_error: 'Vennligst velg en dato for hendelsen.',
  select_reason_error: 'Vennligst velg dødsårsaken."',
  reason_of_death: 'Årsak til dødsfall',
  other_reason: 'Skriv inn annen årsak',
  other_reason_error: 'Vennligst skriv inn en annen årsak',
  select_country: 'Velg land',
  select_municipality: 'Velg kommune',
  select_cemetery: 'Velg kirkegården',
  create_the_grave: 'Opprett Nettgrav',
  create_the_message: 'Vi oppretter Nettgraven, vennligst vent..."',
  death_date_error: 'Vennligst velg en gyldig dødsdato.',
  birth_date_error: 'Vennligst velg en gyldig fødselsdato.',
  month_0: 'Januar',
  month_1: 'Februar',
  month_2: 'Mars',
  month_3: 'April',
  month_4: 'Mai',
  month_5: 'Juni',
  month_6: 'Juli',
  month_7: 'August',
  month_8: 'September',
  month_9: 'Oktober',
  month_10: 'November',
  month_11: 'Desember',
  weekday_0: 'Søndag',
  weekday_1: 'Mandag',
  weekday_2: 'Tirsdag',
  weekday_3: 'Onsdag',
  weekday_4: 'Torsdag',
  weekday_5: 'Fredag',
  weekday_6: 'Lørdag',
  father: 'Far',
  mother: 'Mor',
  son: 'Sønn',
  husband: 'Ektemann',
  wife: 'Kone',
  brother: 'Bror',
  sister: 'Søster',
  grandfather: 'Bestefar',
  grandmother: 'Bestemor',
  great_grandmother: 'Oldemor',
  grandson: 'Barnebarn (gutt)',
  granddaughter: 'Barnebarn (jente)',
  uncle: 'Onkel',
  uncle_child: 'Søskenbarn',
  aunt: 'Tante',
  aunt_child: 'Søskenbarn',
  nephew: 'Nevø',
  niece: 'Niese',
  stepbrother: 'Stebror',
  stepsister: 'Stesøster',
  stepfather: 'Stefar',
  stepmother: 'Stemor',
  stepson: 'Stesønn',
  stepdaughter: 'Stedatter',
  father_in_law: 'Svigerfar',
  mother_in_law: 'Svigermor',
  brother_in_law: 'Svoger',
  sister_in_law: 'Svigerinne',
  son_in_law: 'Svigersønn',
  daughter_in_law: 'Svigerdatter',
  boyfriend: 'Kjæreste (mann)',
  girlfriend: 'Kjæreste (kvinne)',
  adopt_son: 'Adoptivsønn',
  adopt_daughter: 'Adoptivdatter',
  friends: 'Venner',
  colleagues: 'Kollegaer',
  friend: 'Venner',
  other: 'Noe annet',
  daughter: 'Datter',
  partner: 'Partner',
  colleague: 'Kollega',
  great_granddaughter: 'Oldebarn (jente)',
  great_grandson: 'Oldebarn (gutt)',
  great_grandfather: 'Oldefar',
  great_great_granddaughter: 'Tippoldebarn (jente)',
  great_great_grandson: 'Tippoldebarn (gutt)',
  great_great_grandmother: 'Tippoldemor',
  great_great_grandfather: 'Tippoldefar',
  great_great_great_granddaughter: 'Tipp-tippoldebarn (jente)',
  great_great_great_grandson: 'Tipp-tippoldebarn (gutt)',
  great_great_great_grandmother: 'Tipp-tippoldemor',
  great_great_great_grandfather: 'Tipp-tippoldefar',
  cohabitant: 'Samboer',
  free_description: 'Søk etter Nettgraver og Skap Gnist på din livsside',
  conscious_description: 'Inkluderer Nettgrav - graven på nett',
  consciousPlus_description: 'Tilgang som Bevisst, med ekstra trykk på gravens etikk.',
  feature_life_archive: 'Livsarkiv',
  feature_memory_wall: 'Gravtavle',
  contact_person: 'Din kontaktperson',
  invited_person_name: 'Navn',
  monthly: 'Månedlig',
  semi_annual: 'Halvårlig',
  annually: 'Årlig',
  biennial: 'Annenhvert år',
  auth_modal_description: `Opprett konto eller logg inn for å finne graver og utforske mer.`,
  auth_modal_heading: 'Opprett konto eller logg inn',
  complete_pending_payment: `Fullfør betalingen for å fortsette.`,
  subscription_base_price: 'Grunnpris for abonnement',
  membership: 'medlemskap',
  relation_to_grave: 'Invitert relasjon til {graveName}',
  relation_to_person: '{graveName} sin relasjon til inviterte',
  administrator_access: 'Administrator-tilgang',
  select_role: 'Velg brukerrolle',
  normal: 'Nei, ikke tildel administrator-rolle',
  select_role_error: 'Vennligst velg en rolle',
  user_name: 'Brukernavn',
  remove_all_grave_user: 'Viktig melding: Oppdatering av gravforvaltning',
  remove_all_grave_user_message:
    'Du kan ikke fjerne alle medlemmer eller administratorer fra graven. Hvis du ønsker å fjerne alle, må du overføre eierskapet eller slette graven. En grav må alltid være tilknyttet en bruker eller administrator for korrekt forvaltning.',
  send_it: 'Send det',
  public_view: 'Offentlig visning',
  constellation: 'Konstellasjon',
  moons_only: 'Kun måner',
  question: 'spørsmål',
  insight: 'innsikt',
  experience: 'erfaring',
  discovery: 'oppdagelse',
  feeling: 'følelse',
  value: 'verdi',
  energy: 'energi',
  select_category: 'Velg kategori',
  spark_delete: 'Gnisten ble slukket',
  spark_create: 'Gnisten din er tent',
  spark_update: 'Gnisten din har fått nytt liv',
  ENG: 'Engelsk',
  NOR: 'Norsk',
  added_members: 'Medlemmer',
  removed_members: 'Antall medlemmer som skal fjernes fra abonnementet',
  allowed_members: 'Tillatt antall tilknyttede medlemmer',
  loading: 'Vennligst vent, laster...',
  in_your_mind: `Hva er din gnist, {name}?`,
  create_spark: 'Tenn Gnist',
  empty_graves:
    'Bruk søkefeltet øverst til venstre for å finne en grav. Ingen graver lagt til ennå. Merk: For å lagre graver på din livsside, må du være medlem.',
  all: 'Alle',
  owner: 'Eier',
  administrator: 'Administrator',
  affiliated: 'Tilknyttet',
  view_all: 'Vis alle',
  close_relation: 'Dine nærmeste kommer her når du har lagt til noen.',
  no_relation_set: 'Det er ikke lagt til noen ennå.',
  love_ones: '{name} sine nærmeste',
  set_relation: 'Velg forhold',
  relation_request_send: 'Din relasjonsforespørsel har blitt sendt.',
  relation_connection: '{name} er min {reciprocalRelation}, og jeg er {relation} til {name}.',
  request_send: 'Forespørsel er sendt',
  empty_sparks: 'Ingen Gnister enda. Send en Gnist som kan inspirere til refleksjon og samhold.',
  relatives: 'Nære og kjære',
  account_heading: 'Slett Nettgrav-kontoen din',
  delete_account: 'Slett konto',
  delete_account_pending: 'Kontosletting pågår',
  deleted_message_pending:
    'Forespørsel om sletting er sendt. Kontoen din og all data vil bli slettet. Du kan avbryte slettingen før 23:50 i dag. Hvis du har spørsmål kontakt oss på kontakt@nettgrav.no.',
  deleted_message:
    'Slett kontoen din og all data som hører til. Dette kan ikke angres etter at angrefristen er utløpt. Du kan avbryte slettingen frem til 23:50 samme dag. Hvis du har spørsmål, kontakt oss på kontakt@nettgrav.no.',
  confirm_delete_account: 'Er du sikker på at du vil slette kontoen?',
  confirm_cancel_account: 'Vil du avbryte slettingen av kontoen?',
  phone_number_error: 'Telefonnummer eller passord er feil',
  email_error_login: 'E-post eller passord er feil',
  set_relation_heading: 'Koble til graven',
  set_relation_free_description: `Fortell hvem {graveName} er for deg, og få tilgang til graven.`,
  set_relation_access: 'Som medlem kan du:',
  do_you_know: 'Hvem er {graveName} for deg?',
  set_relation_description:
    'Lagre og åpne opp gravens funksjoner ved å knytte din relasjon til gravlagte',
  set_relation_info_message: `Når du har valgt hvordan dere hører sammen, kan du besøke graven når du vil.`,
  click_button_below:
    'Hvis du har en gratis konto, vil du bli bedt om å betale 27,- per måned via Vipps når du trykker på «Bekreft valget og bli med». Velg forhold for å fortsette.',
  feature_title_1: 'Nettgrav album',
  feature_description_1: 'Legge til bilder på graven',
  feature_title_2: 'Gravtavle',
  feature_description_2: 'Lese og dele minner på graven',
  feature_title_3: 'Merkedagskalender',
  feature_description_3: 'Oversikt på merkedager',
  feature_title_4: 'Verdiarkiv',
  feature_description_4: 'Ta vare på hvem gravlagte var',
  feature_title_5: 'Livsarkiv',
  feature_description_5: 'Kronologisk oversikt over livet til gravlagte',
  choose_relation: 'Si hvem dette er for deg',
  relation_label: 'Jeg er {grave} sin',
  reciprocal_label: '{grave} er min',
  set_relation_note: 'Velg hvem den gravlagte er for deg',
  set_relation_other: 'For alle som kjente den gravlagte – som kolleger, fan eller noe annet.',
  set_relation_placeholder: `For eksempel, jeg var følger av {grave} og ble inspirert av arbeidet deres.`,
  confirm_relation_member: 'Bekreft og betal',
  confirm_membership: 'Bekreft valget',
  enlightened: 'Opplyst',
  inspired: 'Inspirert',
  reflected: 'Reflektert',
  comments: 'kommentarer',
  comment: 'Kommentar',
  empty_comments: 'Ingen kommentarer funnet',
  comment_placeholder: 'skriv kommentar...',
  reply: 'Svar',
  view_replies: 'Se alle {total} svar',
  create_grave_body: 'For å lage en ny Nettgrav trenger du et Bevisst-medlemskap.',
  upgrade_modal_heading: 'Opprett ny grav',
  go_to: 'Gå til',
  our_plans: 'aktiver medlemskap',
  choose_the_subscription: 'for å få alle funksjonene.',
  link_grave_body: 'For å lage en kobling mellom graver, trenger du et Bevisst medlemskap.',
  add_more_images_body:
    'For å legge til flere bilder i albumer trenger du å oppgradere abonnementet ditt.',
  inviting_body: 'Å invitere er tilgjengelig med Bevisst medlemskap.',
  life_archive_body: 'Livsarkivet er tilgjengelig med Bevisst medlemskap.',
  upload_images_body:
    'For å legge til bilder, er en oppgradering til ditt nåværende abonnement nødvendig',
  add_value_body: 'Så fint at du vil tilføre verdi. For å legge til, trenger du bevisst medlemskap',
  search_limit_body:
    'Dykk dypere inn i Nettgrav med vår bevisste Plan. Oppgrader i dag for ubegrensede søk.',
  grave_invitation_first_text: 'Du er invitert til å bidra til graven',
  of: 'fra',
  grave_invitation_second_text: `Med invitasjonen kan du legge 10 minner til graven. For å legge til flere minner og samarbeide, må du være en del av en
bevisst abonnement eller har et individuelt betalt abonnement.`,
  grave_email_info:
    'Denne e-posten inviterte deg til å bidra til graven og kan ikke endres. Hvis du har spørsmål, vennligst kontakt personen som inviterte deg for hjelp.',
  subscription_email_info:
    'Denne e-posten ble brukt til å registrere abonnementet ditt og kan ikke endres. Hvis du har spørsmål, ta gjerne kontakt med personen som opprettet abonnementet.',
  grave_invitation_message:
    '{name} har invitert deg til å bidra til graven. Godta invitasjonen eller fullfør betalingen for å bli med.',
  accept_or_pay: 'Godta og fullfør betalingen',
  pay_and_reactivate: 'Betal for å aktivere medlemskapet på nytt',
  paid_grave_user_message:
    'Du er ønsket av {userName} til å bli med på graven til {graveName}. Godta invitasjonen når du føler deg klar.',
  unpaid_grave_user_message:
    'Du er ønsket av {userName} til å bli med på graven tl {graveName}. Hvis du ønsker å oppgradere, er vi her for å hjelpe deg. Godta invitasjonen når du er klar.',
  active_unpaid_grave_user_message: `Det ser ut som abonnementet ditt ikke er aktivt for øyeblikket. Når du er klar, kan du aktivere det igjen. Vi er her hvis du trenger hjelp.`,
  invite: 'Invitere',
  complete_relation_setup: 'Fullfør forbindelsen din',
  already_set_relation: `Du har valgt å knytte deg til {graveName} som {relation}.`,
  already_accept_relation: 'Du har allerede godtatt forholdet ditt til {graveName} som {relation}.',
  finalize_membership: `For å åpne graven og få tilgang til alt som gjør Nettgrav til et fint sted, fullfør betalingen for medlemskapet. Med medlemskapet får du tilgang til:`,
  click_below_to_complete:
    'Hvorfor medlemskap? Vi ønsker at hver grav skal være et trygt sted der du kan dele minner og historier med familien din. Trykk på knappen for å fortsette.',
  complete_payment: 'Fullfør betaling',
  awaiting_owner_approval: `Venter på godkjenning`,
  awaiting_approval_message:
    'Eieren har fått beskjed om at du ønsker tilgang til graven. Du får beskjed så snart de har svart.',
  subscribed_grave_invitation:
    '{name} har invitert deg til å koble til graven til {graveName} som {relation}.',
  click_below_to_accept: 'Klikk på knappen for å godta invitasjonen og bekrefte forholdet.',
  accept_invitation: 'Godta invitasjonen',
  accept_invitation_payment: 'Godta og betal',
  complete_invitation: 'Fullfør invitasjonen',
  unsubscribed_grave_invitation:
    'For å godta invitasjonen og koble til graven, må du fullføre betalingen. Da får du tilgang til alle funksjonene:',
  you_are_invited: `Du er invitert!`,
  reclaim_user_access: 'Få tilbake tilgangen din',
  previous_relation: 'Du var tidligere koblet til graven {graveName} som {relation}.',
  downgrade_subscription:
    'Medlemskapet ditt er nå gratis. Du har begrenset tilgang til denne graven, men du er fortsatt medlem.',
  regain_access: 'For å få tilbake full tilgang, oppgrader medlemskapet ditt.',
  click_below_to_upgrade: 'Klikk på knappen for å oppgradere og få tilbake tilgangen.',
  upgrade_membership: 'Oppgrader medlemskap',
  set_self_relation: 'Si hvem {graveName} er for deg for å koble dere sammen.',
  set_relation_linked: 'Når du gjør dette, kan du:',
  set_self_relation_1: 'Besøke graven når du trenger det.',
  set_self_relation_2: 'Ta vare på minnene og familien.',
  set_self_relation_3: 'La graven bety mer for livet videre.',
  set_self_relation_message: 'Viktig melding:',
  claim_grave_labe: 'Jeg ønsker å ha ansvar for denne graven. Kryss av her for å si ifra.',
  claim_grave_terms_1:
    'Velkommen til Nettgrav. Her kan du gjøre krav på et trygt og fint gravsted.',
  claim_grave_terms_2: `Når du gjør krav på en Nettgrav, sier du ja til at du har lov til å gjøre dette for den som er gravlagt.`,
  claim_grave_terms_3: `Ofte er det graveier av den fysiske graven eller en representant av den.`,
  claim_grave_terms_4: `Klikk «Godta» for å fortsette.`,
  i_agreed_claim: 'Jeg godtar vilkårene ovenfor',
  upload_death_document: 'Last opp dokumentasjon som bekrefter graven og din tilknytning til den.',
  upload_death_document_limit: 'Dokumentet kan være PDF-, png-, jpg-format og mindre enn 5 MB',
  drag_files: 'eller dra og slipp filen her',
  claim_grave_message: 'Vil du ha ansvar for denne graven?:',
  take_ownership: 'Klikk her for å si ifra',
  claim_request_queue:
    'Forespørselen din om å få ansvar for graven til {graveName}, er sendt. Nå venter vi på godkjenning. Du får beskjed når den er gjennomgått.',
  register_ownership: 'Bli ansvarlig',
  public: 'Offentlig',
  private: 'Privat',
  relatives: 'Nære og kjære',
  need_subscription: 'Du trenger et abonnement for å legge til minner.',
  upgrade_subscription: 'Oppgrader abonnement',
  no_memories_added: 'Ingen innlegg på gravveggen ennå.',
  input_memory_placeholder: 'Ta med gravlagte videre her.',
  memory_discard_message: 'Er du sikker på at du vil forkaste endringene dine?',
  discard_changes: 'Forkaste endringer?',
  discard: 'Forkast',
  post_update_success: 'Innlegget ble oppdatert vellykket',
  post_share_success: 'Innlegget ble delt vellykket',
  post_failed: 'Kunne ikke publisere. Vennligst prøv igjen.',
  file_upload_failed: 'Kunne ikke laste opp fil',
  posting: 'Legger ut',
  create_post: 'Ivaretar {graveName}',
  updating: 'Oppdaterer',
  edit_post: 'Rediger innlegg',
  update_memory: 'Oppdater',
  post: 'Legg ut',
  memory_description_placeholder: `Her kan du ivareta livet, {name}`,
  add_photos_videos: 'Legg til bilder/videoer',
  click_or_drag_media: 'Klikk eller dra mediefiler hit',
  edit_image: 'Rediger bilde',
  edit_video: 'Rediger video',
  add_caption: 'Legg til en bildetekst...',
  memory_deleted_success: 'Innlegget er slettet',
  memory_delete_failed: 'Kunne ikke slette innlegget.',
  delete_memory_title: 'Er du sikker på at du vil slette?',
  delete_memory_content: 'Denne handlingen kan ikke angres.',
  delete_memory_confirm: 'Ja, slett',
  write_something_or_add_media: 'Skriv noe eller legg til media i innlegget',
  need_subscription: 'Du trenger et abonnement for å skrive på gravveggen.',
  add_to_your_post: 'Bilde/Video',
  memory_review: 'Forhåndsvisning av minne',
  grave_wall: 'Gravstøtten',
  life_archive_menu: 'Livsarkivet',
  media: 'Om',
  connect_grave: 'Knytt gravlagte',
  link_grave_body: 'Du trenger et abonnement for å få tilgang til tilknyttede graver.',
  upgrade_plan_modal: 'Oppgrader planen din',
  more: 'Mer',
  relations: 'Relasjoner',
  about_gender: 'Kjønn',
  about_birthplace: 'Fødested',
  about_nickname: 'Kallenavn',
  about_birth_name: 'Fødselsnavn',
  about_death_location: 'Dødssted',
  about_cemetery: 'Gravplass',
  about_birthday: 'Fødselsdato',
  about_death_date: 'Dødsdato',
  about_relations: 'Relasjoner',
  about_cause_of_death: 'Dødsårsak',
  about_title: 'Om',
  about_country: 'Land',
  about_name: 'Navn',
  about_privacy: 'Personvern',
  edit_grave_details: 'Rediger gravdetaljer',
  buried: 'Gravlagte',
  relationships: 'Relasjoner',
  work: 'Arbeid',
  grave_link: 'Gravlagt med',
  Søndag: 'Søndag',
  Mandag: 'Mandag',
  Tirsdag: 'Tirsdag',
  Onsdag: 'Onsdag',
  Torsdag: 'Torsdag',
  Fredag: 'Fredag',
  Lørdag: 'Lørdag',
  placeholder_name: 'Skriv inn navn',
  placeholder_nickname: 'Skriv inn kallenavn',
  placeholder_birth_name: 'Skriv inn fødselsnavn',
  placeholder_birthplace: 'Skriv inn fødested',
  placeholder_death_location: 'Skriv inn dødssted',
  placeholder_gender: 'Velg kjønn',
  placeholder_country: 'Velg land',
  placeholder_municipality: 'Velg kommune',
  placeholder_cemetery: 'Velg gravplass',
  placeholder_cause_of_death: 'Velg dødsårsak',
  placeholder_privacy: 'Velg personvern',
  placeholder_date_of_birth: 'Velg fødselsdato',
  placeholder_time_of_birth: 'Velg fødselstid',
  placeholder_date_of_death: 'Velg dødsdato',
  placeholder_time_of_death: 'Velg dødstid',
  grave_owner: 'graveier',
  admin: 'administrator',
  edit_relation: 'Rediger relasjon',
  remove_member: 'Fjern medlem',
  confirm_delete_user_title: 'Er du sikker?',
  confirm_delete_message: `Er du sikker på at du vil fjerne {username} som familiemedlem?`,
  relation_to_buried: 'Din relasjon til den gravlagte',
  relation_to_user: 'Din relasjon til brukeren',
  role_required: 'Vennligst velg en rolle',
  relation_required: 'Vennligst velg en relasjon',
  direction_required: 'Vennligst velg forhold til den gravlagte',
  invite_family_member: 'Send invitasjon',
  email_required: 'E-post er påkrevd',
  email_required_validation: 'Vennligst skriv inn en gyldig e-postadresse',
  name_required: 'Navn er påkrevd',
  pending_invitations: 'Ventende invitasjoner',
  find_grave_member: 'Finn gravmedlem',
  find_invited_user: 'Finn invitert bruker',
  find_workplace: 'Finn arbeidsplass',
  add_workplace: 'Legg til arbeidsplass',
  company_name_label: 'Firmanavn',
  company_name_placeholder: 'Skriv inn firmanavn',
  company_name_required: 'Firmanavn er påkrevd',
  city_label: 'By',
  city_placeholder: 'Skriv inn by',
  city_required: 'By er påkrevd',
  position_label: 'Stilling',
  position_placeholder: 'Skriv inn stilling',
  position_required: 'Stilling er påkrevd',
  start_year_label: 'Startår',
  start_year_placeholder: 'Velg startår',
  start_year_required: 'Startår er påkrevd',
  end_year_label: 'Sluttår',
  end_year_placeholder: 'Velg sluttår',
  end_year_required: 'Sluttår er påkrevd',
  description_label: 'Beskrivelse',
  description_placeholder: 'Skriv inn jobbbeskrivelse',
  publicPrivacy_label: 'Personvern',
  publicPrivacy_placeholder: 'Velg personverninnstilling',
  publicPrivacy_required: 'Personvernvalg er påkrevd',
  invalid_end_date: 'Ugyldig sluttdato',
  invalid_end_date_message:
    'Sluttdatoen din kan ikke være tidligere enn startdatoen din. Vennligst prøv igjen.',
  no_workplaces_added: 'Ingen arbeidsplasser er lagt til ennå.',
  add_workplaces_message:
    'Legg til den avdødes arbeidsplasser for å hedre deres profesjonelle reise og arv.',
  no_invitations_sent: 'Ingen invitasjoner er sendt ennå.',
  no_invitations_message:
    'Ingen er invitert til denne graven ennå. Send en invitasjon for å dele minnet og la andre bidra til å hedre den avdøde.',
  photos: 'Bilder',
  find_interests: 'Finn interesser',
  add_interest: 'Legg til interesse',
  interest_name: 'Interessenavn',
  interest_name_placeholder: 'Skriv inn interessenavn',
  interest_name_required: 'Interessenavn er påkrevd',
  interest_category: 'Kategori',
  interest_category_placeholder: 'Velg en kategori',
  interest_category_required: 'Kategori er påkrevd',
  interest_description: 'Beskrivelse',
  interest_description_placeholder: 'Skriv inn beskrivelse',
  interest_description_required: 'Beskrivelse er påkrevd',
  confirm_delete_workplace_message: `Er du sikker på at du vil fjerne arbeidsplassen?`,
  confirm_delete_interests_message: `Er du sikker på at du vil fjerne interessen?`,
  no_interests_added: 'Ingen interesser er lagt til ennå.',
  add_interests_message: 'Legg til interesser for å vise den avdødes lidenskaper og hobbyer.',
  add_passion: 'Legg til lidenskap',
  passion_description: 'Beskrivelse',
  passion_description_placeholder: 'Skriv inn beskrivelse',
  passion_description_required: 'Beskrivelse er påkrevd',
  no_passion_added: 'Ingen lidenskaper er lagt til ennå.',
  add_passion_message: 'Legg til lidenskaper for å vise den avdødes lidenskaper og hobbyer.',
  no_grave_linked: 'Ingen graver er koblet til ennå.',
  add_grave_linked_message:
    'Koble til andre graver for å vise forholdet til den avdøde og deres familie.',
  confirm_delete_passion_message: 'Er du sikker på at du vil fjerne lidenskapen?',
  upload_photo: 'Last opp bilde',
  no_photos_added: 'Ingen bilder er lagt til ennå.',
  add_photos_message: 'Legg til bilder av den avdøde for å hedre minnet og arven.',
  select_cover_photo: 'Velg et omslagsbilde',
  add_photos: 'Legg til bilder',
  choose_cover_photo: 'Velg et omslagsbilde',
  upload_image: 'Last opp bilde',
  remove_image: 'Fjern bilde',
  edit_cover_image: 'Rediger omslagsbilde',
  add_cover_photo: 'Legg til omslagsbilde',
  edit: 'Rediger',
  upload_photos: 'Last opp bilder',
  label_description: 'Beskrivelse',
  placeholder_description: 'Skriv beskrivelse',
  label_address: 'Adresse',
  placeholder_address: 'Skriv adresse',
  date_time: 'Dato og tid',
  create_anniversary: 'Opprett merkedag',
  edit_anniversary: 'Rediger merkedag',
  add_life_event: 'Legg til livshendelse',
  no_events_added: 'Ingen livshendelser er lagt til ennå.',
  add_events_message: 'Legg til livsbegivenhetene til den avdøde for å hedre deres reise og arv.',
  event_label_name: 'Navn på arrangementet',
  event_placeholder_name: 'Skriv inn arrangementets navn',
  event_required_name: 'Arrangementets navn er påkrevd',
  event_label_date: 'Dato for arrangementet',
  event_placeholder_date: 'Velg arrangementsdato',
  event_required_date: 'Arrangementsdato er påkrevd',
  event_label_theme: 'Velg tema for arrangementet',
  event_placeholder_theme: 'Velg arrangementstema',
  event_required_theme: 'Arrangementstema er påkrevd',
  event_label_country: 'Land',
  event_placeholder_country: 'Velg land',
  event_required_country: 'Land er påkrevd',
  event_label_description: 'Beskrivelse',
  event_placeholder_description: 'Skriv inn arrangementsbeskrivelse',
  event_required_description: 'Beskrivelse er påkrevd',
  event_theme_light: 'Lyst tema',
  event_theme_dark: 'Mørkt tema',
  create_event: 'Opprett arrangement',
  edit_event: 'Rediger arrangement',
  show_all: 'Vis alle',
  set_as_cover: 'Sett som deksel',
  previous: 'Forrige',
  view_more_events: 'Se flere arrangementer',
  connect_grave_title: 'Gravlagte du kan knytte sammen med {graveName}',
  link_to_grave: 'Koble til graven',
  your: 'Din',
  graves_not_found: 'Ingen graver tilgjengelig for øyeblikket.',
  share: 'Del',
  linked_copied: 'Koblet til og kopiert',
  share_memory: 'Del minne',
  copy_link: 'Kopier lenke',
};

export default NOR;
