import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import { Avatar, Button, Popover } from 'antd';
import isEmpty from 'lodash.isempty';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import moreIcon from '../../../../img/VerticalDots.svg';
import { removeMemory } from '../../../../redux/actions';
import { getMemoryShareUrl } from '../../../../service/constants';
import { request } from '../../../../service/request';
import styles from './MemoryCard.module.css';
import Menu from './Menu';
import ShareModal from './ShareModal';

const Header = ({ user, postCreated, memory, setOpenEditModal, openEditModal, grave }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [openMenu, setOpenMenu] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  const memoryUrl = getMemoryShareUrl(grave.slug, memory.id);

  const goToLifePage = () => {
    navigate(`/member/${user.user.slug}`);
  };

  const handleDeleteMemory = async () => {
    try {
      await request(`/memories/${memory.id}`, null, 'delete');
      dispatch(removeMemory(memory.id));
    } catch (error) {
      showUserNotification(intl.formatMessage({ id: 'memory_delete_failed' }), 'error');
    }
  };

  const getPrivacyIcon = (privacy) => {
    switch (privacy) {
      case 'public':
        return <PublicIcon style={{ fontSize: 14 }} />;
      case 'private':
        return <LockIcon style={{ fontSize: 14 }} />;
      case 'relatives':
        return <PeopleIcon style={{ fontSize: 14 }} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.memoryCardHeader}>
      <div className={styles.header}>
        {!isEmpty(user?.user) && (
          <>
            <Avatar
              src={user?.user.user_avatar}
              size={55}
              onClick={goToLifePage}
              icon={<PersonIcon style={{ fontSize: 40 }} />}
            />
            <div className={styles.userInfo}>
              <div className={styles.name} onClick={goToLifePage}>
                {user?.user.full_name}
              </div>
              <div className={styles.privacyContainer}>
                <div className={styles.createdAt}>{postCreated}</div>
                <span className={styles.dot}>•</span>
                <div className={styles.privacyIcon}>{getPrivacyIcon(memory.privacy)}</div>
              </div>
            </div>
          </>
        )}
      </div>
      {memory.can_edit && memory.can_destroy && (
        <Popover
          placement='bottomLeft'
          open={openMenu}
          onOpenChange={() => setOpenMenu(false)}
          trigger='click'
          content={
            <Menu
              onDelete={handleDeleteMemory}
              setOpenEditModal={setOpenEditModal}
              openEditModal={openEditModal}
              setOpenMenu={setOpenMenu}
              setOpenShareModal={setOpenShareModal}
            />
          }
        >
          <Button
            onClick={() => setOpenMenu(true)}
            icon={<img src={moreIcon} alt='more' style={{ height: 18 }} />}
            type='text'
            shape='circle'
          />
        </Popover>
      )}
      {openShareModal && (
        <ShareModal
          isOpen={openShareModal}
          onClose={() => setOpenShareModal(false)}
          memoryUrl={memoryUrl}
        />
      )}
    </div>
  );
};

export default Header;
