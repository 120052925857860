import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGraveImages } from '../../../../../../redux/actions';
import { request } from '../../../../../../service/request';
import Comment from './Comment';
import CommentReplyFooter from './CommentReplyFooter';
import styles from './GravePhotos.module.css';

const AllComments = ({ comments, setImages, images, image, grave, graveUsers }) => {
  const dispatch = useDispatch();
  const [commentReply, setCommentReply] = useState('');
  const [showReplies, setShowReplies] = useState(null);

  const refreshComments = async () => {
    if (!image.id) return;
    try {
      const response = await request(`/grave_images/${image.id}`);
      const newArray = images.map((photo) => (photo.id === image.id ? response.data : photo));
      setImages(newArray);
      dispatch(setGraveImages([...newArray]));
    } catch (err) {
      console.log(err);
    }
  };

  const createCommentReply = async (comment) => {
    try {
      const graveCommentReply = {
        reply: {
          image_comment_id: comment.id,
          description: commentReply,
        },
      };
      await request('/grave_image_replies', graveCommentReply, 'post');
      setCommentReply('');
      await refreshComments();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.allComments}>
      {comments.map((comment) => (
        <div>
          <Comment
            key={comment.id}
            comment={comment}
            setImages={setImages}
            image={image}
            images={images}
            grave={grave}
            setShowReplies={setShowReplies}
            showReplies={showReplies}
            refreshComments={refreshComments}
          />
          {showReplies === comment.id && (
            <CommentReplyFooter
              user={graveUsers}
              comment={commentReply}
              setComment={setCommentReply}
              createCommentReply={() => createCommentReply(comment)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default AllComments;
