import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import 'react-toastify/dist/ReactToastify.css';
import { request } from '../../../../service/request';
import styles from './CreatePostModal.module.css';
import CropImageModal from './CropImageModal';

const MediaUploader = ({ setFileList, fileList, setRemovedFiles, memoryId }) => {
  const intl = useIntl();
  const [editingFile, setEditingFile] = useState(null);

  useEffect(() => {
    const handleBackButton = (event) => {
      if (editingFile) {
        event.preventDefault();
        setEditingFile(null); // Close the modal instead of going back
      }
    };

    if (editingFile) {
      window.history.pushState(null, '', window.location.href); // Add a fake history entry
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [editingFile, setEditingFile]);

  const handleRemove = async (file) => {
    if (file.originFileObj?.preview) {
      URL.revokeObjectURL(file.originFileObj.preview);
    }
    if (file.url) {
      URL.revokeObjectURL(file.url);
    }

    if (file.id) {
      const formData = {
        memory: {
          memory_contents_attributes: [{ id: file.id, _destroy: true }],
        },
      };
      await request(`/memories/${memoryId}`, formData, 'put');

      setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
    } else {
      setRemovedFiles((prev) => new Set([...prev, file.uid]));
      setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
    }

    return false;
  };

  const handleEdit = (file) => {
    setEditingFile(file);
  };

  return (
    <div style={{ marginBottom: 12, padding: '0 16px' }}>
      {fileList.length > 0 && (
        <div className={styles.previewWrapper}>
          <Row gutter={[4, 4]}>
            {fileList.map((file) => {
              const fileURL = file.url || file.originFileObj?.preview;
              const isImage = file.type === 'image' || file.type.startsWith('image/');
              const isVideo = file.type === 'video' || file.type.startsWith('video/');

              return (
                <Col key={file.uid} xs={24} sm={12} md={8}>
                  <div className={styles.previewItem}>
                    <div className={styles.mediaWrapper}>
                      {isImage ? (
                        <img src={fileURL} alt='preview' className={styles.media} />
                      ) : isVideo ? (
                        <video src={fileURL} controls muted className={styles.media} />
                      ) : null}
                    </div>

                    <Space direction='vertical' style={{ width: '100%', marginTop: 8 }}>
                      <Input
                        type='text'
                        placeholder={intl.formatMessage({ id: 'add_caption' })}
                        value={file.caption}
                        onChange={(e) =>
                          setFileList((prev) =>
                            prev.map((f) =>
                              f.uid === file.uid ? { ...f, caption: e.target.value } : f,
                            ),
                          )
                        }
                      />

                      <Space>
                        {isImage && (
                          <Tooltip title={<FormattedMessage id='settings_edit' />}>
                            <Button
                              shape='circle'
                              size='small'
                              icon={<EditOutlined />}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEdit(file);
                              }}
                              style={{ position: 'absolute', top: '5px', right: '32px' }}
                            />
                          </Tooltip>
                        )}

                        <Tooltip title={<FormattedMessage id='settings_remove' />}>
                          <Button
                            shape='circle'
                            size='small'
                            icon={<DeleteOutlined style={{ color: 'red' }} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemove(file);
                            }}
                            style={{ position: 'absolute', top: '5px', right: '5px' }}
                          />
                        </Tooltip>
                      </Space>
                    </Space>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      )}
      {editingFile && (
        <CropImageModal
          editingFile={editingFile}
          setEditingFile={setEditingFile}
          setFileList={setFileList}
        />
      )}
    </div>
  );
};

export default MediaUploader;
