import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CreateEventModal from '../../../NewLifeEvents/Components/CreateEventModal';
import LifeEventDetails from '../../../NewLifeEvents/Components/LifeEventDetails/LifeEventDetails';
import styles from './Event.module.css';
import EventMedia from './EventMedia';

const Event = ({ event }) => {
  const grave = useSelector((state) => state.grave.grave);
  const user = useSelector((state) => state.user.user || {});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const eventMedia = useMemo(() => {
    if (!event || (!event.life_event_images && !event.life_event_videos)) return [];

    const images = Array.isArray(event.life_event_images)
      ? event.life_event_images.map((image) => ({ ...image, type: 'image' }))
      : [];

    const videos = Array.isArray(event.life_event_videos)
      ? event.life_event_videos.map((video) => ({ ...video, type: 'video' }))
      : [];

    const displayImages = images.filter((img) => img.category === 'display');
    const otherImages = images.filter((img) => img.category !== 'display');

    return [...displayImages, ...otherImages, ...videos];
  }, [event]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [event]);

  const prevMedia = useCallback(() => {
    if (eventMedia.length === 0) return;
    setCurrentIndex((prev) => (prev - 1 + eventMedia.length) % eventMedia.length);
  }, [eventMedia]);

  const nextMedia = useCallback(() => {
    if (eventMedia.length === 0) return;
    setCurrentIndex((prev) => (prev + 1) % eventMedia.length);
  }, [eventMedia]);

  if (!event) return null;

  const getEventCreator = () => {
    return event.creator_id === user.id;
  };

  return (
    <>
      <div className={styles.eventYear}>{event.event_year}</div>
      <div
        className={styles['event-wrapper']}
        style={{ background: event.theme_color }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setSearchParams({ tab: 'events', event_id: event.id });
        }}
      >
        <EventMedia
          media={eventMedia}
          onNext={nextMedia}
          onPrev={prevMedia}
          currentIndex={currentIndex}
          isCreator={getEventCreator()}
          setOpenModal={setOpenModal}
          event={event}
        />
        <LifeEventDetails event={event} grave={grave} user={user} />
        {openModal && (
          <CreateEventModal
            visible={openModal}
            onClose={() => setOpenModal(false)}
            grave={grave}
            event={event}
            user={user}
          />
        )}
      </div>
    </>
  );
};

export default Event;
