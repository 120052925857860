import { EditOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { DeleteOutline } from '@mui/icons-material';
import { Button, Modal, Popover } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './Interests.module.css';
const { confirm } = Modal;

const PopoverMenu = ({ interest, onEditInterest, onRemoveInterest }) => {
  const intl = useIntl();
  const [popoverVisible, setPopoverVisible] = useState(false);

  const showDeleteConfirm = () => {
    confirm({
      title: intl.formatMessage({ id: 'confirm_delete_user_title' }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: 'confirm_delete_interests_message' }),
      okText: intl.formatMessage({ id: 'delete_memory_confirm' }),
      okType: 'danger',
      cancelText: intl.formatMessage({ id: 'settings_cancel' }),
      onOk: () => onRemoveInterest(interest.id),
    });
  };

  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  return (
    <>
      <Popover
        content={
          <div className={styles.popoverContent}>
            <Button
              type='text'
              icon={<EditOutlined />}
              onClick={onEditInterest}
              className={styles.popoverButton}
            >
              <FormattedMessage id='settings_edit' />
            </Button>
            <Button
              type='text'
              danger
              onClick={() => {
                showDeleteConfirm();
                setPopoverVisible(false);
              }}
              icon={<DeleteOutline style={{ fontSize: 16 }} />}
              className={styles.popoverButton}
            >
              <FormattedMessage id='delete' />
            </Button>
          </div>
        }
        trigger='click'
        open={popoverVisible}
        onOpenChange={handleVisibleChange}
        placement='bottomRight'
      >
        <Button type='text' shape='circle' icon={<MoreOutlined />} className={styles.moreButton} />
      </Popover>
    </>
  );
};

export default PopoverMenu;
