import React, { useEffect, useState } from 'react';
import LifeEvent from '../LifeEvent';

const LifeEvents = ({ events }) => {
  const [eventsByYear, setEventsByYears] = useState({});

  useEffect(() => {
    const eventsByYearAndMonth = {};

    if (events && events.length > 0) {
      events.forEach((event) => {
        const eventYear = event.event_year;
        const eventMonth = new Date(event.event_date).getMonth();

        if (!eventsByYearAndMonth[eventYear]) {
          eventsByYearAndMonth[eventYear] = {};
        }
        if (!eventsByYearAndMonth[eventYear][eventMonth]) {
          eventsByYearAndMonth[eventYear][eventMonth] = [];
        }
        eventsByYearAndMonth[eventYear][eventMonth].push(event);
      });
      setEventsByYears(eventsByYearAndMonth);
    }
  }, [events]);

  return Object.entries(eventsByYear).map(([year, eventsInYear], index) => (
    <LifeEvent key={index} year={year} eventsInYear={eventsInYear} index={index} />
  ));
};

export default LifeEvents;
