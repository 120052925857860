import { Button, Col, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const PassionForm = ({ passion, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    if (passion) {
      form.setFieldsValue({
        description: passion.comment,
      });
    }
  }, [passion, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (passion) {
        onSubmit(values, passion.id);
        onCancel();
      } else {
        onSubmit(values);
        onCancel();
      }
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  return (
    <Form form={form} layout='vertical' onFinish={handleSubmit}>
      {/* Description */}
      <Form.Item
        name='description'
        label={<FormattedMessage id='passion_description' defaultMessage='Description' />}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'passion_description_required',
              defaultMessage: 'Description is required',
            }),
          },
        ]}
      >
        <Input.TextArea
          rows={3}
          placeholder={intl.formatMessage({
            id: 'passion_description_placeholder',
            defaultMessage: 'Enter description',
          })}
        />
      </Form.Item>

      {/* Buttons */}
      <Col xs={24}>
        <Form.Item>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            <FormattedMessage id='settings_cancel' />
          </Button>
          <Button type='primary' onClick={handleSubmit} style={{ backgroundColor: '#404d56' }}>
            <FormattedMessage id='auth_save' />
          </Button>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default PassionForm;
