import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styles from './MemoryCard.module.css';

const Footer = ({ memory, isCommentModal }) => {
  const [_searchParams, setSearchParams] = useSearchParams();

  return (
    <div
      className={styles.commentWrapper}
      style={{
        borderBottom: isCommentModal ? '1px solid #d8d8d8' : 'none',
        paddingBottom: 8,
      }}
    >
      <button
        className={styles['comment']}
        onClick={() => {
          if (!isCommentModal) {
            setSearchParams({ memory: memory.id });
          }
        }}
      >
        <ChatBubbleIcon style={{ fontSize: '16px' }} />
        {memory.comments_count > 0 && <span>{memory.comments_count}</span>}
        <FormattedMessage id='comment' />
      </button>
    </div>
  );
};

export default Footer;
