import { Button, Col, Form, Input, Row, Select } from 'antd';
import isEmpty from 'lodash/isEmpty'; // To check if an object is empty
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const { Option } = Select;

const RelationshipForm = ({ userData, relations, relationDirections, onSubmit, onCancel }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [filteredDirections, setFilteredDirections] = useState([]);

  // Initialize values from userData
  useEffect(() => {
    if (!isEmpty(userData.relation)) {
      setFilteredDirections(filterDirectionList(userData.relation));
    }
  }, [userData]);

  const initialValues = {
    username: userData?.user?.full_name || '',
    relation: userData.relation?.id || null,
    relationDirection: userData.reciprocal_relation?.id || null,
    role: userData?.admin ? 'admin' : 'normal',
  };

  // Handle Relation Selection
  const handleOnSelectRelation = (option) => {
    if (!option) {
      form.setFieldsValue({ relationDirection: null, relation: null });
      setFilteredDirections([]);
    } else {
      form.setFieldsValue({ relationDirection: null, relation: option.id });
      setFilteredDirections(filterDirectionList(option));
    }
  };

  // Handle Reciprocal Relation Selection
  const handleOnSelectReciprocalRelation = (value) => {
    form.setFieldsValue({ relationDirection: value });
  };

  // Filter Reciprocal Relations Based on Selected Relation
  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = relationDirections
      .filter((item) => item.from_relation.id === selectedRelation.id)
      .map((item) => item.to_relation);

    return filteredList.length > 0 ? filteredList : [selectedRelation];
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields(); // Ensure all fields are validated before submitting
      onSubmit(values, userData.id);
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  return (
    <Form form={form} layout='vertical' initialValues={initialValues} onFinish={handleSubmit}>
      <Row gutter={[16, 0]}>
        {/* Username (Read-Only) */}
        <Col xs={24} md={12}>
          <Form.Item label={<FormattedMessage id='user_name' />} name='username'>
            <Input placeholder={intl.formatMessage({ id: 'user_name' })} disabled readOnly />
          </Form.Item>
        </Col>

        {/* Role Selection */}
        <Col xs={24} md={12}>
          <Form.Item
            label={<FormattedMessage id='administrator_access' />}
            name='role'
            rules={[{ required: true, message: intl.formatMessage({ id: 'role_required' }) }]}
          >
            <Select placeholder='Select role'>
              <Option value='admin'>
                <FormattedMessage id='administrator' />
              </Option>
              <Option value='normal'>
                <FormattedMessage id='normal' />
              </Option>
            </Select>
          </Form.Item>
        </Col>

        {/* Relation Selection */}
        <Col xs={24} md={12}>
          <Form.Item
            label={<FormattedMessage id='relation_to_user' />}
            name='relation'
            rules={[{ required: true, message: intl.formatMessage({ id: 'relation_required' }) }]}
          >
            <Select
              showSearch
              placeholder={intl.formatMessage({ id: 'select_relationship' })}
              onChange={(_value, option) =>
                handleOnSelectRelation(option ? option['data-object'] : null)
              }
              optionFilterProp='label'
              options={relations.map((rel) => ({
                label: rel.key ? intl.formatMessage({ id: rel.key }) : rel.name,
                value: rel.id,
                'data-object': rel,
              }))}
              allowClear
            ></Select>
          </Form.Item>
        </Col>

        {/* Relation Direction Selection */}
        <Col xs={24} md={12}>
          <Form.Item
            label={<FormattedMessage id='relation_to_buried' />}
            name='relationDirection'
            rules={[{ required: true, message: intl.formatMessage({ id: 'direction_required' }) }]}
          >
            <Select
              showSearch
              placeholder={intl.formatMessage({ id: 'select_relationship' })}
              onChange={handleOnSelectReciprocalRelation}
              allowClear
              optionFilterProp='label'
              options={filteredDirections.map((rel) => ({
                label: rel.key ? intl.formatMessage({ id: rel.key }) : rel.name,
                value: rel.id,
              }))}
              disabled={form.getFieldValue('relation') === null}
            ></Select>
          </Form.Item>
        </Col>

        {/* Buttons */}
        <Col xs={24}>
          <Form.Item>
            <Button onClick={onCancel} style={{ marginRight: 8 }}>
              <FormattedMessage id='settings_cancel' />
            </Button>
            <Button type='primary' onClick={handleSubmit} style={{ backgroundColor: '#404d56' }}>
              <FormattedMessage id='auth_save' />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default RelationshipForm;
