import { Button, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { goToChosenGrave } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import styles from './GraveAvatar.module.css';

const EditAvatarModal = ({ visible, onClose, imageItem, grave }) => {
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImage = async () => {
    if (!imageItem.url || !croppedAreaPixels) return null;

    try {
      const croppedBlob = await cropImage(imageItem.url, croppedAreaPixels);
      return croppedBlob;
    } catch (error) {
      console.error('Cropping error:', error);
      return null;
    }
  };
  const cropImage = async (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.src = imageSrc;
      image.crossOrigin = 'anonymous';

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );

        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error('Canvas to Blob conversion failed'));
          },
          'image/jpeg',
          0.95, // High quality
        );
      };

      image.onerror = (err) => reject(err);
    });
  };

  const handleSave = async () => {
    try {
      const croppedImageBlob = await getCroppedImage();
      if (croppedImageBlob) {
        const formData = new FormData();
        const blob = new Blob([croppedImageBlob], { type: croppedImageBlob.type });
        formData.append('grave[avatar]', blob, 'avatar.jpg');
        const headers = { 'Content-Type': 'multipart/form-data' };
        const response = await request(`/graves/${grave.slug}`, formData, 'put', headers);
        if (response.status >= 200 && response.status < 300) {
          dispatch(goToChosenGrave(response.data));
        }
      }
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<FormattedMessage id='edit_image' />}
      width={window.innerWidth <= 768 ? '100%' : 800}
      style={{
        top: window.innerWidth <= 768 ? 0 : '10%',
        margin: window.innerWidth <= 768 ? 0 : 'auto',
        maxWidth: window.innerWidth <= 768 ? '100%' : 'calc(100% - 40px)',
      }}
      styles={{
        header: {
          color: '#333',
          fontSize: '18px',
          fontWeight: '600',
          padding: '12px 16px',
          borderBottom: '1px solid #e1e1e1',
        },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'calc(100vh - 200px)',
          borderRadius: window.innerWidth <= 768 ? 0 : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: 'auto',
          flex: 1,
        },
      }}
      footer={
        <div className={styles.footer}>
          <Button key='cancel' onClick={onClose}>
            <FormattedMessage id='settings_cancel' />
          </Button>
          <Button
            key='save'
            type='primary'
            onClick={handleSave}
            style={{ backgroundColor: '#404d56' }}
          >
            <FormattedMessage id='auth_save' />
          </Button>
        </div>
      }
    >
      <div className={styles.cropperWrapper}>
        <Cropper
          image={imageItem.url}
          crop={crop}
          aspect={1}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          zoomWithScroll={true}
          showGrid={false}
          objectFit='contain'
          style={{
            containerStyle: { width: '100%' },
            mediaStyle: { width: '100%', objectFit: 'contain' },
            cropAreaStyle: { borderRadius: '50%', height: '100%', width: '100%' },
          }}
        />
      </div>
    </Modal>
  );
};

export default EditAvatarModal;
