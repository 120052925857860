import { UserOutlined } from '@ant-design/icons';
import Editor from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import '@draft-js-plugins/emoji/lib/plugin.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import { Avatar } from 'antd';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import mentionsStyles from './Editor.module.css';

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
const linkifyPlugin = createLinkifyPlugin();
const mentionPlugin = createMentionPlugin({
  entityMutability: 'IMMUTABLE',
  theme: mentionsStyles,
  mentionPrefix: '@',
  supportWhitespace: true,
  mentionComponent: (mentionProps) => (
    <a
      href={`/member/${mentionProps.mention.link}`}
      target='_blank'
      rel='noopener noreferrer'
      style={{ color: '#0475f6', textDecoration: 'none' }}
    >
      {mentionProps.mention.name}
    </a>
  ),
});
const { MentionSuggestions } = mentionPlugin;

const plugins = [emojiPlugin, linkifyPlugin, mentionPlugin];

const MyEditor = ({ setText, mentions, value, user, setMentionList, mentionList }) => {
  const [mentionSuggestions, setMentionSuggestions] = useState(mentions);
  const [open, setOpen] = useState(false);

  const createEditorStateFromHTML = (html) => {
    if (!html) return EditorState.createEmpty();

    const mentionRegex = /<a href="\/(member|deceased)\/(.*?)".*?>(.*?)<\/a>/g;
    const processedHtml = html.replace(mentionRegex, (_, type, slug, name) => `@${name}`);

    const blocksFromHTML = convertFromHTML(processedHtml);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );

    return EditorState.createWithContent(contentState);
  };

  const [editorState, setEditorState] = useState(() => createEditorStateFromHTML(value || ''));

  const convertLinksToAnchors = (htmlContent) => {
    const urlRegex = /(?:https?:\/\/|www\.)[^\s]+/g;
    return htmlContent.replace(urlRegex, (url) => {
      const formattedUrl = url.startsWith('www.') ? `https://${url}` : url;
      return `<a href="${formattedUrl}" target="_blank">${url}</a>`;
    });
  };

  const convertMentionsToLinks = (htmlContent) => {
    if (!htmlContent.includes('@')) return htmlContent;
    let mentionIndex = 0;

    return htmlContent.replace(/@([\w]+(?:\s[\w]+)?)/g, (match) => {
      if (mentionIndex >= mentionList.length) return match;

      const mention = mentionList[mentionIndex];
      mentionIndex++;

      if (mention) {
        return `<a href="${mention.link}" target="_blank">${mention.name}</a>`;
      }
      return match;
    });
  };

  const handleChange = (newState) => {
    setEditorState(newState);

    let content = newState.getCurrentContent();
    let htmlContent = stateToHTML(content);

    if (!content.hasText()) {
      htmlContent = '';
    } else {
      htmlContent = convertLinksToAnchors(htmlContent);
      htmlContent = convertMentionsToLinks(htmlContent);
    }

    setText(htmlContent);
  };

  const onSearchChange = ({ value }) => {
    const filtered = mentions.filter((m) => m.name.toLowerCase().includes(value.toLowerCase()));
    setMentionSuggestions(filtered);
  };

  const Entry = (props) => {
    const { mention, theme, ...parentProps } = props;
    return (
      <div {...parentProps}>
        <div className={theme?.mentionSuggestionsEntryContainer}>
          <div className={theme?.mentionSuggestionsEntryContainerLeft}>
            <Avatar size={40} src={mention.avatar} icon={<UserOutlined />} />
          </div>
          <div className={theme?.mentionSuggestionsEntryContainerRight}>
            <div className={theme?.mentionSuggestionsEntryText}>{mention.name}</div>
            <div className={theme?.mentionSuggestionsEntryTitle}>{mention.relation}</div>
          </div>
        </div>
      </div>
    );
  };

  const onMentionSelect = (mention) => {
    setMentionList((prev) => {
      const isAlreadyMentioned = prev.some((m) => m.id === mention.id);

      if (isAlreadyMentioned) {
        return prev;
      }

      return [...prev, mention];
    });
  };

  return (
    <div style={{ border: '1px solid #ddd', padding: 10, borderRadius: 5 }}>
      <Editor
        editorState={editorState}
        onChange={handleChange}
        plugins={plugins}
        placeholder={
          <FormattedMessage
            id='memory_description_placeholder'
            values={{ name: user.user.full_name }}
          />
        }
      />

      <MentionSuggestions
        open={open}
        onOpenChange={setOpen}
        suggestions={mentionSuggestions}
        onSearchChange={onSearchChange}
        onAddMention={onMentionSelect}
        entryComponent={Entry}
        popoverContainer={({ children }) => <div>{children}</div>}
      />

      <EmojiSuggestions />
      <div className={mentionsStyles.emojiPicker}>
        <EmojiSelect />
      </div>
    </div>
  );
};

export default MyEditor;
