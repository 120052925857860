import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './MemoryCard.module.css';

const MemoryGallery = ({ media }) => {
  const [, setSearchParams] = useSearchParams();
  const mediaCount = media.length;
  const maxDisplay = 5; // Show max 5 items
  const showMoreCount = mediaCount > maxDisplay ? `+${mediaCount - maxDisplay}` : null;

  return (
    <div className={styles.mediaContainer} data-count={Math.min(mediaCount, maxDisplay)}>
      {media.slice(0, maxDisplay).map((item, index) => {
        const isLastItem = index === maxDisplay - 1 && showMoreCount;
        return (
          <div
            key={`content-${index}`}
            className={`${styles.mediaItem} ${isLastItem ? styles.more : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setSearchParams({ memory: item.memory_id, id: item.id });
            }}
          >
            {item.category === 'image' ? (
              <img src={item.file_url} alt='Memory' />
            ) : (
              <div className={styles.videoWrapper}>
                <video src={item.file_url} muted playsInline controls />
                <div
                  className={styles.videoOverlay}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSearchParams({ memory: item.memory_id, id: item.id });
                  }}
                />
              </div>
            )}
            {isLastItem && <div className={styles.overlay}>{showMoreCount}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default MemoryGallery;
