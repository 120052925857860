import { Button, Modal } from 'antd';
import 'cropperjs/dist/cropper.css';
import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { FormattedMessage } from 'react-intl';
import styles from './Event.module.css';

const ConfirmDiscardModal = ({ visible, onDiscard, onCancel }) => (
  <Modal
    title={<FormattedMessage id='discard_changes' />}
    open={visible}
    onCancel={onCancel}
    onOk={onDiscard}
    okType='danger'
    okText={<FormattedMessage id='discard' />}
    cancelText={<FormattedMessage id='settings_cancel' />}
  >
    <FormattedMessage id='memory_discard_message' />
  </Modal>
);

const EditImageModal = ({ visible, onClose, cropperImage, onSave }) => {
  const [confirmDiscardOpen, setConfirmDiscardOpen] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);

  const handleCancel = () => {
    setConfirmDiscardOpen(true);
  };

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight(); // Set initial height

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      if (visible) {
        event.preventDefault();
        handleCancel(); // Close the modal instead of going back
      }
    };

    if (visible) {
      window.history.pushState(null, '', window.location.href); // Add a fake history entry
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [visible, handleCancel]);

  const handleDiscard = () => {
    setConfirmDiscardOpen(false);
    onClose();
  };

  const getCroppedImage = async () => {
    if (!cropperImage || !croppedAreaPixels) return null;

    try {
      const images = await cropImage(cropperImage, croppedAreaPixels);

      return images;
    } catch (error) {
      console.error('Cropping error:', error);
      return null;
    }
  };

  // 🔧 Fixes for Cropping Function
  const cropImage = async (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.src = imageSrc;
      image.crossOrigin = 'anonymous';

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );

        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error('Canvas to Blob conversion failed'));
          },
          'image/jpeg',
          0.95, // High quality
        );
      };

      image.onerror = (err) => reject(err);
    });
  };

  const handleSave = async () => {
    const croppedImageBlob = await getCroppedImage();
    onSave(croppedImageBlob);
  };

  return (
    <Modal
      title={<FormattedMessage id='edit_image' />}
      open={visible}
      onCancel={handleCancel}
      maskClosable={false}
      keyboard={false}
      width={window.innerWidth <= 768 ? '100%' : 800}
      style={{
        top: window.innerWidth <= 768 ? 0 : '10%',
        margin: window.innerWidth <= 768 ? 0 : 'auto',
        maxWidth: window.innerWidth <= 768 ? '100%' : 'calc(100% - 40px)',
      }}
      styles={{
        header: {
          color: '#333',
          fontSize: '18px',
          fontWeight: '600',
          padding: '12px 16px',
          borderBottom: '1px solid #e1e1e1',
        },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'calc(100vh - 200px)',
          borderRadius: window.innerWidth <= 768 ? 0 : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: 'auto',
          flex: 1,
        },
      }}
      footer={
        <div className={styles.footer}>
          <Button onClick={handleCancel}>
            <FormattedMessage id='settings_cancel' />
          </Button>
          <Button type='primary' onClick={handleSave} style={{ backgroundColor: '#404d56' }}>
            <FormattedMessage id='auth_save' />
          </Button>
        </div>
      }
    >
      <div className={styles.cropperWrapper}>
        <Cropper
          image={cropperImage}
          crop={crop}
          aspect={1}
          zoom={zoom}
          onZoomChange={setZoom}
          onCropChange={setCrop}
          zoomWithScroll={true}
          objectFit='contain'
          showGrid={false}
          onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
          style={{
            containerStyle: { width: '100%' },
            mediaStyle: { width: '100%', objectFit: 'contain' },
          }}
        />
      </div>
      <ConfirmDiscardModal
        visible={confirmDiscardOpen}
        onDiscard={handleDiscard}
        onCancel={() => setConfirmDiscardOpen(false)}
      />
    </Modal>
  );
};

export default EditImageModal;
