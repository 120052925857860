import { LoadingOutlined } from '@ant-design/icons';
import { Button, Empty, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import EmptyImage from '../../img/Family.svg';
import { addMemory, setSubscription } from '../../redux/actions';
import { request } from '../../service/request';
import CreateMemorySection from './Components/CreateMemorySection/CreateMemorySection';
import PostModal from './Components/CreatePostModal/PostModal';
import MemoryCard from './Components/MemoryCard/MemoryCard';
import styles from './NewMemoryWall.module.css';

const NewMemoryWall = ({ grave, user, isGraveUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const canAddMemory = user.user.is_subscribed;
  const memories = useSelector((state) => state.grave.memories);

  const fetchGraveMemories = async () => {
    if (!grave.id) return;
    setLoading(true);
    try {
      const newData = await request(`/memories?memory[grave_id]=${grave.id}`);
      if (newData.data.memories.length > 0) {
        dispatch(addMemory(newData.data.memories));
        dispatch(setSubscription(newData.data.subscription));
      } else {
        dispatch(addMemory([]));
        dispatch(setSubscription(newData.data.subscription));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGraveMemories();
  }, [grave.id]);

  const handleOpenModal = () => {
    if (!canAddMemory) {
      showUserNotification(intl.formatMessage({ id: 'need_subscription' }), 'warning');
      return;
    }
    setOpenModal(true);
  };

  return (
    <div style={{ flex: 1 }}>
      {/* Memory Creation Section */}
      {isGraveUser && (
        <>
          {canAddMemory ? (
            <CreateMemorySection
              user={user}
              setOpenModal={handleOpenModal}
              graveName={grave.name}
            />
          ) : (
            <div className={styles.emptyMemory}>
              <p>
                <FormattedMessage id='need_subscription' />
              </p>
              <Button
                type='primary'
                onClick={() => navigate('/pricing')}
                style={{ backgroundColor: '#404d56' }}
              >
                <FormattedMessage id='upgrade_subscription' />
              </Button>
            </div>
          )}
        </>
      )}

      {/* Memories List */}
      <div>
        {loading ? (
          <div className={styles.loading}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </div>
        ) : memories.length > 0 ? (
          memories.map((memory) => (
            <MemoryCard key={memory.id} memory={memory} user={user} grave={grave} />
          ))
        ) : (
          <div className={styles.emptyMemory}>
            <Empty
              image={EmptyImage}
              description={
                <FormattedMessage id='no_memories_added' values={{ graveName: grave.name }} />
              }
              style={{ marginTop: '20px' }}
            />
          </div>
        )}
      </div>

      {/* Post Modal */}
      {openModal && (
        <PostModal
          user={user}
          visible={openModal}
          onClose={() => setOpenModal(false)}
          grave={grave}
        />
      )}
    </div>
  );
};

export default NewMemoryWall;
