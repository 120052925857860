import InterestsIcon from '@mui/icons-material/Interests';
import { Avatar, Typography } from 'antd';
import React from 'react';
import styles from './Interests.module.css';

const { Title, Text } = Typography;

const InterestCard = ({ interest }) => {
  if (!interest) return null;

  return (
    <div className={styles.interestHeader}>
      <Avatar
        size={50}
        icon={<InterestsIcon style={{ fontSize: 30, color: '#404d56' }} />}
        style={{ backgroundColor: '#f2f4f7' }}
      />
      <div className={styles.interestInfo}>
        <Title level={5} style={{ margin: 0 }}>
          {interest?.name}
        </Title>
        <Text style={{ display: 'block' }} type='secondary'>
          {interest.captivated_interest?.interest_type}
        </Text>
        <Text style={{ display: 'block' }} type='secondary'>
          {interest.description}
        </Text>
      </div>
    </div>
  );
};

export default InterestCard;
