import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CardHeader.module.css';

const CardHeader = ({ titleKey, setOpenForm, isPublic, isGraveUser }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['heading']}>
        <FormattedMessage id={titleKey} />
      </div>
      {!isPublic && isGraveUser && (
        <Button
          type='primary'
          onClick={() => setOpenForm(true)}
          icon={<PlusOutlined style={{ color: '#fff' }} />}
          style={{
            background: 'rgb(64, 77, 86)',
          }}
        >
          <FormattedMessage id='add_dates' />
        </Button>
      )}
    </div>
  );
};

export default CardHeader;
