import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo } from 'react';

const SearchInput = ({ filterItems, placeholder }) => {
  const onSearch = useCallback(
    (value) => {
      filterItems(value);
    },
    [filterItems],
  );

  const debouncedChangeHandler = useMemo(() => debounce(onSearch, 300), [onSearch]);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  return (
    <Input
      prefix={<SearchOutlined />}
      style={{ maxWidth: 200, backgroundColor: '#f2f4f7' }}
      placeholder={placeholder}
      variant='filled'
      allowClear
      onChange={(e) => debouncedChangeHandler(e.target.value)}
      onSearch={onSearch}
    />
  );
};

export default SearchInput;
