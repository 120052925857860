import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import AuthModal from '../../components/Modals/AuthModal';
import {
  getListOfCountries,
  goToChosenGrave,
  setInformationAboutUser,
  setRelationDirections,
  setRelations,
  setSubscription,
  spinnerStart,
} from '../../redux/actions';
import { CONSTANTS } from '../../service/constants';
import { fetch_request, request } from '../../service/request';
import ConnectRequest from '../ConnectRequest/ConnectRequest';
import AcceptedUsers from './AcceptedUsers';
import styles from './NewDeceased.module.css';

const NewDeceasedPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const grave = useSelector((state) => state.grave.grave);
  const userSlug = localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const isClaimed = grave.grave_status === CONSTANTS.grave.claimed;

  let { slug } = useParams();

  const fetchGrave = async () => {
    try {
      if (slug) {
        const response = await request(`/graves/${slug}`, null, 'get');
        dispatch(goToChosenGrave(response.data));
      }
    } catch (err) {
      console.log(err);
      err.message === 'Request failed with status code 500' &&
        console.log('document.location.reload()');
    }
  };

  const fetchUserData = () => {
    if (userSlug) {
      try {
        request(`/users/${userSlug}`).then((res) => {
          dispatch(setInformationAboutUser(res.data));
          dispatch(setSubscription(res.data.user.subscription));
          setUser(res.data.user);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
  };

  useEffect(() => {
    fetch_request('/api/countries', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        dispatch(setRelationDirections(res.data?.list || []));
      })
      .catch((error) => console.log(error));
  }, []);

  // Get Relations
  useEffect(() => {
    request('/relations')
      .then((res) => dispatch(setRelations(res.data)))
      .catch((err) => console.log(err));
  }, []);

  const fetchAllData = async () => {
    dispatch(spinnerStart());
    try {
      await Promise.all([fetchUserData(), fetchGrave()]);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuth) {
      setIsLoading(true);
      fetchAllData();
    }
  }, [slug, isAuth]);

  if (!isAuth) {
    return <AuthModal isOpen={true} handleClose={() => navigate('/')} />;
  }

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 500,
        }}
      >
        <Loader />
      </div>
    );

  const isAcceptedUser = () => {
    return (
      user &&
      user.is_subscribed &&
      grave &&
      grave.current_user_relation &&
      (grave.current_user_relation.status === 'accepted' ||
        grave.current_user_relation.status === 'accepted_without_review')
    );
  };

  return (
    <div className={styles['deceased-page-container']}>
      {isAcceptedUser() ? (
        <AcceptedUsers
          grave={grave}
          user={user}
          isClaimed={isClaimed}
          isAuth={isAuth}
          isAcceptedUser={isAcceptedUser}
        />
      ) : (
        <ConnectRequest
          user={user}
          grave={grave}
          isAuth={isAuth}
          isClaimed={isClaimed}
          isAcceptedUser={isAcceptedUser}
        />
      )}
    </div>
  );
};

export default NewDeceasedPage;
