import { Avatar } from 'antd';
import React from 'react';
import { FaUserLarge } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import styles from './Relations.module.css';

const Relations = (props) => {
  const navigate = useNavigate();
  const { relationsArray, grave, isAcceptedUser } = props;

  return (
    <div className={styles['relations-section']}>
      <span
        className={styles['relation-container']}
        onClick={() => {
          if (isAcceptedUser()) navigate(`/deceased/${grave.slug}/?tab=relationships`);
        }}
      >
        <Avatar.Group
          max={{
            count: 8,
            style: {
              background: 'rgb(242, 244, 247)',
              cursor: 'pointer',
              border: '1px solid #404d56',
            },
          }}
        >
          {relationsArray.length > 0 &&
            relationsArray.map((item, index) => {
              return (
                <Avatar
                  alt=''
                  src={item.user?.user_avatar}
                  key={index}
                  icon={<FaUserLarge style={{ color: '#404d56' }} />}
                  style={{
                    cursor: 'pointer',
                    border: '1px solid #404d56',
                    background: 'rgb(242, 244, 247)',
                  }}
                />
              );
            })}
        </Avatar.Group>
      </span>
    </div>
  );
};

export default Relations;
