import { Avatar } from '@mui/material';
import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CommentFooter from '../CommentFooter/CommentFooter';
import CommentInput from '../CommentInput/CommentInput';
import ActionButton from './ActionButton';
import styles from './Comment.module.css';

const Comment = ({
  spark,
  isReply = false,
  comment,
  handelDeleteComment,
  handelEditComment,
  handelCreateComment,
}) => {
  const user = useSelector((state) => state.user.user);
  const [editComment, setEditComment] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showReplies, setShowReplies] = useState(false);

  const onClickEdit = () => {
    setEditComment(true);
  };

  const onCloseEdit = () => {
    setEditComment(false);
  };

  const onOpenReplyInput = () => {
    setShowReplyInput(true);
  };

  const onCloseReplyInput = () => {
    setShowReplyInput(false);
  };

  const showAllReplies = () => {
    setShowReplies(true);
  };

  const onClickDelete = (comment) => {
    handelDeleteComment(comment);
  };

  const saveEditComment = (description) => {
    handelEditComment(description, comment);
    setTimeout(() => onCloseEdit(), 300);
  };

  return editComment ? (
    <CommentInput
      description={comment.description}
      isEdit={editComment}
      saveEditComment={saveEditComment}
      onCloseEdit={onCloseEdit}
    />
  ) : (
    <div className={styles['container']}>
      <Avatar
        src={comment?.user.displayPicture}
        className={!isReply ? styles['avatar'] : styles['reply-avatar']}
      />
      <div className={styles['comment-container']}>
        <div className={styles['comment-outline']}>
          <div className={styles['comment']}>
            {comment?.user && (
              <div className={styles['user-info']}>
                <div className={styles['user-name']}>{comment?.user?.fullName}</div>
                {user && comment?.user?.id === user?.id.toString() && (
                  <ActionButton
                    comment={comment}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                  />
                )}
              </div>
            )}
            <div
              className={styles['description']}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(comment?.description),
              }}
            />
          </div>
        </div>
        <CommentFooter comment={comment} onOpenReplyInput={onOpenReplyInput} />
        {/* {!showReplies && comment?.repliesCount > 0 && (
          <TotalReplies
            totalReplies={comment?.repliesCount}
            showAllReplies={showAllReplies}
          />
        )}
        {showReplies && (
          <Replies
            spark={spark}
            comment={comment}
            handelDeleteComment={handelDeleteComment}
            handelEditComment={handelEditComment}
            handelCreateComment={handelCreateComment}
          />
        )} */}
        {/* {showReplyInput && (
          <CommentInput
            spark={spark}
            parentId={comment?.id}
            handelCreateComment={handelCreateComment}
          />
        )} */}
      </div>
    </div>
  );
};

export default Comment;
