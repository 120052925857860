import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import AllComments from './AllComments';
import CommentArea from './CommentArea';
import CommentFooter from './CommentFooter';
import styles from './GravePhotos.module.css';
import ImageCarousel from './ImageCarousel';

const PhotoDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const grave = useSelector((state) => state.grave.grave);
  const graveUsers = useSelector((state) => state.grave.grave?.current_user_relation);

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [images, setImages] = useState(grave?.grave_images || []);

  const selectedImageId = searchParams.get('id');

  useEffect(() => {
    if (grave?.grave_images) {
      setImages(grave.grave_images);
    }
  }, [grave]);

  useEffect(() => {
    if (selectedImageId && images.length > 0) {
      const selectedIndex = images.findIndex((img) => img.id === Number(selectedImageId));
      setCurrentIndex(selectedIndex >= 0 ? selectedIndex : 0);
    } else {
      setSearchParams({ tab: 'about_photos' });
    }
  }, [selectedImageId, images, setSearchParams]);

  useEffect(() => {
    const updateHeight = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;

      const isTyping =
        activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA' ||
        activeElement?.isContentEditable;

      if (!isTyping) {
        if (event.key === 'ArrowRight') nextMedia();
        if (event.key === 'ArrowLeft') prevMedia();
        if (event.key === 'Escape') {
          event.stopPropagation();
          event.preventDefault();
          closeModal();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, images, setSearchParams]);

  const updateSearchParams = (newIndex) => {
    if (images.length > 0 && images[newIndex]) {
      setSearchParams({ tab: 'photos', id: images[newIndex].id });
    }
  };

  const prevMedia = () => {
    if (images.length === 0) return;
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? images.length - 1 : prevIndex - 1;
      updateSearchParams(newIndex);
      return newIndex;
    });
  };

  const nextMedia = () => {
    if (images.length === 0) return;
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      updateSearchParams(newIndex);
      return newIndex;
    });
  };

  const closeModal = () => setSearchParams({ tab: 'about_photos' });
  return (
    <Modal
      open={true}
      onCancel={closeModal}
      width={'100%'}
      closeIcon={null}
      maskClosable={false}
      keyboard={false}
      style={{
        top: 0,
        margin: 0,
        maxWidth: '100%',
      }}
      styles={{
        header: { display: 'none' },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          height: `${viewportHeight}px`,
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: window.innerWidth > 768 ? 'hidden' : 'auto',
          flex: 1,
        },
      }}
      footer={null}
    >
      <>
        <Button
          type='primary'
          shape='circle'
          icon={<CloseOutlined style={{ fontSize: '18px' }} />}
          className={styles.closeIcon}
          onClick={closeModal}
        />

        <div className={styles.modalContainer}>
          <ImageCarousel
            images={images}
            currentIndex={currentIndex}
            onPrev={prevMedia}
            onNext={nextMedia}
          />
          <div className={styles.border} />
          {images[currentIndex] && (
            <div className={styles.commentArea}>
              <CommentArea
                image={images[currentIndex]}
                grave={grave}
                images={images}
                setImages={setImages}
              />
              {images[currentIndex]?.image_comments &&
                images[currentIndex].image_comments.length > 0 && (
                  <AllComments
                    comments={images[currentIndex].image_comments}
                    image={images[currentIndex]}
                    images={images}
                    setImages={setImages}
                    grave={grave}
                    graveUsers={graveUsers}
                  />
                )}
              <CommentFooter
                image={images[currentIndex]}
                images={images}
                setImages={setImages}
                user={graveUsers}
              />
            </div>
          )}
        </div>
      </>
    </Modal>
  );
};

export default PhotoDetails;
