import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Event from '../Components/Event/Event';
import styles from '../LifeEventDetails.module.css';

const LifeEventSlider = ({ events }) => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const selectedEventId = searchParams.get('event_id');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (selectedEventId && events.length > 0) {
      const foundIndex = events.findIndex((event) => event.id === Number(selectedEventId));
      if (foundIndex !== -1) {
        setCurrentIndex(foundIndex);
      }
    }
  }, [selectedEventId, events]);

  const slidesToShowCount = Math.min(events.length, 6);

  const settings = {
    dots: false,
    infinite: events.length > slidesToShowCount,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    variableWidth: true,
  };

  return (
    <>
      <div className={styles.moreEvents}>
        <FormattedMessage id='view_more_events' />
      </div>
      <Slider {...settings} initialSlide={currentIndex}>
        {events.map((event) => (
          <div key={event.id} style={{ padding: '10px' }}>
            <div style={{ background: '#fff', padding: '5px', borderRadius: '10px' }}>
              <Event event={event} />
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default LifeEventSlider;
