import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { setGraveUsersList } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import GraveUser from './GraveUser';
import PopoverMenu from './PopoverMenu';
import RelationshipForm from './RelationshipForm';
import styles from './Relationships.module.css';

const GraveUserCard = ({
  user,
  relations,
  relationDirections,
  grave,
  loggedUser,
  currentGraveUser,
}) => {
  const dispatch = useDispatch();
  const [openEditForm, setOpenEditForm] = useState(false);

  const onRemoveUser = (id) => {
    if (id) {
      request(`/grave_users/${id}`, null, 'delete')
        .then(() => {
          const updatedUsers = grave.grave_users.filter((graveUser) => graveUser.id !== id);
          dispatch(setGraveUsersList(updatedUsers));
        })
        .catch((err) => {
          console.log(err);
          showUserNotification(err.response.data.error || err.message, 'warning');
        });
    }
  };

  const updateUser = async (values, id) => {
    if (!id) return;

    try {
      const payload = {
        grave_user: {
          admin: values.role === 'admin' ? true : false,
          relation_id: values.relation,
          reciprocal_relation_id: values.relationDirection,
          grave_id: grave.id,
        },
      };

      const res = await request(`/grave_users/${id}`, payload, 'put');

      if (res?.data) {
        const updatedUsers = grave.grave_users.map((graveUser) =>
          graveUser.id === id ? res.data : graveUser,
        );

        dispatch(setGraveUsersList(updatedUsers));
        setOpenEditForm(false);
      }
    } catch (err) {
      showUserNotification(err?.response?.data?.error || err.message, 'warning');
    }
  };

  return (
    <div className={styles.card}>
      {openEditForm ? (
        <RelationshipForm
          userData={user}
          relations={relations}
          relationDirections={relationDirections}
          onSubmit={updateUser}
          onCancel={() => setOpenEditForm(false)}
        />
      ) : (
        <>
          <GraveUser user={user} />
          {(currentGraveUser?.admin || currentGraveUser?.grave_owner) &&
            loggedUser.is_subscribed && (
              <PopoverMenu
                user={user}
                onEditRelation={() => setOpenEditForm(true)}
                onRemoveUser={onRemoveUser}
              />
            )}
        </>
      )}
    </div>
  );
};

export default GraveUserCard;
