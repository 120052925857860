import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import styles from './MemoryCard.module.css';

const ExpandableText = ({ descriptionContent, maxWords = 20 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const sanitizedText = DOMPurify.sanitize(descriptionContent);
  const words = sanitizedText.split(' ');

  const shouldTruncate = words.length > maxWords;
  const truncatedText = shouldTruncate ? words.slice(0, maxWords).join(' ') : sanitizedText;

  return (
    <div className={styles.description}>
      <p dangerouslySetInnerHTML={{ __html: isExpanded ? sanitizedText : truncatedText }} />
      {shouldTruncate && (
        <button
          className={styles.seeMoreButton}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'See less' : '...See more'}
        </button>
      )}
    </div>
  );
};

export default ExpandableText;
