import React from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Lock from '../../../../img/lock.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './NewCard.module.css';

const cardNumberStyle = {
  iconColor: '#c4f0ff',
  color: '#777777',
  fontWeight: '500',
  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
  fontSize: '16px',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: '#fce883',
  },
  '::placeholder': {
    color: '#777777',
  },
};

const CardInputs = ({
  nameOnCard,
  setCardInputs,
  cardInputs,
  setNameOnCard,
}) => {
  const intl = useIntl();

  const onNameChange = ({ target }) => {
    const inputValue = target.value;
    const trimmedValue = inputValue
      .replace(/[0-9]/g, '')
      .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, '');
    setNameOnCard(trimmedValue);
  };

  return (
    <div className={styles['card-payment']}>
      <form className={styles['payment-form']}>
        <div className={styles['card-input-details']}>
          <span className={styles['card-input-title']}>
            <FormattedMessage id='name_on_card' />
          </span>
          <input
            type='text'
            value={nameOnCard}
            placeholder={intl.formatMessage({ id: 'settings_name' })}
            className={styles['card-inputs']}
            onChange={onNameChange}
          />
        </div>
        <div className={styles['card-input-details']}>
          <span className={styles['card-input-title']}>
            <FormattedMessage id='settings_cardNumber' />
          </span>
          <CardNumberElement
            onChange={(event) => {
              setCardInputs({
                ...cardInputs,
                cardNumber: event.complete,
              });
            }}
            options={{
              style: {
                base: cardNumberStyle,
              },
              placeholder: 'XXXX XXXX XXXXX',
            }}
            className={styles['card-inputs']}
          />
        </div>
        <div className={styles['card-details-wrapper']}>
          <div className={styles['card-input-details']}>
            <span className={styles['card-input-title']}>
              <FormattedMessage id='expiry_date' />
            </span>
            <CardExpiryElement
              onChange={(event) => {
                setCardInputs({
                  ...cardInputs,
                  Cvc: event.complete,
                });
              }}
              options={{
                style: {
                  base: cardNumberStyle,
                },
                placeholder: 'MM/YY',
              }}
              className={styles['card-inputs']}
            />
          </div>
          <div className={styles['card-input-cvc-details']}>
            <span className={styles['card-input-title']}>CVV</span>
            <CardCvcElement
              onChange={(event) => {
                setCardInputs({
                  ...cardInputs,
                  expiry: event.complete,
                });
              }}
              options={{
                style: {
                  base: cardNumberStyle,
                },
                placeholder: '----',
              }}
              className={styles['card-inputs']}
            />
          </div>
        </div>
      </form>
      <div className={styles['payment-security']}>
        <img src={Lock} alt='Lock' />
        <span>
          <FormattedMessage id='secure_payment_message' />
        </span>
      </div>
    </div>
  );
};

export default CardInputs;
