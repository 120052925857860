import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Relationships.module.css';

const InvitedUsers = ({ user }) => {
  return (
    <div className={styles.userCard}>
      {/* Avatar */}
      <Avatar
        src={user?.user?.user_avatar}
        size={50}
        style={{ border: '1px solid  #d8d8d8', cursor: 'pointer' }}
        icon={<UserOutlined />}
      />

      {/* User Info */}
      <div className={styles.userInfo}>
        <div className={styles.userHeader}>
          <div className={styles.userName}>{user?.user.full_name}</div>
        </div>
        <div className={styles.userRelation}>
          {user?.relation && (
            <span className={styles.relation}>
              {user.relation?.key ? (
                <FormattedMessage id={user.relation?.key} />
              ) : (
                user.relation?.name
              )}
            </span>
          )}
          {user.admin && (
            <span className={styles.relation}>
              (<FormattedMessage id='admin' />)
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvitedUsers;
