import { FireOutlined } from '@ant-design/icons';
import { Avatar, Typography } from 'antd';
import React from 'react';
import styles from './Passion.module.css';
const { Text } = Typography;

const PassionCard = ({ passion }) => {
  if (!passion) return null;

  return (
    <div className={styles.passionHeader}>
      <Avatar
        size={50}
        icon={<FireOutlined style={{ fontSize: 26, color: '#404d56' }} />}
        style={{ backgroundColor: '#f2f4f7' }}
      />
      <div className={styles.passionInfo}>
        <Text style={{ display: 'block' }} type='secondary'>
          {passion.comment}
        </Text>
      </div>
    </div>
  );
};

export default PassionCard;
