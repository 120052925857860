import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const { TextArea } = Input;

const { warning } = Modal;

const WorkForm = ({ workplace = null, onSubmit, onCancel }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const showDeleteConfirm = () => {
    warning({
      title: intl.formatMessage({ id: 'invalid_end_date' }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: 'invalid_end_date_message' }),
    });
  };

  useEffect(() => {
    if (workplace) {
      form.setFieldsValue({
        company_name: workplace.company_name,
        city: workplace.city,
        position: workplace.position,
        start_year: workplace.start_year ? dayjs(`${workplace.start_year}-01-01`) : null,
        end_year: workplace.end_year ? dayjs(`${workplace.end_year}-01-01`) : null,
        description: workplace.description,
      });
    }
  }, [workplace, form]);

  const handleFinish = async () => {
    try {
      const values = await form.validateFields();
      const startYear = values.start_year?.year();
      const endYear = values.end_year?.year();
      if (startYear > endYear) {
        showDeleteConfirm();
        return;
      }
      if (workplace) {
        onSubmit(values, workplace.id);
        onCancel();
      } else {
        onSubmit(values);
        onCancel();
      }
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  return (
    <Form form={form} layout='vertical' onFinish={handleFinish}>
      <Row gutter={[16, 0]}>
        {/* Company Name */}
        <Col xs={24} sm={12}>
          <Form.Item
            label={intl.formatMessage({ id: 'company_name_label' })}
            name='company_name'
            rules={[
              { required: true, message: intl.formatMessage({ id: 'company_name_required' }) },
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: 'company_name_placeholder' })} />
          </Form.Item>
        </Col>

        {/* Position */}
        <Col xs={24} sm={12}>
          <Form.Item
            label={intl.formatMessage({ id: 'position_label' })}
            name='position'
            rules={[{ required: true, message: intl.formatMessage({ id: 'position_required' }) }]}
          >
            <Input placeholder={intl.formatMessage({ id: 'position_placeholder' })} />
          </Form.Item>
        </Col>

        {/* Start Year */}
        <Col xs={24} sm={12}>
          <Form.Item
            label={intl.formatMessage({ id: 'start_year_label' })}
            name='start_year'
            rules={[{ required: true, message: intl.formatMessage({ id: 'start_year_required' }) }]}
          >
            <DatePicker
              picker='year'
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({ id: 'start_year_placeholder' })}
              disabledDate={(current) => current && current > dayjs().endOf('year')}
            />
          </Form.Item>
        </Col>

        {/* End Year */}
        <Col xs={24} sm={12}>
          <Form.Item
            label={intl.formatMessage({ id: 'end_year_label' })}
            name='end_year'
            rules={[{ required: true, message: intl.formatMessage({ id: 'end_year_required' }) }]}
          >
            <DatePicker
              disabledDate={(current) => current && current > dayjs().endOf('year')}
              picker='year'
              style={{ width: '100%' }}
              placeholder={intl.formatMessage({ id: 'end_year_placeholder' })}
            />
          </Form.Item>
        </Col>

        {/* City */}
        <Col xs={24} sm={12}>
          <Form.Item
            label={intl.formatMessage({ id: 'city_label' })}
            name='city'
            rules={[{ required: true, message: intl.formatMessage({ id: 'city_required' }) }]}
          >
            <Input placeholder={intl.formatMessage({ id: 'city_placeholder' })} />
          </Form.Item>
        </Col>

        {/* Description */}
        <Col xs={24}>
          <Form.Item label={intl.formatMessage({ id: 'description_label' })} name='description'>
            <TextArea
              rows={3}
              placeholder={intl.formatMessage({ id: 'description_placeholder' })}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Buttons */}
      <Col xs={24}>
        <Form.Item>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            <FormattedMessage id='settings_cancel' />
          </Button>
          <Button type='primary' onClick={handleFinish} style={{ backgroundColor: '#404d56' }}>
            <FormattedMessage id='auth_save' />
          </Button>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default WorkForm;
