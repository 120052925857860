import { ArrowLeftOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Image as AntdImage,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
} from 'antd';
import 'cropperjs/dist/cropper.css';
import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification';
import useNavigationWarning from '../../../../../../hooks/NavigationAlert';
import { setGraveImages } from '../../../../../../redux/actions';
import { request } from '../../../../../../service/request';
import styles from './GravePhotos.module.css';
import PopoverMenu from './PopoverMenu';

const { TextArea } = Input;

const privacyOptions = [
  { value: 'private', label: <FormattedMessage id='private' /> },
  { value: 'public', label: <FormattedMessage id='public' /> },
  { value: 'relatives', label: <FormattedMessage id='relatives' /> },
];

const ConfirmDiscardModal = ({ visible, onDiscard, onCancel }) => (
  <Modal
    title={<FormattedMessage id='discard_changes' />}
    open={visible}
    onCancel={onCancel}
    onOk={onDiscard}
    okType='danger'
    okText={<FormattedMessage id='discard' />}
    cancelText={<FormattedMessage id='settings_cancel' />}
  >
    <FormattedMessage id='memory_discard_message' />
  </Modal>
);

const UploadPhotoModal = ({ openModal, setOpenModal, filesList, setFilesList, grave }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [isCropping, setIsCropping] = useState(false);
  const [cropperImage, setCropperImage] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [confirmDiscardOpen, setConfirmDiscardOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useNavigationWarning(isUploading);

  useEffect(() => {
    const updateHeight = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const handleRemove = (index) => {
    setFilesList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleUpload = ({ fileList }) => {
    const uniqueFiles = fileList.map((file) => ({
      file,
      preview: URL.createObjectURL(file.originFileObj),
      description: '',
      privacy: 'relatives',
      address: '',
    }));

    setFilesList(uniqueFiles);
    setOpenModal(true);
  };

  const onEdit = (image, index) => {
    setCropperImage(image.preview);
    setEditingIndex(index);
    setIsCropping(true);
  };

  const onCrop = async () => {
    if (!cropperImage || !croppedAreaPixels) {
      console.error('No image or crop area selected');
      return;
    }

    try {
      const croppedBlob = await getCroppedImage();
      if (!croppedBlob) {
        console.error('Cropping error: Blob is null');
        return;
      }

      // Convert Blob to File
      const croppedFile = new File([croppedBlob], `cropped-image-${Date.now()}.jpg`, {
        type: 'image/jpeg',
      });

      setFilesList((prev) =>
        prev.map((item, i) =>
          i === editingIndex
            ? {
                ...item,
                file: croppedFile,
                preview: URL.createObjectURL(croppedBlob), // Update preview
              }
            : item,
        ),
      );

      setIsCropping(false);
      setCropperImage(null);
      setEditingIndex(null);
    } catch (error) {
      console.error('Error during cropping:', error);
    }
  };

  const getCroppedImage = async () => {
    if (!cropperImage || !croppedAreaPixels) return null;

    try {
      const images = await cropImage(cropperImage, croppedAreaPixels);

      return images;
    } catch (error) {
      console.error('Cropping error:', error);
      return null;
    }
  };

  // 🔧 Fixes for Cropping Function
  const cropImage = async (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.src = imageSrc;
      image.crossOrigin = 'anonymous';

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );

        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
            else reject(new Error('Canvas to Blob conversion failed'));
          },
          'image/jpeg',
          0.95, // High quality
        );
      };

      image.onerror = (err) => reject(err);
    });
  };

  const onClose = () => {
    setFilesList([]);
    setOpenModal(false);
    setIsCropping(false);
    setCropperImage(null);
    setEditingIndex(null);
  };

  const handleDiscard = () => {
    setConfirmDiscardOpen(false);
    onClose();
  };

  const handleCancel = () => {
    if (filesList.length > 0) {
      setConfirmDiscardOpen(true);
    } else {
      onClose();
      setConfirmDiscardOpen(false);
    }
  };

  const handleSubmit = async () => {
    if (filesList.length === 0) {
      showUserNotification(intl.formatMessage({ id: 'write_something_or_add_media' }), 'warning');
      return;
    }
    setIsUploading(true);

    try {
      const formData = new FormData();
      let images = [];

      for (const fileObj of filesList) {
        const file = fileObj.file?.originFileObj || fileObj.file;
        if (file) {
          const blob = new Blob([file], { type: file.type });
          formData.append('grave_image[file]', blob);
        }

        formData.append('grave_image[description]', fileObj.description || '');
        formData.append('grave_image[address]', fileObj.address || '');
        formData.append('grave_image[category]', 'display');
        formData.append('grave_image[privacy_attributes][setting]', fileObj.privacy || 'relatives');
        formData.append('grave_image[grave_id]', `${grave.id}`);

        const headers = { 'Content-Type': 'multipart/form-data' };

        const res = await request('/grave_images', formData, 'post', headers);
        images.push(res.data);
      }
      dispatch(setGraveImages([...images, ...grave.grave_images]));
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      onClose();
      setIsUploading(false);
    }
  };

  return (
    <>
      {isUploading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loading}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color: '#fff' }} spin />} />
            <div className={styles.title}>
              <FormattedMessage id='posting' />
            </div>
          </div>
        </div>
      )}
      <Modal
        open={openModal}
        onCancel={handleCancel}
        title={
          isCropping ? (
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                setIsCropping(false);
              }}
              type='text'
              shape='circle'
              style={{
                fontWeight: 'bold',
                background: '#f2f4f7',
                color: '#404d56',
              }}
            />
          ) : (
            <FormattedMessage id='upload_photos' />
          )
        }
        width={window.innerWidth <= 768 ? '100%' : 800}
        style={{
          top: window.innerWidth <= 768 ? 0 : '10%',
          margin: window.innerWidth <= 768 ? 0 : 'auto',
          maxWidth: window.innerWidth <= 768 ? '100%' : 'calc(100% - 40px)',
        }}
        styles={{
          header: {
            color: '#333',
            fontSize: '18px',
            fontWeight: '600',
            padding: '12px 16px',
            borderBottom: '1px solid #e1e1e1',
          },
          wrapper: {
            overflow: 'hidden',
          },
          content: {
            padding: 0,
            height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'calc(100vh - 200px)',
            borderRadius: window.innerWidth <= 768 ? 0 : '10px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          },
          body: {
            overflowY: 'auto',
            flex: 1,
          },
        }}
        footer={
          isCropping ? (
            <div className={styles.footer}>
              <Button onClick={() => setIsCropping(false)}>
                <FormattedMessage id='settings_cancel' />
              </Button>
              <Button type='primary' onClick={onCrop} style={{ backgroundColor: '#404d56' }}>
                <FormattedMessage id='auth_save' />
              </Button>
            </div>
          ) : (
            <div className={styles.footer}>
              <Button onClick={handleCancel}>
                <FormattedMessage id='settings_cancel' />
              </Button>
              <Button type='primary' onClick={handleSubmit} style={{ backgroundColor: '#404d56' }}>
                <FormattedMessage id='auth_save' />
              </Button>
            </div>
          )
        }
      >
        <div style={{ padding: '0px 16px' }}>
          {!isCropping ? (
            <>
              <Upload
                accept='image/*'
                beforeUpload={() => false}
                multiple
                fileList={filesList.map((item) => item.file)}
                onChange={handleUpload}
                showUploadList={false}
              >
                <Button
                  type='text'
                  style={{
                    marginTop: 12,
                    fontWeight: 'bold',
                    background: '#f2f4f7',
                    color: '#404d56',
                    fontSize: 14,
                  }}
                  icon={<UploadOutlined />}
                >
                  <FormattedMessage id='upload_photo' />
                </Button>
              </Upload>

              <Row gutter={[4, 4]} justify='start' style={{ marginTop: 12 }}>
                {filesList.map((item, index) => (
                  <Col key={index} xs={12} sm={8}>
                    <div className={styles.imageContainer} style={{ height: 200 }}>
                      <AntdImage
                        src={item.preview}
                        width='100%'
                        height='100%'
                        preview={false}
                        style={{ objectFit: 'cover', borderRadius: 4 }}
                      />
                      <PopoverMenu
                        image={item.preview}
                        onEdit={() => onEdit(item, index)}
                        onDelete={() => handleRemove(index)}
                      />
                    </div>
                    <Form
                      layout='vertical'
                      style={{ marginTop: 12 }}
                      initialValues={{
                        description: item.description,
                        address: item.address,
                        privacy: item.privacy,
                      }}
                      onValuesChange={(_changedValues, allValues) => {
                        setFilesList((prev) =>
                          prev.map((item, i) =>
                            i === index
                              ? {
                                  ...item,
                                  description: allValues.description,
                                  address: allValues.address,
                                  privacy: allValues.privacy,
                                }
                              : item,
                          ),
                        );
                      }}
                    >
                      <Form.Item
                        name='description'
                        label={
                          <FormattedMessage id='label_description' defaultMessage='Description' />
                        }
                      >
                        <TextArea
                          rows={3}
                          placeholder={intl.formatMessage({ id: 'label_description' })}
                        />
                      </Form.Item>

                      <Form.Item
                        name='address'
                        label={<FormattedMessage id='label_address' defaultMessage='Address' />}
                      >
                        <Input
                          placeholder={intl.formatMessage({
                            id: 'placeholder_address',
                            defaultMessage: 'Enter address',
                          })}
                        />
                      </Form.Item>

                      <Form.Item
                        name='privacy'
                        label={
                          <FormattedMessage id='about_privacy' defaultMessage='Privacy Settings' />
                        }
                      >
                        <Select
                          showSearch
                          optionFilterProp='label'
                          placeholder={intl.formatMessage({
                            id: 'placeholder_privacy',
                            defaultMessage: 'Select privacy option',
                          })}
                          options={privacyOptions.map((c) => ({ value: c.value, label: c.label }))}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <>
              <div className={styles.cropperWrapper}>
                <Cropper
                  image={cropperImage}
                  crop={crop}
                  aspect={1}
                  onCropChange={setCrop}
                  zoomWithScroll={false}
                  objectFit='contain'
                  showGrid={false}
                  onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
                  style={{
                    containerStyle: { width: '100%' },
                    mediaStyle: { width: '100%', objectFit: 'contain' },
                  }}
                />
              </div>
            </>
          )}
        </div>
        <ConfirmDiscardModal
          visible={confirmDiscardOpen}
          onDiscard={handleDiscard}
          onCancel={() => setConfirmDiscardOpen(false)}
        />
      </Modal>
    </>
  );
};

export default UploadPhotoModal;
