import { Button } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const CustomButton = styled(Button)(() => ({
  backgroundColor: '#404D56',
  color: '#fff',
  borderRadius: 6,
  padding: '4px 16px',
  width: 'max-content',
  textTransform: 'capitalize !important',
  '&:hover': {
    backgroundColor: '#36434B',
  },
  '&:disabled': {
    color: '#404D56',
    backgroundColor: '#eee',
    cursor: 'not-allowed',
  },
}));

const PrimaryButton = ({ onClick, children, style, disabled, type = 'button' }) => {
  return (
    <CustomButton type={type} onClick={onClick} style={style} disabled={disabled}>
      {children}
    </CustomButton>
  );
};

export default PrimaryButton;
