import { Button, message, Modal } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FacebookIcon, LinkedinIcon, TelegramIcon, WhatsappIcon, XIcon } from 'react-share';
import styles from './MemoryCard.module.css';

const ShareModal = ({ isOpen, onClose, memoryUrl }) => {
  const intl = useIntl();
  const [isCopied, setIsCopied] = useState(false);

  const openInAppOrWeb = (appUrl, webUrl) => {
    const timeout = setTimeout(() => {
      window.open(webUrl, '_blank');
    }, 800);

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = appUrl;
    document.body.appendChild(iframe);

    setTimeout(() => {
      document.body.removeChild(iframe);
      clearTimeout(timeout);
    }, 1000);
  };

  const handleWhatsAppShare = () => {
    openInAppOrWeb(
      `whatsapp://send?text=${encodeURIComponent(memoryUrl)}`,
      `https://web.whatsapp.com/send?text=${encodeURIComponent(memoryUrl)}`,
    );
  };

  const handleFacebookShare = () => {
    openInAppOrWeb(
      `fb://facewebmodal/f?href=${encodeURIComponent(memoryUrl)}`,
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(memoryUrl)}`,
    );
  };

  const handleTwitterShare = () => {
    openInAppOrWeb(
      `twitter://post?message=${encodeURIComponent(memoryUrl)}`,
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(memoryUrl)}`,
    );
  };

  const handleTelegramShare = () => {
    openInAppOrWeb(
      `tg://msg?text=${encodeURIComponent(memoryUrl)}`,
      `https://t.me/share/url?url=${encodeURIComponent(memoryUrl)}`,
    );
  };

  const handleLinkedInShare = () => {
    openInAppOrWeb(
      `linkedin://shareArticle?mini=true&url=${encodeURIComponent(memoryUrl)}`,
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(memoryUrl)}`,
    );
  };

  const handleCopy = () => {
    if (!isCopied) {
      navigator.clipboard
        .writeText(memoryUrl)
        .then(() => {
          message.success(intl.formatMessage({ id: 'linked_copied' }));
          setIsCopied(true);
        })
        .catch(() => {});
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title={<FormattedMessage id='share_memory' />}
    >
      <div className={styles.shareApps}>
        <Button
          type='text'
          shape='circle'
          style={{ width: 'auto', minWidth: 'auto' }}
          icon={<FacebookIcon size={50} round />}
          onClick={handleFacebookShare}
        />
        <Button
          type='text'
          shape='circle'
          style={{ width: 'auto', minWidth: 'auto' }}
          icon={<WhatsappIcon size={50} round />}
          onClick={handleWhatsAppShare}
        />
        <Button
          type='text'
          shape='circle'
          style={{ width: 'auto', minWidth: 'auto' }}
          icon={<XIcon size={50} round />}
          onClick={handleTwitterShare}
        />
        <Button
          type='text'
          shape='circle'
          style={{ width: 'auto', minWidth: 'auto' }}
          icon={<TelegramIcon size={50} round />}
          onClick={handleTelegramShare}
        />
        <Button
          type='text'
          shape='circle'
          style={{ width: 'auto', minWidth: 'auto' }}
          icon={<LinkedinIcon size={50} round />}
          onClick={handleLinkedInShare}
        />
      </div>
      <div className={styles.share}>
        <div className={styles.link}>{memoryUrl}</div>
        <Button
          type='primary'
          shape='round'
          style={{ background: 'rgb(64, 77, 86)' }}
          onClick={handleCopy}
        >
          <FormattedMessage id='copy_link' />
        </Button>
      </div>
    </Modal>
  );
};

export default ShareModal;
