import React from 'react';
import './Footer.css';
import MobileLogo from '../../img/LoGo_main.svg';
import Logo from '../../img/LoGo_main.svg';

const FooterLogo = () => {
  return (
    <div className='footer-logo-title-wrapper'>
      <div className='landing-footer_logo'>
        <img
          loading='lazy'
          src={Logo}
          alt='sad'
          className='landing-footer_logo-image'
        />
        <img
          loading='lazy'
          src={MobileLogo}
          alt='logo'
          className='landing-footer_logo-image-mobile'
        />
      </div>
      <span className='landing-footer_logo-header'>Nettgrav © 2025</span>
    </div>
  );
};

export default FooterLogo;
