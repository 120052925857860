import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Avatar } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import ProcessModal from '../../../containers/Payment/atoms/ProcessModal';
import { setInformationAboutUser } from '../../../redux/actions';
import { makeFileFromURL } from '../../../service/makeFileFromBlob';
import { request } from '../../../service/request';
import CropperModal from './CropperModal/CropperModal';
import styles from './UploadUserAvatar.module.css';

const UploadUserAvatar = ({ user, setCurrentUser }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [openProcessModal, setOpenProcessModal] = useState(false);
  const [image, setImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleFileChange = (event) => {
    if (!isUserCurrentUser()) return;
    event.preventDefault();
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage({ file, url: reader.result });
        setOpenModal(true);
        fileInput.value = '';
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = async (croppedImage) => {
    setOpenModal(false);
    setOpenProcessModal(true);
    const formData = new FormData();
    const newImage = await makeFileFromURL(croppedImage, image.file.name);
    formData.append('user[avatar]', newImage, image.file?.name);
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    try {
      const response = await request(`/users/${user.slug}`, formData, 'put', headers);
      dispatch(setInformationAboutUser(response.data));
      setCurrentUser((prev) => ({
        ...prev,
        avatar: response.data.user.avatar,
      }));
      setOpenProcessModal(false);
      showUserNotification(intl.formatMessage({ id: 'profile_image_changed' }), 'success');
    } catch (error) {
      setOpenProcessModal(false);
      showUserNotification(error.response.data.error || error.message, 'error');
    }
  };

  const isUserCurrentUser = () => {
    return (
      user.slug === localStorage.getItem('user-slug') ||
      user.slug === sessionStorage.getItem('user-slug')
    );
  };

  return (
    <>
      {/* <CoverImage user={user} /> */}
      <label className={styles['user-avatar']} htmlFor='file-input'>
        <Avatar src={user?.avatar} className={styles['avatar']} />
        {isUserCurrentUser() && (
          <div className={styles['container']}>
            <CameraAltIcon className={styles['camera-icon']} />
            <input
              id='file-input'
              type='file'
              accept='image/jpeg, image/jpg, image/png'
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {openModal && (
              <CropperModal
                openModal={openModal}
                closeModal={() => setOpenModal(false)}
                image={image}
                aspectRatio={1 / 1}
                onSave={handleCrop}
              />
            )}
            {openProcessModal && (
              <ProcessModal open={openProcessModal} messageKey='waiting_profile_upload' />
            )}
          </div>
        )}
      </label>
    </>
  );
};

export default UploadUserAvatar;
