import {
  CameraFilled,
  DeleteOutlined,
  LoadingOutlined,
  StarOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Popover, Space, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import defaultBackground from '../../../../img/defaultGraveCoverPhoto.jpg';
import { goToChosenGrave } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import styles from './CoverPhoto.module.css';
import SelectCoverPhotoModal from './SelectCoverPhotoModal';

const CoverPhoto = ({ grave }) => {
  const grave_images = grave?.grave_images;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [isImagePicked, setIsImagePicked] = useState(false);
  const [openSelectCoverPhotoModal, setOpenSelectCoverPhotoModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const currentGraveUser = grave?.current_user_relation;
  const [isUploading, setIsUploading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (grave?.cover_image_id && grave.grave_images.length > 0) {
      const coverImage = grave.grave_images.find((image) => image.id === grave.cover_image_id);
      if (coverImage) {
        setImage(coverImage.file_url);
        setBackgroundImage(coverImage.file_url);
      }
    } else {
      setImage(defaultBackground);
    }
  }, [grave]);

  const handleUpload = ({ file }) => {
    setOpenPopover(false);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setIsImagePicked(true);
    };
    reader.readAsDataURL(file);
  };

  const handleRemove = () => {
    setOpenPopover(false);
    setCoverPhoto(null);
    setImage(defaultBackground);
    setIsImagePicked(false);
  };

  const handleSave = async () => {
    if (selectedImage) {
      try {
        setOpenSelectCoverPhotoModal(false);
        setSelectedImage(null);
        setCoverPhoto(selectedImage.id);
      } catch (error) {
        console.log(error);
      }
    }
    if (isImagePicked) {
      handleSubmit();
    }
  };

  const setCoverPhoto = async (value) => {
    const formData = {
      grave: {
        cover_image_id: value,
      },
    };

    try {
      const response = await request(`/graves/${grave.slug}`, formData, 'put');
      const updatedGrave = response.data;

      dispatch(goToChosenGrave(updatedGrave));
    } catch (error) {
      console.error('Failed to update cover image:', error);
    } finally {
      setSelectedImage(null);
    }
  };

  const getCroppedImage = async () => {
    if (!image) return;
    try {
      const croppedBlob = await cropImage(image, croppedAreaPixels);
      return croppedBlob;
    } catch (error) {
      console.error('Cropping error:', error);
    }
  };

  // Convert Cropped Area to Blob
  const cropImage = async (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.crossOrigin = 'anonymous'; // Prevent CORS issues if needed

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // ⚡ Ensure canvas size matches the cropped area's actual size
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        // ✨ Clear the canvas to avoid image repetition issues
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // 🖼️ Draw only the cropped part of the image onto the canvas
        ctx.drawImage(
          image,
          croppedAreaPixels.x, // Crop start X
          croppedAreaPixels.y, // Crop start Y
          croppedAreaPixels.width, // Crop width
          croppedAreaPixels.height, // Crop height
          0,
          0,
          canvas.width,
          canvas.height,
        );

        // Convert to Blob (JPEG or PNG format)
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else reject(new Error('Canvas to Blob conversion failed'));
        }, 'image/jpeg');
      };

      image.onerror = (err) => reject(err);
    });
  };

  // Submit Cropped Image to Backend
  const handleSubmit = async () => {
    setIsUploading(true);
    const croppedBlob = await getCroppedImage();
    if (!croppedBlob) {
      setIsUploading(false);
      return console.error('Failed to crop image');
    }

    try {
      const formData = new FormData();
      formData.append('grave_image[file]', croppedBlob);
      formData.append('grave_image[category]', 'display');
      formData.append('grave_image[grave_id]', `${grave.id}`);

      const headers = { 'Content-Type': 'multipart/form-data' };
      const res = await request('/grave_images', formData, 'post', headers);

      if (res.data) {
        const formData = new FormData();
        setImage(res.data.file_url);
        setIsImagePicked(false);
        formData.append('grave[cover_image_id]', res.data.id);
        const response = await request(`/graves/${grave.slug}`, formData, 'put');
        dispatch(goToChosenGrave(response.data));
      }
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCancel = () => {
    setSelectedImage(null);
    setImage(backgroundImage);
    setIsImagePicked(false);
    setOpenSelectCoverPhotoModal(false);
  };

  const handleSelectCoverPhoto = (photo) => {
    setSelectedImage(photo);
    setImage(photo.file_url);
    setOpenSelectCoverPhotoModal(false);
  };

  const popoverContent = (
    <Space direction='vertical' style={{ width: '100%' }}>
      {grave_images && grave_images.length > 0 && (
        <Button
          type='text'
          icon={<StarOutlined />}
          block
          style={{ justifyContent: 'flex-start' }}
          onClick={() => {
            setOpenPopover(false);
            setOpenSelectCoverPhotoModal(true);
          }}
        >
          <FormattedMessage id='choose_cover_photo' />
        </Button>
      )}
      <Upload
        accept='image/*'
        beforeUpload={() => false}
        onChange={handleUpload}
        showUploadList={false}
        style={{ width: '100%' }}
      >
        <Button
          type='text'
          icon={<UploadOutlined />}
          block
          style={{ justifyContent: 'flex-start', width: '100%' }}
        >
          <FormattedMessage id='upload_image' />
        </Button>
      </Upload>
      {image && (
        <Button
          type='text'
          danger
          block
          style={{ justifyContent: 'flex-start' }}
          icon={<DeleteOutlined />}
          onClick={handleRemove}
        >
          <FormattedMessage id='remove_image' />
        </Button>
      )}
    </Space>
  );

  return (
    <div className={styles.coverPhotoWrapper}>
      {isImagePicked ? (
        <Cropper
          image={image}
          crop={crop}
          aspect={18 / 9}
          onCropChange={setCrop}
          zoomWithScroll={false}
          objectFit='cover'
          showGrid={false}
          onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
          style={{
            containerStyle: { width: '100%' },
            mediaStyle: { width: '100%' },
          }}
        />
      ) : (
        <div
          className={styles.coverPhoto}
          style={{
            backgroundImage: image ? `url(${image})` : '#ccc',
          }}
        />
      )}
      {(selectedImage || isImagePicked) && (
        <div className={styles.topBar}>
          <Button onClick={handleCancel}>
            <FormattedMessage id='settings_cancel' />
          </Button>
          <Button type='primary' onClick={handleSave} style={{ background: '#404d56' }}>
            <FormattedMessage id='auth_save' />
          </Button>
        </div>
      )}
      {currentGraveUser && currentGraveUser.admin && (
        <Popover
          content={popoverContent}
          open={openPopover}
          onOpenChange={() => setOpenPopover(false)}
          placement='bottomRight'
          trigger='click'
        >
          {isMobile ? (
            <Button
              type='primary'
              shape='circle'
              icon={<CameraFilled style={{ fontSize: 18 }} />}
              onClick={() => setOpenPopover(true)}
              style={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                fontWeight: 'bold',
                background: '#f2f4f7',
                color: '#404d56',
              }}
            ></Button>
          ) : (
            <Button
              type='primary'
              shape='round'
              icon={<CameraFilled />}
              onClick={() => setOpenPopover(true)}
              style={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                fontWeight: 'bold',
                background: '#f2f4f7',
                color: '#404d56',
              }}
            >
              {image ? (
                <FormattedMessage id='edit_cover_image' />
              ) : (
                <FormattedMessage id='add_cover_photo' />
              )}
            </Button>
          )}
        </Popover>
      )}

      {openSelectCoverPhotoModal && (
        <SelectCoverPhotoModal
          isOpen={openSelectCoverPhotoModal}
          grave={grave}
          handleCancel={handleCancel}
          handleSelect={handleSelectCoverPhoto}
          selectedImage={selectedImage}
        />
      )}
      {isUploading && (
        <div className={styles.loading}>
          <Spin
            spinning={isUploading}
            indicator={<LoadingOutlined style={{ fontSize: 48, color: '#fea65a' }} spin />}
          />
        </div>
      )}
    </div>
  );
};

export default CoverPhoto;
