import { PlusOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../../../../../service/request';
import styles from './Passion.module.css';
import PassionForm from './PassionForm';
import PassionsList from './PassionsList';

const Passion = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const grave = useSelector((state) => state.grave.grave);
  const loggedUser = useSelector((state) => state.user.user);
  const currentGraveUser = grave?.current_user_relation;
  const [passions, setPassions] = useState([]);
  const [openPassionForm, setOpenPassionForm] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpenPassionForm = () => {
    setOpenPassionForm(true);
  };

  const fetchPassion = async () => {
    if (!grave.id) return;
    setLoading(true);
    await request(`/grave_passions?grave_id=${grave.id}`)
      .then((res) => {
        if (res.data.length > 0) {
          setPassions(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPassion();
  }, [grave.id]);

  // useEffect(() => {
  //   if (grave?.passion) setPassion(grave?.passion);
  // }, [grave.passion]);

  // useEffect(() => {
  //   if (passion) {
  //     setLoading(false);
  //   }
  // }, [passion]);

  const onSubmitPassion = async (values, id) => {
    try {
      const payload = {
        grave_passion: {
          comment: values.description,
          grave_id: grave?.id,
          privacy_attributes: { setting: 'relatives' },
        },
      };

      let response;
      if (id) {
        response = await request(`/grave_passions/${id}`, payload, 'put');
        const updatedPassions = passions.map((passion) => {
          if (passion.id === id) {
            return response.data;
          }
          return passion;
        });
        setPassions(updatedPassions);
      } else {
        response = await request('/grave_passions', payload, 'post');
        const updatedPassions = [...passions, response.data];
        setPassions(updatedPassions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deletePassion = (id) => {
    if (id) {
      request(`/grave_passions/${id}`, null, 'delete')
        .then(() => {
          const updatedPassions = passions.filter((passion) => passion.id !== id);
          setPassions(updatedPassions);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h3>
          <FormattedMessage id='passion' />
        </h3>
        {loggedUser.is_subscribed && currentGraveUser && (
          <Button
            type='text'
            style={{
              fontWeight: 'bold',
              background: '#f2f4f7',
              color: '#404d56',
              fontSize: 14,
            }}
            icon={<PlusOutlined />}
            onClick={handleOpenPassionForm}
          >
            <FormattedMessage id='add_passion' />
          </Button>
        )}
      </div>
      {openPassionForm && (
        <div className={styles.passionForm}>
          <PassionForm onSubmit={onSubmitPassion} onCancel={() => setOpenPassionForm(false)} />
        </div>
      )}

      <div style={{ marginTop: 12, height: loading ? 400 : 'auto' }}>
        {passions && passions.length > 0
          ? passions.map((passion) => (
              <PassionsList
                key={passion.id}
                passion={passion}
                onSubmit={onSubmitPassion}
                loggedUser={loggedUser}
                currentGraveUser={currentGraveUser}
                onRemovePassion={deletePassion}
              />
            ))
          : !loading && (
              <Result
                status='404'
                title={<FormattedMessage id='no_passion_added' />}
                subTitle={<FormattedMessage id='add_passion_message' />}
              />
            )}
      </div>
    </div>
  );
};

export default Passion;
