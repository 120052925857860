import { SendOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import isEmpty from 'lodash.isempty';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import ContentEditable from '../../../../../../components/RichTextEditor/ContentEditable';
import { setGraveImages } from '../../../../../../redux/actions';
import { request } from '../../../../../../service/request';
import styles from './GravePhotos.module.css';

const CommentFooter = ({ user, image, images, setImages }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');

  const createComment = async () => {
    if (!image.id) return;
    try {
      const graveComment = {
        grave_image_comment: {
          grave_image_id: image.id,
          comment: comment,
        },
      };

      const response = await request('/grave_image_comments', graveComment, 'post');
      if (response.data.comment_id) {
        setComment('');
        await refreshComments();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refreshComments = async () => {
    if (!image.id) return;
    try {
      const response = await request(`/grave_images/${image.id}`);
      const newArray = images.map((photo) => (photo.id === image.id ? response.data : photo));
      setImages(newArray);
      dispatch(setGraveImages([...newArray]));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.footerComment}>
      <div>
        <Avatar src={user?.user.user_avatar} className={styles.avatar} icon={<UserOutlined />} />
      </div>
      <div className={styles.inputContainer}>
        <ContentEditable
          placeholder={<FormattedMessage id='comment_placeholder' />}
          value={comment}
          onInput={setComment}
          editable={true}
          style={{
            minHeight: 70,
          }}
        />
        <Button
          className={styles.sendIcon}
          disabled={isEmpty(comment)}
          type='primary'
          shape='circle'
          icon={<SendOutlined />}
          onClick={() => {
            createComment();
          }}
        />
      </div>
    </div>
  );
};

export default CommentFooter;
