import { UserOutlined } from '@ant-design/icons';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import { Avatar, Button, Popover } from 'antd';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moreIcon from '../../../../../../img/VerticalDots.svg';
import { setGraveImages } from '../../../../../../redux/actions';
import { timeSinceCreated } from '../../../../../../service/Utils';
import { request } from '../../../../../../service/request';
import EditImageModal from './EditImageModal';
import styles from './GravePhotos.module.css';
import Menu from './Menu';

const Header = ({ graveUser, image, images, setImages }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [postCreated, setPostCreated] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    setPostCreated(timeSinceCreated(image?.created_at));
  }, [image]);

  const goToLifePage = () => {
    navigate(`/member/${graveUser.user.slug}`);
  };

  const handleDeleteImage = async () => {
    try {
      await request(`/grave_images/${image.id}`, null, 'delete');
      const newImages = images.filter((item) => item.id !== image.id);
      setImages(newImages);
      dispatch(setGraveImages([...newImages]));
    } catch (error) {
      console.log(error);
    }
  };

  const getPrivacyIcon = (privacy) => {
    switch (privacy) {
      case 'public':
        return <PublicIcon style={{ fontSize: 14 }} />;
      case 'private':
        return <LockIcon style={{ fontSize: 14 }} />;
      case 'relatives':
        return <PeopleIcon style={{ fontSize: 14 }} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.memoryCardHeader}>
      <div className={styles.header}>
        {!isEmpty(graveUser?.user) && (
          <>
            <Avatar
              src={graveUser?.user.user_avatar}
              size={45}
              onClick={goToLifePage}
              icon={<UserOutlined />}
            />
            <div className={styles.userInfo}>
              <div className={styles.name} onClick={goToLifePage}>
                {graveUser?.user.full_name}
              </div>
              <div className={styles.privacyContainer}>
                <div className={styles.createdAt}>{postCreated}</div>
                <span className={styles.dot}>•</span>
                <div className={styles.privacyIcon}>{getPrivacyIcon(image.privacy)}</div>
              </div>
            </div>
          </>
        )}
      </div>
      {image.can_edit && image.can_destroy && (
        <Popover
          placement='bottomLeft'
          open={openMenu}
          onOpenChange={() => setOpenMenu(false)}
          trigger='click'
          content={
            <Menu
              onDelete={handleDeleteImage}
              setOpenEditModal={setOpenEditModal}
              openEditModal={openEditModal}
              setOpenMenu={setOpenMenu}
            />
          }
        >
          <Button
            onClick={() => setOpenMenu(true)}
            icon={<img src={moreIcon} alt='more' style={{ height: 18 }} />}
            type='text'
            shape='circle'
          />
        </Popover>
      )}
      {openEditModal && (
        <EditImageModal
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          images={images}
          setImages={setImages}
          imageItem={image}
        />
      )}
    </div>
  );
};

export default Header;
