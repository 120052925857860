import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LinkPreview from '../../../../components/LinkPreview';
import { timeSinceCreated } from '../../../../service/Utils';
import AllComments from '../CommentsModal/AllComments';
import PostModal from '../CreatePostModal/PostModal';
import ExpandableText from './ExpandableText';
import Footer from './Footer';
import Header from './Header';
import styles from './MemoryCard.module.css';
import MemoryGallery from './MemoryGallery';

const MemoryCard = ({
  memory,
  grave,
  user,
  isCommentModal = false,
  comments = [],
  createComment,
  editComment,
  deleteComment,
  setMemory = null,
}) => {
  const graveUsers = useSelector((state) => state.grave.grave.grave_users);
  const [postCreated, setPostCreated] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);

  // Ensure memory.contents is an array
  const memoryContents = Array.isArray(memory?.contents) ? memory.contents : [];

  useEffect(() => {
    setPostCreated(timeSinceCreated(memory?.created_at));
  }, [memory]);

  if (memoryContents.length === 0) {
    return null;
  }

  // Find the memory creator from grave users
  const memoryCreator = graveUsers.find((graveUser) => graveUser.user?.id === memory.user_id);

  // Get text-based description contents
  const descriptionContents = memoryContents.filter((content) => content.category === null);
  const descriptionContent =
    descriptionContents.length > 0 ? descriptionContents[0]?.description : '';
  const linkPreview = descriptionContents.length > 0 ? descriptionContents[0]?.title : '';

  // Filter out description content to get only media content
  const mediaContents = memoryContents.filter((content) => content.category !== null);

  return (
    <div
      className={styles.memoryCard}
      style={{
        border: isCommentModal && 'none',
        boxShadow: isCommentModal && 'none',
        padding: isCommentModal && 0,
      }}
    >
      {/* Memory Header */}
      <Header
        user={memoryCreator}
        postCreated={postCreated}
        memory={memory}
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        grave={grave}
      />
      {/* Post Description */}
      {descriptionContent && <ExpandableText descriptionContent={descriptionContent} />}

      {linkPreview && <LinkPreview previewLink={linkPreview} />}

      {/* Media Content (Images & Videos) */}
      {mediaContents.length > 0 && <MemoryGallery media={mediaContents} />}

      <Footer memory={memory} isCommentModal={isCommentModal} />

      {isCommentModal && comments.length > 0 && (
        <AllComments
          comments={comments}
          grave={grave}
          memoryId={memory.id}
          createComment={createComment}
          editComment={editComment}
          deleteComment={deleteComment}
        />
      )}

      {/* Edit Modal */}
      {openEditModal && (
        <PostModal
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          user={user}
          grave={grave}
          memory={memory}
          setMemory={setMemory}
        />
      )}
    </div>
  );
};

export default MemoryCard;
