import React, { useState } from 'react';
import styles from './Passion.module.css';
import PassionCard from './PassionCard';
import PassionForm from './PassionForm';
import PopoverMenu from './PopoverMenu';

const PassionsList = ({ passion, currentGraveUser, loggedUser, onRemovePassion, onSubmit }) => {
  const [openEditForm, setOpenEditForm] = useState(false);
  return openEditForm ? (
    <PassionForm passion={passion} onCancel={() => setOpenEditForm(false)} onSubmit={onSubmit} />
  ) : (
    <div className={styles.passionList}>
      <PassionCard passion={passion} />
      {currentGraveUser &&
        loggedUser &&
        currentGraveUser.user.id === passion.user_id &&
        loggedUser.is_subscribed && (
          <PopoverMenu
            passion={passion}
            onEditPassion={() => setOpenEditForm(true)}
            onRemovePassion={onRemovePassion}
          />
        )}
    </div>
  );
};

export default PassionsList;
