import { Avatar, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BoyAvatar from '../../../../../../img/BoyAvatar.svg';
import GirlsAvatar from '../../../../../../img/GirlsAvatar.svg';
import defaultBackground from '../../../../../../img/defaultGraveCoverPhoto.jpg';
import { goToChosenGrave } from '../../../../../../redux/actions';
import styles from './GraveLink.module.css';

const { Text } = Typography;

const Grave = ({ grave, linkedGrave }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToGrave = (slug) => {
    dispatch(goToChosenGrave({}));
    navigate(`/deceased/${slug}`);
  };

  return (
    <div className={styles.graveLinkContainer}>
      <div className={styles.graveContainer}>
        <div className={styles.graveAvatar} onClick={() => navigateToGrave(grave.slug)}>
          <Avatar
            src={grave?.avatar}
            shape='square'
            style={{ borderRadius: 0, height: '100%', width: '100%' }}
            icon={
              <img
                src={
                  grave?.gender === 'mann'
                    ? BoyAvatar
                    : grave.gender === 'kvinne'
                    ? GirlsAvatar
                    : defaultBackground
                }
              />
            }
          />
        </div>
        <div className={styles.graveName}>{grave?.name}</div>
        <div className={styles.graveDates}>
          {grave?.date_of_birth && (
            <Text type='secondary'>{grave?.date_of_birth.substring(0, 4)}</Text>
          )}{' '}
          -{' '}
          {grave?.date_of_death && (
            <Text type='secondary'>{grave?.date_of_death.substring(0, 4)}</Text>
          )}
        </div>
        <div className={styles.graveRelation}>{grave.current_user_relation?.relation.name}</div>
      </div>
      <div className={styles.linkIconContainer}>
        <div className={styles.graveRelation} style={{ marginBottom: 8 }}>
          {linkedGrave?.relation}
        </div>
        <div className={styles.linkIcon}>
          <div className={styles.circleDot} />
          <div className={styles.line} />
          <div className={styles.filledDot} />
        </div>
      </div>
      <div className={styles.graveContainer}>
        <div
          className={styles.graveAvatar}
          style={{ borderColor: linkedGrave ? '#36434B' : 'transparent' }}
          onClick={() => navigateToGrave(linkedGrave.slug)}
        >
          <Avatar
            src={linkedGrave?.avatar}
            shape='square'
            style={{ borderRadius: 0, height: '100%', width: '100%' }}
            icon={
              <img
                src={
                  linkedGrave?.gender === 'mann'
                    ? BoyAvatar
                    : linkedGrave.gender === 'kvinne'
                    ? GirlsAvatar
                    : defaultBackground
                }
              />
            }
          />
        </div>
        <div className={styles.graveName}>{linkedGrave?.name}</div>
        <div className={styles.graveDates}>
          <div className={styles.graveDates}>
            {linkedGrave?.date_of_birth && (
              <Text type='secondary'>{linkedGrave?.date_of_birth.substring(0, 4)}</Text>
            )}{' '}
            -{' '}
            {linkedGrave?.date_of_death && (
              <Text type='secondary'>{linkedGrave?.date_of_death.substring(0, 4)}</Text>
            )}
          </div>
        </div>
        <div className={styles.graveRelation}>{linkedGrave?.relation}</div>
      </div>
    </div>
  );
};

export default Grave;
