import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import AboutItems from '../../../AboutContainer/AboutItems';
import styles from './Buried.module.css';
import EditGraveModal from './EditGraveModal';

const Buried = () => {
  const grave = useSelector((state) => state.grave.grave);
  const [openModal, setOpenModal] = React.useState(false);
  const user = useSelector((state) => state.user.user) || {};

  const graveUser = grave.grave_users.find((u) => u.user.id === user.id);

  const handleEdit = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <div className={styles.header}>
        <h3>
          <FormattedMessage id='buried' />
        </h3>
        {graveUser && graveUser.admin && graveUser.grave_owner && user.is_subscribed && (
          <Button
            type='primary'
            icon={<EditOutlined />}
            style={{ backgroundColor: '#404d56' }}
            onClick={handleEdit}
          >
            <FormattedMessage id='settings_edit' />
          </Button>
        )}
      </div>

      <div className={styles.content}>
        <AboutItems grave={grave} />
      </div>
      {openModal && <EditGraveModal visible={openModal} onClose={handleClose} grave={grave} />}
    </div>
  );
};

export default Buried;
