import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setLifeEvents } from '../../../../redux/actions';
import { request } from '../../../../service/request';
import EventDetails from './Components/EventDetails/EventDetails';
import LifeEventSlider from './Components/LifeEventSlider';
import MediaGallery from './Components/MediaGallery';
import styles from './LifeEventDetails.module.css';

const LifeEventDetails = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const grave = useSelector((state) => state.grave.grave);
  const events = useSelector((state) => state.grave.lifeEvents);
  const user = useSelector((state) => state.user.user) || {};
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [eventIndex, setEventIndex] = useState(0);
  const [isCreator, setIsCreator] = useState(false);
  const [loading, setLoading] = useState(true);

  const selectedEventId = searchParams.get('event_id');

  const fetchLifeEvents = async () => {
    if (!grave.id) return;
    setLoading(true);
    await request(`/life_events?grave_id=${grave.id}`)
      .then((res) => {
        dispatch(setLifeEvents(res.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLifeEvents();
  }, [dispatch, grave.id]);

  // ✅ Combine events & videos from selected event
  const eventMedia = useMemo(() => {
    if (!selectedEventId || events.length === 0) return [];

    const selectedEvent = events.find((event) => event.id === Number(selectedEventId));
    if (!selectedEvent) return [];

    setIsCreator(selectedEvent.creator_id === user.id);

    const images = Array.isArray(selectedEvent.life_event_images)
      ? selectedEvent.life_event_images.map((image) => ({
          ...image,
          type: 'image',
        }))
      : [];

    const videos = Array.isArray(selectedEvent.life_event_videos)
      ? selectedEvent.life_event_videos.map((video) => ({
          ...video,
          type: 'video',
        }))
      : [];

    // Display images first, then others, then videos

    const displayImages = images.filter((img) => img.category === 'display');
    const otherImages = images.filter((img) => img.category !== 'display');

    return [...displayImages, ...otherImages, ...videos];
  }, [selectedEventId, events]);

  useEffect(() => {
    const updateHeight = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    if (selectedEventId && events.length > 0) {
      const selectedIndex = events.findIndex((event) => event.id === Number(selectedEventId));
      if (selectedIndex >= 0) {
        setEventIndex(selectedIndex);
        setCurrentIndex(0);
      }
    }
  }, [selectedEventId, events, setSearchParams]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement;
      const isTyping =
        activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA' ||
        activeElement?.isContentEditable;

      if (!isTyping) {
        if (event.key === 'ArrowRight') nextMedia();
        if (event.key === 'ArrowLeft') prevMedia();
        if (event.key === 'Escape') {
          event.stopPropagation();
          event.preventDefault();
          closeModal();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, events, setSearchParams]);

  const updateSearchParams = (newIndex) => {
    if (events.length > 0 && events[newIndex]) {
      setSearchParams({ tab: 'events', event_id: events[newIndex].id });
    }
  };

  const prevMedia = useCallback(() => {
    if (eventMedia.length === 0) return;
    setCurrentIndex((prev) => (prev - 1 + eventMedia.length) % eventMedia.length);
  }, [eventMedia]);

  const nextMedia = useCallback(() => {
    if (eventMedia.length === 0) return;
    setCurrentIndex((prev) => (prev + 1) % eventMedia.length);
  }, [eventMedia]);

  const prevEvent = useCallback(() => {
    if (events.length === 0) return;
    const newIndex = eventIndex === 0 ? events.length - 1 : eventIndex - 1;
    updateSearchParams(newIndex);
  }, [events, eventIndex]);

  const nextEvent = useCallback(() => {
    if (events.length === 0) return;
    const newIndex = eventIndex === events.length - 1 ? 0 : eventIndex + 1;
    updateSearchParams(newIndex);
  }, [events, eventIndex]);

  const closeModal = () => setSearchParams({ tab: 'life_events' });

  if (events.length === 0 && !loading) {
    setSearchParams({ tab: 'life_events' });
    return null;
  }

  return (
    <Modal
      open={true}
      onCancel={closeModal}
      width={'100%'}
      closeIcon={null}
      maskClosable={false}
      keyboard={false}
      style={{
        top: 0,
        margin: 0,
        maxWidth: '100%',
      }}
      styles={{
        header: { display: 'none' },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: '0 12px',
          height: `${viewportHeight}px`,
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          marginTop: '16px',
          overflowY: 'auto',
          overflowX: 'hidden',
          flex: 1,
        },
      }}
      footer={null}
    >
      <>
        <Button
          type='primary'
          shape='circle'
          icon={<CloseOutlined style={{ fontSize: '18px' }} />}
          className={styles.closeIcon}
          onClick={closeModal}
        />
        {loading ? (
          <div className={styles.loading}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
          </div>
        ) : (
          <div className={styles.modalContainer}>
            {events[eventIndex] && (
              <div className={styles.eventYear}>{events[eventIndex].event_year}</div>
            )}

            <div className={styles.rightContent}>
              {events[eventIndex] && <EventDetails event={events[eventIndex]} />}

              <MediaGallery
                onNext={nextMedia}
                onPrev={prevMedia}
                currentIndex={currentIndex}
                media={eventMedia}
                isCreator={isCreator}
                event={events[eventIndex]}
              />

              <div className={styles.navButtons}>
                <Button
                  type='text'
                  icon={<ArrowLeftOutlined />}
                  style={{
                    color: '#404D56',
                    fontFamily: 'Roboto',
                  }}
                  onClick={prevEvent}
                  disabled={events.length === 0}
                >
                  <FormattedMessage id='previous' />
                </Button>

                <Button
                  type='text'
                  icon={<ArrowRightOutlined />}
                  onClick={nextEvent}
                  style={{
                    color: '#404D56',
                    fontFamily: 'Roboto',
                  }}
                  iconPosition='end'
                  disabled={events.length === 0}
                >
                  <FormattedMessage id='auth_next' />
                </Button>
              </div>

              {events.length > 1 && <LifeEventSlider events={events} />}
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

export default LifeEventDetails;
