import { MoreOutlined, SendOutlined } from '@ant-design/icons';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Button, Popover } from 'antd';
import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentEditable from '../../../../components/RichTextEditor/ContentEditable';
import { timeSinceCreated } from '../../../../service/Utils';
import AllComments from './AllComments';
import styles from './CommentsModal.module.css';
import Menu from './Menu';

const Comment = ({
  comment,
  grave,
  setShowReplies,
  showReplies,
  memoryId,
  editComment,
  deleteComment,
  createComment,
  style,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editAbleComment, setEditAbleComment] = useState(comment.comment);

  const creator = grave.grave_users.find((graveUser) => graveUser.user?.id === comment.user_id);

  const onEditComment = async () => {
    if (!comment) return;
    editComment(comment.id, editAbleComment);
    openEditModal && setOpenEditModal(false);
  };

  const onDeleteComment = async () => {
    if (!comment.id) return;
    deleteComment(comment.id);
  };

  const handelOpenEditModal = (open) => {
    if (open) {
      setEditAbleComment(comment.description);
    }
    setOpenEditModal(open);
  };

  return (
    <div className={styles.commentContainer} style={style}>
      <div>
        <Avatar src={creator?.user.user_avatar} className={styles.avatar} icon={<PersonIcon />} />
      </div>
      {!openEditModal ? (
        <div style={{ flex: showReplies === comment.id && 1 }}>
          <div className={styles.comment}>
            <div className={styles.userName}>{creator?.user.full_name}</div>
            <div
              className={styles.commentText}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.description) }}
            />
          </div>
          <div className={styles.commentFooterReplies}>
            <span className={styles.date}>{timeSinceCreated(comment?.created_at)}</span>
            {comment.can_reply && (
              <Button
                onClick={() => {
                  if (showReplies === comment.id) {
                    setShowReplies(null);
                  } else {
                    setShowReplies(comment.id);
                  }
                }}
                style={{
                  fontSize: '12px',
                  color: '#777777',
                }}
                type='text'
                size='small'
              >
                {comment.replies_count > 0 ? comment.replies_count : ''}{' '}
                <FormattedMessage id='reply' />
              </Button>
            )}
          </div>
          {showReplies === comment.id && comment?.replies && comment.replies.length > 0 && (
            <AllComments
              style={{ padding: '6px 0px' }}
              comments={comment?.replies}
              grave={grave}
              memoryId={memoryId}
              createComment={createComment}
              editComment={editComment}
              deleteComment={deleteComment}
            />
          )}
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <div className={styles.inputContainer}>
            <ContentEditable
              placeholder={<FormattedMessage id='comment_placeholder' />}
              value={editAbleComment}
              onInput={setEditAbleComment}
              editable={true}
              autoFocus={true}
              style={{
                width: 'calc(100% - 16px)',
              }}
            />
            <Button
              className={styles.sendIcon}
              type='primary'
              shape='circle'
              icon={<SendOutlined />}
              onClick={() => {
                onEditComment();
              }}
            />
          </div>
          <Button
            type='text'
            size='small'
            onClick={() => setOpenEditModal(false)}
            style={{ color: '#36434b', marginTop: '5px' }}
          >
            <FormattedMessage id='settings_cancel' />
          </Button>
        </div>
      )}
      {comment.can_edit && comment.can_destroy && !openEditModal && (
        <Popover
          placement='bottomLeft'
          open={openMenu}
          onOpenChange={() => setOpenMenu(false)}
          trigger='click'
          content={
            <Menu
              onDelete={onDeleteComment}
              setOpenEditModal={handelOpenEditModal}
              openEditModal={openEditModal}
              setOpenMenu={setOpenMenu}
            />
          }
        >
          <Button
            onClick={() => setOpenMenu(true)}
            icon={<MoreOutlined rotate={90} />}
            type='text'
            shape='circle'
            size='small'
            style={{ color: '#36434b' }}
          />
        </Popover>
      )}
    </div>
  );
};

export default Comment;
