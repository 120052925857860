import { CaretDownOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd'; // ✅ Import AntD Grid
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setEventMonthIndex, setEventYearIndex } from '../../../../../redux/actions';
import Event from './Event/Event';
import styles from './LifeEvent.module.css';

const LifeEvent = ({ year, eventsInYear, index }) => {
  const dispatch = useDispatch();
  const eventYearIndex = useSelector((state) => state.grave.eventYearIndex);
  const eventMonthIndex = useSelector((state) => state.grave.eventMonthIndex);

  const getMonthName = (monthIndex) => {
    const monthNames = [
      <FormattedMessage id='month_0' />,
      <FormattedMessage id='month_1' />,
      <FormattedMessage id='month_2' />,
      <FormattedMessage id='month_3' />,
      <FormattedMessage id='month_4' />,
      <FormattedMessage id='month_5' />,
      <FormattedMessage id='month_6' />,
      <FormattedMessage id='month_7' />,
      <FormattedMessage id='month_8' />,
      <FormattedMessage id='month_9' />,
      <FormattedMessage id='month_10' />,
      <FormattedMessage id='month_11' />,
    ];
    return monthNames[monthIndex];
  };

  return (
    <>
      <div
        className={styles['year-btn']}
        onClick={() => {
          if (eventYearIndex === index) {
            dispatch(setEventYearIndex(-1));
          } else {
            dispatch(setEventYearIndex(index));
          }
        }}
      >
        <button>{year}</button>
        <CaretDownOutlined rotate={eventYearIndex === index && 180} />
      </div>

      {eventYearIndex === index && (
        <div className={styles['container-year']}>
          <div className={styles['side-container-year']}>
            <div className={styles['line-container']}>
              <div className={styles['dot']}></div>
              <div className={styles['line']}></div>
              <div className={styles['dot']}></div>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            {Object.entries(eventsInYear).map(([month, eventsInMonth], monthIndex) => (
              <div key={month}>
                <div
                  className={styles['month-btn']}
                  onClick={() => {
                    if (eventMonthIndex === monthIndex) {
                      dispatch(setEventMonthIndex(-1));
                    } else {
                      dispatch(setEventMonthIndex(monthIndex));
                    }
                  }}
                >
                  <button>{getMonthName(month)}</button>
                  <CaretDownOutlined rotate={eventMonthIndex === monthIndex && 180} />
                </div>

                {eventMonthIndex === monthIndex && (
                  <div className={styles['container-month']}>
                    <div className={styles['side-container-month']}>
                      <div className={styles['line-container']}>
                        <div className={styles['dot']}></div>
                        <div className={styles['line']}></div>
                        <div className={styles['dot']}></div>
                      </div>
                    </div>

                    <Row gutter={[8, 8]} style={{ flex: 1 }}>
                      {eventsInMonth
                        .sort((a, b) => {
                          const dayA = new Date(a.event_date).getDate();
                          const dayB = new Date(b.event_date).getDate();
                          return dayA - dayB;
                        })
                        .map((event, eventIndex) => (
                          <Col key={eventIndex} xs={24} sm={12} md={8}>
                            <div
                              className={styles['event-wrapper']}
                              style={{ background: event.theme_color }}
                            >
                              <Event event={event} year={year} />
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LifeEvent;
