import React from 'react';
import CommentReply from './CommentReply';

const AllCommentsReplies = ({ comments, grave, refreshComments }) => {
  return comments.map((comment) => (
    <CommentReply
      key={comment.id}
      comment={comment}
      grave={grave}
      refreshComments={refreshComments}
    />
  ));
};

export default AllCommentsReplies;
