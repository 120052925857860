import { Button, DatePicker, Form, Input, Modal } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './AnniversaryFormModal.module.css';
dayjs.extend(utc);

const AnniversaryFormModal = ({
  isOpen,
  onClose,
  onCreate,
  anniversary = null,
  handleUpdateAnniversary,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    if (!isEmpty(anniversary)) {
      form.setFieldsValue({
        name: anniversary.name || '',
        dateTime: anniversary.datetime ? dayjs.utc(anniversary.datetime).local() : null,
      });
    }
  }, [anniversary, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (anniversary && !isEmpty(anniversary)) {
        handleUpdateAnniversary(values, anniversary.id);
      } else {
        onCreate(values);
      }
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={
        !isEmpty(anniversary) ? (
          <FormattedMessage id='edit_anniversary' />
        ) : (
          <FormattedMessage id='create_anniversary' />
        )
      }
      centered
      width={window.innerWidth <= 768 ? '100%' : 500}
      style={{
        top: window.innerWidth <= 768 ? 0 : 'auto',
        margin: window.innerWidth <= 768 ? 0 : 'auto',
        maxWidth: window.innerWidth <= 768 ? '100%' : 'auto',
      }}
      styles={{
        header: {
          color: '#333',
          fontSize: '18px',
          fontWeight: '600',
          padding: '12px 16px',
          borderBottom: '1px solid #e1e1e1',
        },
        wrapper: {
          overflow: 'hidden',
        },
        content: {
          padding: 0,
          height: window.innerWidth <= 768 ? `${viewportHeight}px` : 'auto',
          borderRadius: window.innerWidth <= 768 ? 0 : '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        body: {
          overflowY: 'auto',
          flex: 1,
        },
      }}
      footer={
        <div className={styles.footer}>
          <Button onClick={onClose}>
            <FormattedMessage id='settings_cancel' />
          </Button>
          <Button type='primary' onClick={handleSubmit} style={{ backgroundColor: '#404d56' }}>
            {!isEmpty(anniversary) ? (
              <FormattedMessage id='update_memory' />
            ) : (
              <FormattedMessage id='create' />
            )}
          </Button>
        </div>
      }
    >
      <Form form={form} layout='vertical' style={{ padding: '0px 16px' }}>
        {/* Text Input */}
        <Form.Item
          label={<FormattedMessage id='settings_name' />}
          name='name'
          rules={[{ required: true, message: 'Please enter name' }]}
        >
          <Input placeholder={intl.formatMessage({ id: 'placeholder_name' })} />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id='date_time' />}
          name='dateTime'
          rules={[{ required: true, message: 'Please pick a date & time' }]}
        >
          <DatePicker showTime format='YYYY-MM-DD HH:mm:ss' style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AnniversaryFormModal;
